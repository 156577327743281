<template>
    <div class="flex w-full flex-col gap-2 overflow-hidden pb-2">
        <div
            class="flex w-max cursor-pointer items-end justify-start gap-1 rounded py-0.5 hover:bg-gray-100"
            @click="isOpened = !isOpened"
        >
            <label
                class="inline cursor-pointer text-xs select-none font-medium leading-5 tracking-wide text-gray-700 line-clamp-1"
            >
                {{ title }}
            </label>
            <svg
                viewBox="0 0 16 16"
                version="1.1"
                class="mb-0.5 h-4 w-4 transition duration-300 ease-in-out"
                :class="{ 'rotate-180 transform': !isOpened }"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
            >
                <polygon points="8,5 13,10 3,10" />
            </svg>
        </div>

        <template v-if="isOpened">
            <div class="flex w-full items-center gap-2 pl-3">
                <colour-picker
                    v-model="color"
                    class="w-32"
                    :swatches="swatches"
                    @input="boxShadowChanged(false)"
                    @change="boxShadowChanged"
                />
                <div
                    class="flex w-full flex-1 items-center justify-center gap-1.5"
                >
                    <label
                        class="text-xs font-medium leading-4 tracking-wide text-gray-700"
                    >
                        Inset
                    </label>
                    <base-small-toggle-switch
                        v-model="isInset"
                        @input="boxShadowChanged(true)"
                    />
                </div>
            </div>
            <div class="flex w-full flex-col gap-2 pl-3">
                <div class="flex items-end justify-between">
                    <label
                        class="text-xs font-medium leading-4 tracking-wide text-gray-700"
                    >
                        Horizontal
                    </label>
                    <span class="text-xs">{{ horizontalPx }}</span>
                </div>
                <input
                    v-model="horizontal"
                    class="h-1.5 w-full accent-black cursor-pointer appearance-none rounded-md bg-gray-200"
                    type="range"
                    step="1"
                    max="15"
                    min="-15"
                    @input="boxShadowChanged"
                />
            </div>
            <div class="flex w-full flex-col gap-2 pl-3">
                <div class="flex items-end justify-between">
                    <label
                        class="text-xs font-medium leading-4 tracking-wide text-gray-700"
                    >
                        Vertical
                    </label>
                    <span class="text-xs">{{ verticalPx }}</span>
                </div>
                <input
                    v-model="vertical"
                    class="h-1.5 w-full accent-black cursor-pointer appearance-none rounded-md bg-gray-200"
                    type="range"
                    step="1"
                    max="15"
                    min="-15"
                    @input="boxShadowChanged"
                />
            </div>
            <div class="flex w-full flex-col gap-2 pl-3">
                <div class="flex items-end justify-between">
                    <label
                        class="text-xs font-medium leading-4 tracking-wide text-gray-700"
                    >
                        Blur
                    </label>
                    <span class="text-xs">{{ blurPx }}</span>
                </div>
                <input
                    v-model="blur"
                    class="h-1.5 w-full accent-black cursor-pointer appearance-none rounded-md bg-gray-200"
                    type="range"
                    step="1"
                    max="50"
                    min="0"
                    @input="boxShadowChanged"
                />
            </div>
            <div class="flex w-full flex-col gap-2 pl-3">
                <div class="flex items-end justify-between">
                    <label
                        class="text-xs font-medium leading-4 tracking-wide text-gray-700"
                    >
                        Spread
                    </label>
                    <span class="text-xs">{{ spreadPx }}</span>
                </div>
                <input
                    v-model="spread"
                    class="h-1.5 w-full accent-black cursor-pointer appearance-none rounded-md bg-gray-200"
                    type="range"
                    step="1"
                    max="10"
                    min="-10"
                    @input="boxShadowChanged"
                />
            </div>
        </template>
    </div>
</template>

<script>
import ColourPicker from '@/components/storeDesigner/ColourPicker';

export default {
    props: {
        value: {
            type: [String, null],
            required: false,
            default: null
        },
        keyName: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        swatches: {
            type: Array,
            required: false,
            default: () => [
                '#000000',
                '#222831',
                '#ffffff',
                '#393e46',
                '#583d72',
                '#9f5f80',
                '#99c1de',
                '#84a59d',
                '#ffd369',
                '#f5cac3',
                '#ff8e71'
            ]
        }
    },
    components: {
        ColourPicker
    },
    data() {
        return {
            blur: 1,
            color: '#0000002e',
            spread: 0,
            isInset: true,
            vertical: 0,
            horizontal: 0,
            isOpened: false
        };
    },
    computed: {
        computedValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        horizontalPx() {
            return `${this.horizontal}px`;
        },
        verticalPx() {
            return `${this.vertical}px`;
        },
        blurPx() {
            return `${this.blur}px`;
        },
        spreadPx() {
            return `${this.spread}px`;
        },
        boxShadow() {
            return `${this.isInset ? 'inset' : ''} ${this.horizontalPx} ${
                this.verticalPx
            } ${this.blurPx} ${this.spreadPx} ${this.color}`.trim();
        }
    },
    watch: {
        value() {
            this.extractValues();
        }
    },
    created() {
        this.extractValues();
    },
    methods: {
        extractValues() {
            const values = this.value
                ? this.value.split(' ').map(value => value.replace('px', ''))
                : ['inset', '0', '0', '1', '0', '#0000002e'];
            const isInset = values[0].toLowerCase() === 'inset';

            if (isInset) {
                values.shift();
            }
            const [horizontal, vertical, blur, spread, color] = values;

            this.isInset = isInset;
            this.horizontal = horizontal;
            this.vertical = vertical;
            this.blur = blur;
            this.spread = spread;
            this.color = color;
        },
        boxShadowChanged(changeImmediately = true) {
            this.computedValue = this.boxShadow;

            if (changeImmediately) {
                this.$emit('change', this.keyName, this.boxShadow);
            }
        }
    }
};
</script>
