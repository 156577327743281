<template>
    <div
        class="mx-4 mb-4 flex flex-shrink-0 overflow-hidden rounded-md bg-sk-gray p-2 shadow-md transition-shadow duration-300 ease-in-out hover:shadow-lg"
    >
        <div v-if="!currentUser" class="flex items-center w-full">
            <div class="flex-shrink-0">
                <div class="h-8 w-8">
                    <VclFacebook :width="34" :height="34" class="rounded-full overflow-hidden" />
                </div>
            </div>
            <div class="ml-3 flex-grow">
                <VclList :width="160" :height="40" />
            </div>
        </div>
        <router-link
            v-else
            :to="{ name: 'settings-profile' }"
            class="focus:outline-none group block flex-shrink-0"
        >
            <div class="flex items-center">
                <div>
                    <img
                        v-if="currentUser.image"
                        :src="currentUser.image"
                        class="h-8 w-8 rounded-full"
                        alt="">
                    <avatar
                        v-else-if="currentUser && currentUser.name"
                        :username="currentUser.name"
                        :size="34"
                    />
                </div>
                <div class="ml-3">
                    <p
                        class="font-subheading truncate text-sm font-semibold leading-5 text-white"
                    >
                        {{ currentUser.name }}
                    </p>
                    <p
                        class="text-xs font-medium leading-4 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-300 group-focus:underline"
                    >
                        {{ $t('sidebar.account.settings') }}
                    </p>
                </div>
            </div>
        </router-link>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Avatar from 'vue-avatar';
import { VclList, VclFacebook } from 'vue-content-loading';
export default {
    components: {
        Avatar,
        VclList,
        VclFacebook
    },
    computed: {
        ...mapGetters({ currentUser: 'user/currentUser' })
    }
};
</script>
