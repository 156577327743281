<template>
    <nav class="mt-4 flex-1 bg-sk-black px-2" v-auto-animate>
      <div v-if="this.$route.path.startsWith('/settings')" key="settings">
        <router-link
            :to="{ name: 'dashboard' }"
            class="focus:outline-none mb-2 border-b border-gray-500 group flex mt-0.5 px-4 py-1 font-heading text-sm leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white -mx-2"
        >
          <base-back-icon />
          {{ $t('sidebar.navigation.back') }}
        </router-link>
        <router-link
            exact
            :to="{ name: 'settings-profile' }"
            active-class="bg-gray-700 text-white"
            class="focus:outline-none group flex rounded-md mt-0.5 px-2 py-1 font-heading text-sm  leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
        >
          <svg class="mr-2 h-4 w-4 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200" data-slot="icon" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path clip-rule="evenodd" fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"></path>
          </svg>
          {{ $t('sidebar.navigation.profile') }}
        </router-link>
<!--        @TODO account settings -->
        <router-link
            exact
            v-if="false"
            :to="{ name: 'settings-account' }"
            active-class="bg-gray-700 text-white"
            class="focus:outline-none group flex rounded-md mt-0.5 px-2 py-1 font-heading text-sm  leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
        >
          <svg class="mr-2 h-4 w-4 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200" data-slot="icon" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path clip-rule="evenodd" fill-rule="evenodd" d="M4.5 2.25a.75.75 0 0 0 0 1.5v16.5h-.75a.75.75 0 0 0 0 1.5h16.5a.75.75 0 0 0 0-1.5h-.75V3.75a.75.75 0 0 0 0-1.5h-15ZM9 6a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5H9Zm-.75 3.75A.75.75 0 0 1 9 9h1.5a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM9 12a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5H9Zm3.75-5.25A.75.75 0 0 1 13.5 6H15a.75.75 0 0 1 0 1.5h-1.5a.75.75 0 0 1-.75-.75ZM13.5 9a.75.75 0 0 0 0 1.5H15A.75.75 0 0 0 15 9h-1.5Zm-.75 3.75a.75.75 0 0 1 .75-.75H15a.75.75 0 0 1 0 1.5h-1.5a.75.75 0 0 1-.75-.75ZM9 19.5v-2.25a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-.75.75h-4.5A.75.75 0 0 1 9 19.5Z"></path>
          </svg>
          {{ $t('sidebar.navigation.account') }}
        </router-link>
        <router-link
            exact
            :to="{ name: 'settings-members' }"
            active-class="bg-gray-700 text-white"
            class="focus:outline-none group flex rounded-md mt-0.5 px-2 py-1 font-heading text-sm  leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
        >
          <svg class="mr-2 h-4 w-4 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200" data-slot="icon" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path d="M4.5 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM14.25 8.625a3.375 3.375 0 1 1 6.75 0 3.375 3.375 0 0 1-6.75 0ZM1.5 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM17.25 19.128l-.001.144a2.25 2.25 0 0 1-.233.96 10.088 10.088 0 0 0 5.06-1.01.75.75 0 0 0 .42-.643 4.875 4.875 0 0 0-6.957-4.611 8.586 8.586 0 0 1 1.71 5.157v.003Z"></path>
          </svg>
          {{ $t('sidebar.navigation.members') }}
        </router-link>
        <router-link
            exact
            :to="{ name: 'settings-developers' }"
            active-class="bg-gray-700 text-white"
            class="focus:outline-none group flex rounded-md mt-0.5 px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
        >
          <svg class="mr-2 h-4 w-4 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200" data-slot="icon" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path clip-rule="evenodd" fill-rule="evenodd" d="M14.447 3.026a.75.75 0 0 1 .527.921l-4.5 16.5a.75.75 0 0 1-1.448-.394l4.5-16.5a.75.75 0 0 1 .921-.527ZM16.72 6.22a.75.75 0 0 1 1.06 0l5.25 5.25a.75.75 0 0 1 0 1.06l-5.25 5.25a.75.75 0 1 1-1.06-1.06L21.44 12l-4.72-4.72a.75.75 0 0 1 0-1.06Zm-9.44 0a.75.75 0 0 1 0 1.06L2.56 12l4.72 4.72a.75.75 0 0 1-1.06 1.06L.97 12.53a.75.75 0 0 1 0-1.06l5.25-5.25a.75.75 0 0 1 1.06 0Z"></path>
          </svg>
          {{ $t('sidebar.navigation.developers') }}
        </router-link>
      </div>
      <div v-else key="default">
        <router-link
            exact
            :to="{ name: 'dashboard' }"
            active-class="bg-gray-700 text-white"
            class="focus:outline-none group flex rounded-md px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
        >
          <base-home-icon class="mr-2 h-4 w-4 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
          />
          {{ $t('sidebar.navigation.dashboard') }}
        </router-link>
        <router-link
            v-if="
                isFeatureAvailable(FeaturesEnum.INTERNAL) ||
                isFeatureAvailable(FeaturesEnum.PAT)
            "
            exact
            :to="{ name: 'tables' }"
            active-class="bg-gray-700 text-white"
            class="focus:outline-none group mt-0.5 flex rounded-md px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
        >
          <base-table-icon class="mr-2 h-4 w-4 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200" />
          {{ $t('sidebar.navigation.tables') }}
        </router-link>
        <span
            @click="showOrdersSubMenuAndNavigate"
            active-class="bg-gray-700 text-white"
            class="focus:outline-none group mt-0.5 flex cursor-pointer rounded-md px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
        >
          <base-orders-icon class="mr-2 h-4 w-4 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200" />
            {{ $t('sidebar.navigation.orders') }}
            <div
                class="ml-auto pl-4"
                @click.stop="showOrdersSubMenu = !showOrdersSubMenu"
            >
                <base-chevron-icon
                    :class="{ '-rotate-180': showOrdersSubMenu }"
                />
            </div>
        </span>
        <div v-auto-animate>
          <router-link
              v-if="showOrdersSubMenu"
              :to="{ name: 'orders' }"
              active-class="text-white bg-gray-700"
              class="focus:outline-none group mt-0.5 flex rounded-md px-2 py-2 pl-10 font-heading text-xs font-medium leading-4 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
          >
            {{ $t('sidebar.navigation.allOrders') }}
          </router-link>
          <router-link
              v-if="showOrdersSubMenu"
              :to="{ name: 'liveOrders' }"
              class="focus:outline-none group mt-0.5 flex rounded-md px-2 py-2 pl-10 font-heading text-xs font-medium leading-4 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
          >
            {{ $t('sidebar.navigation.liveOrders') }}
          </router-link>
          <router-link
              v-if="showOrdersSubMenu && isFeatureAvailable(FeaturesEnum.INTERNAL)"
              :to="{ name: 'preOrderPrep' }"
              class="focus:outline-none group mt-0.5 flex rounded-md px-2 py-2 pl-10 font-heading text-xs font-medium leading-4 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
          >
            {{ $t('sidebar.navigation.preOrders') }}
          </router-link>
          <router-link
              v-if="showOrdersSubMenu && isFeatureAvailable(FeaturesEnum.CATERING)"
              :to="{ name: 'orderCalendar' }"
              class="focus:outline-none group mt-0.5 flex rounded-md px-2 py-2 pl-10 font-heading text-xs font-medium leading-4 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
          >
            {{ $t('sidebar.navigation.orderCalendar') }}
          </router-link>
        </div>
        <router-link
            :to="{ name: 'stores' }"
            active-class="bg-gray-700 text-white"
            class="focus:outline-none group mt-0.5 flex rounded-md px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
        >
          <base-map-pin-icon class="mr-2 h-4 w-4 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
          />
          {{ $t('sidebar.navigation.stores') }}
        </router-link>
        <router-link
            :to="{ name: 'menus' }"
            active-class="bg-gray-700 text-white"
            class="focus:outline-none group mt-0.5 flex rounded-md px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
        >
          <base-menu-icon class="mr-2 h-4 w-4 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
          />

          {{ $t('sidebar.navigation.menus') }}
        </router-link>
        <router-link
            v-if="reportingNotBlocked"
            :to="{ name: 'discounts' }"
            active-class="bg-gray-700 text-white"
            class="focus:outline-none group mt-0.5 flex rounded-md px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
        >
          <base-discount-icon class="mr-2 h-4 w-4 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
          />
          {{ $t('sidebar.navigation.discounts') }}
        </router-link>
        <router-link
            v-if="isFeatureAvailable(FeaturesEnum.SAAS) || isFeatureAvailable(FeaturesEnum.INTERNAL)"
            :to="{ name: 'offers' }"
            active-class="bg-gray-700 text-white"
            class="focus:outline-none group mt-0.5 flex rounded-md px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
        >
          <base-banners-icon class="mr-2 h-4 w-4 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200" />
          {{ $t('sidebar.navigation.banners') }}
        </router-link>
        <router-link
            v-if="reportingNotBlocked"
            :to="{ name: 'customers' }"
            active-class="bg-gray-900 text-white"
            class="focus:outline-none group mt-0.5 flex rounded-md px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
        >
          <base-customers-icon class="mr-2 h-4 w-4 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200" />
          {{ $t('sidebar.navigation.customers') }}
        </router-link>
        <router-link
            v-if="reportingNotBlocked"
            :to="{ name: 'feedbackOverview' }"
            active-class="bg-gray-700 text-white"
            class="focus:outline-none group mt-0.5 flex rounded-md px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
        >
          <base-magic-icon
              class="mr-2 h-4 w-4 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
          />
          {{ $t('sidebar.navigation.feedback') }}
        </router-link>
        <span
            v-if="reportingNotBlocked"
            @click="showReportsSubMenuAndNavigate"
            active-class="bg-gray-700 text-white"
            class="focus:outline-none group mt-0.5 flex cursor-pointer rounded-md px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
        >
           <base-report-icon class="mr-2 h-5 w-5 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
           />
            {{ $t('sidebar.navigation.analytics') }}
            <div
                class="ml-auto pl-4"
                @click.stop="showReportsSubMenu = !showReportsSubMenu"
            >
                <base-chevron-icon
                    :class="{ '-rotate-180': showReportsSubMenu }"
                />
            </div>
        </span>
        <div v-auto-animate v-if="reportingNotBlocked">
          <router-link
              v-if="showReportsSubMenu"
              :to="{ name: 'ordersReport' }"
              active-class="text-white bg-gray-700"
              class="focus:outline-none group mt-0.5 flex items-center rounded-md px-2 py-2 pl-10 font-heading text-xs font-medium leading-4 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
          >
            {{ $t('sidebar.navigation.sales') }}
          </router-link>
          <router-link
              v-if="showReportsSubMenu"
              :to="{ name: 'adyenBalanceReport' }"
              active-class="text-white bg-gray-700"
              class="focus:outline-none group mt-0.5 flex items-center rounded-md px-2 py-2 pl-10 font-heading text-xs font-medium leading-4 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
          >
            {{ $t('sidebar.navigation.accounting') }}
          </router-link>
        </div>
      </div>
    </nav>
</template>
<script>
import { mapGetters } from 'vuex';
import { FeaturesEnum } from '@/enums';
import { RESTRICTED_ACCOUNTS } from './../config/restrictedAccounts'
import BaseTableIcon from "@/components/base/icons/BaseTableIcon.vue";
import BaseMapPinIcon from "@/components/base/icons/BaseMapPinIcon.vue";

export default {
    components: {BaseMapPinIcon, BaseTableIcon},
    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
            isFeatureAvailable: 'user/isFeatureAvailable',
            accountId: 'user/accountId',
            userId: 'user/userId'
        }),
        reportingNotBlocked() {
          // If account is not restricted, allow access
          if (!RESTRICTED_ACCOUNTS[this.accountId]) {
            return true;
          }

          const accountConfig = RESTRICTED_ACCOUNTS[this.accountId];

          // Handle accounts with allowed users list
          if (Array.isArray(accountConfig)) {
            return accountConfig.includes(this.userId);
          }

          // Handle accounts with blocked users list
          if (accountConfig.blockedUsers) {
            return !accountConfig.blockedUsers.includes(this.userId);
          }

          return true;
        },
    },
    data() {
        return {
            showReportsSubMenu: false,
            showOrdersSubMenu: false,
            restrictedAccounts: RESTRICTED_ACCOUNTS,
            FeaturesEnum
        };
    },
    methods: {
        showOrdersSubMenuAndNavigate() {
            if (this.$router.currentRoute.name !== 'orders') {
                this.$router.push({ name: 'orders' });
            }
            this.showOrdersSubMenu = !this.showOrdersSubMenu;
        },
        showReportsSubMenuAndNavigate() {
            if (!this.$router.currentRoute.path.includes('reports')) {
                this.$router.push({ name: 'ordersReport' });
            }
            this.showReportsSubMenu = !this.showReportsSubMenu;
        }
    }
};
</script>
