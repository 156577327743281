var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex w-0 flex-1 flex-col overflow-hidden"},[_c('base-toggle-sidebar-button'),_c('main',{staticClass:"focus:outline-none relative z-0 flex-1 overflow-y-auto pb-6 pt-2 md:py-6"},[_c('div',{staticClass:"mx-auto flex max-w-7xl flex-row items-center justify-between px-4 sm:px-6 lg:px-8"},[_c('BasePageTitle',{attrs:{"title":"Menus"}}),_c('div',{staticClass:"h-4 w-4",class:{ 'text-gray-900 spinner': _vm.isLoading }},[_c('span')]),_c('SortBySelector',{on:{"toggleSort":_vm.sortMenu}})],1),(_vm.isLoading)?_c('div',{staticClass:"mx-auto max-w-7xl px-4 sm:px-6 md:px-8"},[_c('div',{staticClass:"py-4"},[_c('div',{staticClass:"overflow-hidden bg-white shadow sm:rounded-md"},[_c('div',{staticClass:"p-4"},[_c('VclList',{attrs:{"width":400,"height":200}})],1)])])]):(_vm.menus && _vm.menus.length > 0)?_c('div',{staticClass:"mx-auto mt-10 max-w-7xl p-4 px-4 sm:mt-0 sm:px-6 md:px-8"},[_c('div',_vm._l((_vm.menus),function(menu,index){return _c('MenuListItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.showArchived || !menu.archived),expression:"showArchived || !menu.archived"}],key:menu.id,attrs:{"menu":menu,"index":index}})}),1)]):(!_vm.availableVenues.length)?_c('create-venue-card'):_c('div',{staticClass:"mx-auto mt-10 max-w-7xl p-4 px-4 sm:mt-0 sm:px-6 md:px-8"},[_c('div',{staticClass:"rounded-sm bg-white shadow"},[_c('div',{staticClass:"px-4 py-5 sm:p-6"},[_c('h3',{staticClass:"text-lg font-medium leading-6 text-gray-900"},[_vm._v(" Create a menu ")]),_c('div',{staticClass:"mt-2 sm:flex sm:items-start sm:justify-between"},[_vm._m(0),_c('div',{staticClass:"mt-5 sm:ml-6 sm:mt-0 sm:flex sm:shrink-0 sm:items-center"},[_c('span',{staticClass:"inline-flex rounded-md shadow-sm",on:{"click":function($event){return _vm.$router.push({
                                        name: 'createMenu',
                                        params: {
                                            venueId: _vm.availableVenues[0].id
                                        }
                                    })}}},[_c('button',{staticClass:"focus:outline-none inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700",attrs:{"type":"button"}},[_vm._v(" Create a menu ")])])])])])])]),(!_vm.showArchived && _vm.isFeatureAvailable(_vm.FeaturesEnum.INTERNAL))?_c('div',{staticClass:"mx-auto mt-10 max-w-7xl cursor-pointer sm:mt-0"},[_c('span',{staticClass:"block p-4 px-4 font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 sm:px-6 md:px-8",on:{"click":_vm.toggleShowArchivedFlag}},[_vm._v(" Show archived menus ")])]):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"max-w-xl text-sm leading-5 text-gray-500"},[_c('p',[_vm._v(" Before you can start taking orders online, you'll need to setup your first menu. ")])])
}]

export { render, staticRenderFns }