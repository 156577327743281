<template>
    <div
        :class="{ 'cursor-pointer': link }"
        class="inline-block truncate rounded-md bg-white py-0.5 border border-gray-200 mb-0.5 px-1 text-xs text-gray-500"
    >
        {{ name | truncateString(truncateLength) }}
    </div>
</template>

<script>
export default {
    name: 'VenueNameTag',
    props: {
        name: {
            type: String,
            required: true
        },
        truncateLength: {
            type: Number,
            required: false,
            default: 100
        },
        link: {
            type: Boolean,
            required: false,
            default: false
        }
    }
    // methods: {
    //     navigateToVenue() {
    //       this.$router.push({name: })
    //     }
    // }
};
</script>
