var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative flex items-center overflow-hidden",class:{ 'h-40 flex-col': _vm.changeable, 'h-24': _vm.selectable }},[_c('div',{staticClass:"group relative h-full overflow-hidden",class:{
            'w-full border-b': _vm.changeable,
            'w-2/5 rounded-l': _vm.selectable
        }},[_c('img',{staticClass:"size-full object-cover",attrs:{"src":_vm.staticMapUrl,"loading":"lazy"}}),(_vm.editable)?_c('div',{staticClass:"absolute inset-0 hidden size-full items-center justify-center bg-gray-100 bg-opacity-10 opacity-0 backdrop-blur-0 backdrop-filter transition-all duration-300 ease-in-out group-hover:flex group-hover:opacity-100 group-hover:backdrop-blur-sm"},[_c('base-button',{attrs:{"size":"xs","button-text":"Edit"},on:{"clicked":function($event){return _vm.$emit('edit')}}})],1):_vm._e()]),_c('div',{staticClass:"flex flex-1 flex-col items-start gap-1 p-2 text-sm",class:{ 'w-3/5': _vm.selectable }},[_c('h3',{staticClass:"font-bold",class:[
                { 'leading-8': _vm.changeable, 'pl-1': _vm.editable },
                _vm.selected ? 'text-indigo-700' : 'text-gray-900'
            ]},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('p',{class:[
                _vm.selected ? 'text-indigo-600' : 'text-gray-700',
                {
                    'max-w-full truncate rounded-md border border-gray-300 bg-gray-100/75 px-2 py-1 text-center text-xs': _vm.editable
                }
            ]},[_vm._v(" "+_vm._s(_vm.description)+" ")])]),(_vm.changeable)?_c('div',{staticClass:"absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-10 opacity-0 backdrop-blur-0 backdrop-filter transition duration-150 ease-in-out hover:opacity-100 hover:backdrop-blur-sm"},[_c('base-button',{attrs:{"size":"xs","button-text":"Change"},on:{"clicked":function($event){return _vm.$emit('show-modal')}}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }