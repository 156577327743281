<template>
    <div class="flex w-full flex-col gap-2 overflow-hidden pb-2">
        <div
            class="flex w-max cursor-pointer items-end justify-start gap-1 rounded py-0.5 hover:bg-gray-100"
            @click="isOpened = !isOpened"
        >
            <span
                v-tooltip="tooltip"
                class="line-clamp-1 inline cursor-pointer text-xs font-medium leading-5 tracking-wide text-gray-700"
            >
                {{ title }}
            </span>
            <svg
                viewBox="0 0 16 16"
                version="1.1"
                class="mb-0.5 h-4 w-4 transition duration-300 ease-in-out"
                :class="{ 'rotate-180 transform': !isOpened }"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
            >
                <polygon points="8,5 13,10 3,10" />
            </svg>
        </div>

        <fieldset v-if="isOpened" class="flex w-full min-w-0 pl-3">
            <div
                class="flex w-full flex-wrap justify-between"
                :class="[
                    containerClasses,
                    isColumn ? 'flex-col gap-3' : 'flex-row gap-2'
                ]"
            >
                <label
                    v-for="(option, index) in options"
                    :key="`${keyName}-${index}`"
                    :for="`${keyName}-${index}`"
                    class="flex flex-1 items-center gap-1 text-xs font-medium leading-5 tracking-wide text-gray-700"
                >
                    <input
                        :id="`${keyName}-${index}`"
                        :checked="option.value === (value || defaultValue)"
                        :name="`${keyName}-${index}`"
                        :value="option.value"
                        type="radio"
                        :disabled="option.isDisabled"
                        class="peer form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                        :class="{ 'sr-only': isInputHidden }"
                        @input="updateValue(option.value)"
                        @change="onChange(option.value)"
                    />
                    <slot
                        v-if="$scopedSlots.default"
                        :option="option"
                        :index="index"
                    />
                    <span v-else>{{ option.text }}</span>
                </label>
            </div>
        </fieldset>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            required: true
        },
        value: {
            type: [String, null],
            required: false,
            default: null
        },
        keyName: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        isColumn: {
            type: Boolean,
            required: false,
            default: false
        },
        isInitOpened: {
            type: Boolean,
            required: false,
            default: false
        },
        isInputHidden: {
            type: Boolean,
            required: false,
            default: false
        },
        tooltip: {
            type: String,
            required: false,
            default: ''
        },
        containerClasses: {
            type: String,
            required: false,
            default: ''
        },
        defaultValue: {
            type: [String, null],
            required: false,
            default: null
        }
    },
    data() {
        return {
            isOpened: this.isInitOpened
        };
    },
    methods: {
        updateValue(value) {
            this.$emit('input', value);
        },
        onChange(value) {
            this.$emit('change', this.keyName, value);
        }
    }
};
</script>
