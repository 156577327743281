<template>
    <div>
        <label
            for="timezone"
            class="mb-1 block text-sm font-medium leading-5 text-gray-700"
        >
            Timezone
        </label>
        <multi-select
            id="timezone"
            :value="selectedTimeZone"
            @input="setTimezone"
            class="border-gray-300 shadow-sm"
            deselect-label="Can't remove this value"
            track-by="name"
            label="name"
            placeholder="Select one"
            :options="timezones"
            :searchable="true"
            :allow-empty="false"
        >
        </multi-select>
    </div>
</template>

<script>
import { getTimeZones } from '@vvo/tzdb';

export default {
    name: 'TimezoneSelector',
    props: {
        value: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            timezones: getTimeZones()
        };
    },
    computed: {
        selectedTimeZone() {
            return this.timezones.find(
                timeZone =>
                    this.value === timeZone.name ||
                    timeZone.group.includes(this.value)
            );
        }
    },
    methods: {
        setTimezone(val) {
            this.$emit('input', val.name);
        }
    }
};
</script>
