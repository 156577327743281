<template>
    <div
        class="focus:outline-none relative z-0 flex-1 overflow-y-auto pb-6 pt-2 md:py-6"
    >
        <base-toggle-sidebar-button />
        <div
            class="mx-auto flex max-w-7xl flex-row items-center justify-between px-2 sm:px-6 lg:px-8"
        >
            <div class="min-w-0 flex-1">
                <BasePageTitle :title="$t('views.venues.title')" />
            </div>
            <div class="mr-4 flex md:ml-4">
                <div class="inline-flex rounded-md cursor-pointer select-none shadow-sm mr-3">
                    <div
                        class="px-2 py-1 text-xs focus:outline-none inline-flex items-center rounded-md border font-semibold leading-5 transition duration-150 ease-in-out border-gray-300 bg-white text-gray-700 hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                        @click="
                            displayType = displayType === 'compact' ? 'grid' : 'compact'
                        "
                    >
                      <span>
                        {{ displayType === 'compact' ? $t('views.venues.expand') : $t('views.venues.collapse') }}
                      </span>
                    </div>
                </div>
              <div
                  v-if="isFeatureAvailable(FeaturesEnum.INTERNAL)"
                  class="mr-3 inline-flex"
              >
                <router-link :to="{ name: 'multisite' }">
                  <base-button
                      size="sm"
                      :buttonText="$t('views.venues.multisite')"
                  />
                </router-link>
              </div>
                <div
                    v-if="isFeatureAvailable(FeaturesEnum.INTERNAL)"
                    class="mr-3 inline-flex"
                >
                    <base-button
                        @clicked="showDuplicateStoreModal()"
                        size="sm"
                        :buttonText="$t('views.venues.duplicateStore')"
                    />
                </div>
                <base-button
                    v-if="!isLoading && venues.length"
                    @clicked="
                        $router.push({
                            name: 'createVenue'
                        })
                    "
                    size="sm"
                    :buttonText="$t('views.venues.createStore')"
                    button-style="action"
                />
            </div>
            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div
            class="mx-auto mt-6 max-w-7xl sm:px-6 md:px-8 lg:mt-12"
            v-if="isLoading"
        >
            <div class="overflow-hidden bg-white p-4 shadow sm:rounded-md">
                <VclList :width="400" :height="200" />
            </div>
        </div>
        <div
            v-if="venues && venues.length"
            class="mx-auto mt-6 grid max-w-7xl grid-cols-1 gap-6 sm:gap-8 px-2 sm:px-6 md:px-8 lg:mt-12"
            :class="{'lg:grid-cols-2' : displayType === 'grid', 'lg:gap-1 lg:grid-cols-2' : displayType === 'compact'}"
        >
            <template v-for="venue in venues">
                <VenueItem
                    v-show="showArchived || !venue.archived"
                    :key="venue.id"
                    :venue="venue"
                    :displayType="displayType"
                />
            </template>
        </div>
        <CreateVenueCard v-else-if="!isLoading && !venues.length" />
        <div
            class="mx-auto mt-10 max-w-7xl cursor-pointer sm:mt-0"
            v-if="!showArchived && archivedVenuesNumber > 0"
        >
            <span
                @click="toggleShowArchivedFlag"
                class="block p-4 px-4 font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 sm:px-6 md:px-8"
            >
                Show
                {{
                    archivedVenuesNumber
                        | pluralize($t('views.venues.showArchivedStore'), $t('views.venues.showArchivedStores'))
                }}
            </span>
        </div>
        <DuplicateStoreModal
            :venues="venues"
            @venue-duplicated="updateVenues"
        />
        <CreatedVenueModal />
        <SnoozeVenueModal @venue-snoozed="venueSnoozed" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { VclList } from 'vue-content-loading';
import { FeaturesEnum } from '@/enums';
import VenueItem from './../components/VenueItem';
import CreatedVenueModal from '../components/modals/CreatedVenueModal';
import SnoozeVenueModal from '@/components/modals/SnoozeVenueModal';
import DuplicateStoreModal from '@/components/modals/DuplicateStoreModal';
import CreateVenueCard from '@/components/onboarding/CreateVenueCard';

export default {
    name: 'VenuesList',
    metaInfo() {
        return {
            title: this.$t('views.venues.title')
        };
    },
    components: {
        VenueItem,
        VclList,
        CreatedVenueModal,
        SnoozeVenueModal,
        DuplicateStoreModal,
        CreateVenueCard
    },
    data() {
        return {
            FeaturesEnum,
            isLoading: false,
            showArchived: false,
            displayType: 'grid'
        };
    },
    async created() {
        await Promise.all([
            !this.availableVenues?.length > 0 ? this.fetchVenues() : null,
            !this.archivedVenues?.length > 0
                ? this.fetchVenues({ archived: true })
                : null
        ]);
    },
    methods: {
        ...mapActions({
            fetchVenues: 'venues/fetch'
        }),
        toggleShowArchivedFlag() {
            this.showArchived = !this.showArchived;
        },
        venueSnoozed() {
            this.isLoading = true;

            setTimeout(() => {
                this.updateVenues();
                this.isLoading = false;
            }, 3000);
        },
        showDuplicateStoreModal() {
            this.$modal.show('duplicate-store-modal');
        },
        updateVenues() {
            Promise.all([
                this.fetchVenues(),
                this.fetchVenues({ archived: true })
            ]);
        }
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable',
            availableVenues: 'venues/getVenues',
            archivedVenues: 'venues/getArchivedVenues'
        }),
        venues() {
            return [...this.availableVenues, ...this.archivedVenues];
        },
        archivedVenuesNumber() {
            return this.archivedVenues.length;
        }
    }
};
</script>
