const state = {
    sidebarOpen: false,
    logoUrl: process.env.VUE_APP_WIHTELABEL_LOGO,
    logoWhiteUrl: process.env.VUE_APP_WIHTELABEL_LOGO_LIGHT,
    isWebExtension: false,
    loadingTasks: 0
};

const mutations = {
    UPDATE_SIDEBAR(state, payload) {
        state.sidebarOpen = payload;
    },
    SET_IS_WEB_EXTENSION(state, payload) {
        state.isWebExtension = payload;
    },
    LOADING_BAR_TASK_BEGIN(state) {
        state.loadingTasks++;
    },
    LOADING_BAR_TASK_END(state) {
        state.loadingTasks = Math.max(0, state.loadingTasks - 1);
    }
};

const getters = {
    isWebExtension(state) {
        return state.isWebExtension;
    },
    isRouteLoading(state) {
        return state.loadingTasks > 0;
    }
};

export default {
    namespaced: true,
    getters,
    mutations,
    state
};
