<template>
    <modal
        name="select-map-style-modal"
        :adaptive="true"
        height="auto"
        width="920px"
        @before-open="reset"
    >
        <div
            class="flex max-h-screen w-full transform flex-col gap-2 overflow-auto rounded-lg bg-white p-6 shadow-xl transition-all"
        >
            <div class="pt-1">
                <h3 class="mb-3 font-heading text-lg font-medium text-gray-900">
                    Select map style
                </h3>
            </div>
            <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div
                    v-for="(style, index) in mapStyles"
                    :key="`style-item-${index}`"
                >
                    <input
                        :id="`style-item-${index}`"
                        :checked="style.url === selected"
                        :name="`style-item-${index}`"
                        type="radio"
                        :value="style.url"
                        class="peer hidden"
                        @change="selected = style.url"
                    />
                    <label
                        :for="`style-item-${index}`"
                        class="block cursor-pointer rounded-md border-2 peer-checked:border-indigo-500 peer-checked:bg-indigo-50 peer-checked:bg-opacity-50"
                    >
                        <map-style-item
                            selectable
                            :selected="style.url === selected"
                            :description="style.description"
                            :name="style.name"
                            :map-style="style.url"
                            :coordinates="coordinates"
                        />
                    </label>
                </div>
                <div>
                    <input
                        v-if="isValid"
                        id="custom-style-item"
                        :checked="customMapStyle.url === selected"
                        name="custom-style-item"
                        type="radio"
                        :value="customMapStyle.url"
                        class="peer hidden"
                        @change="selected = customMapStyle.url"
                    />
                    <label
                        for="custom-style-item"
                        class="block rounded-md border-2 peer-checked:border-indigo-500 peer-checked:bg-indigo-50 peer-checked:bg-opacity-50"
                        :class="{ 'cursor-pointer': isValid }"
                    >
                        <map-style-item
                            v-if="isValid && !showError && !isEditMode"
                            :selected="customMapStyle.url === selected"
                            :description="customMapStyle.url"
                            :map-style="customMapStyle.url"
                            :name="customMapStyle.name"
                            :coordinates="coordinates"
                            selectable
                            editable
                            @edit="onEdit"
                        />
                        <div
                            v-else
                            class="flex h-24 items-center justify-center overflow-hidden px-4"
                        >
                            <button
                                v-if="!isEditMode"
                                class="flex h-20 w-20 items-center justify-center rounded-full border border-gray-400 text-7xl text-gray-600 hover:border-indigo-300 hover:text-indigo-500"
                                @click="isEditMode = true"
                            >
                                ＋
                            </button>

                            <div
                                v-else
                                class="flex h-full w-full flex-col justify-center gap-1 px-4"
                            >
                                <div
                                    class="flex w-full items-center justify-between"
                                >
                                    <label
                                        for="mapbox-style"
                                        class="text-sm font-medium leading-5 tracking-wide text-gray-700"
                                    >
                                        Custom mapbox style url
                                    </label>
                                    <div
                                        class="cursor-pointer rounded hover:bg-gray-200"
                                        @click="reset"
                                    >
                                        <base-small-close-icon
                                            class="h-4 w-4"
                                        />
                                    </div>
                                </div>
                                <div class="relative h-10 w-full">
                                    <input
                                        id="mapbox-style"
                                        v-model.trim="customMapStyle.url"
                                        :disabled="isLoading"
                                        placeholder="mapbox://styles/..."
                                        class="form-input absolute inset-0 mt-1 block w-full truncate rounded-md border bg-white py-2 pl-3 pr-20 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue focus:outline-none disabled:opacity-50 sm:text-sm sm:leading-5"
                                        :class="
                                            showError && !isValid
                                                ? 'border-red-500'
                                                : 'border-gray-300'
                                        "
                                        @input="showError = false"
                                    />
                                    <button
                                        type="button"
                                        class="absolute inset-y-0 right-0 mt-1 block w-16 rounded-r-md border-l bg-transparent py-2 text-sm font-medium text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 disabled:opacity-40 sm:text-sm sm:leading-5"
                                        :class="
                                            showError && !isValid
                                                ? 'border-red-500'
                                                : 'border-gray-300'
                                        "
                                        :disabled="!customMapStyle.url"
                                        @click="applyMapStyle"
                                    >
                                        <base-spinner
                                            v-if="isLoading"
                                            is-loading
                                            class="mx-auto h-4 w-4"
                                        />
                                        <span v-else>Apply</span>
                                    </button>
                                    <span
                                        v-if="showError && !isValid"
                                        class="absolute left-1 top-11 text-xs text-red-400"
                                    >
                                        Invalid style
                                    </span>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
            <div
                class="mt-6 flex w-full flex-auto flex-wrap justify-between gap-3 sm:flex-nowrap"
            >
                <button
                    type="button"
                    class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline focus:outline-none sm:text-sm sm:leading-5"
                    @click="$modal.hide('select-map-style-modal')"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    :disabled="!hasChanged || isInvalidCustomSelected"
                    class="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm sm:leading-5"
                    @click="onSave"
                >
                    Save
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import MapStyleItem from '@/components/storeDesigner/MapStyleItem';

export default {
    props: {
        currentUrl: {
            type: String,
            default: ''
        },
        coordinates: {
            type: Array,
            required: false,
            default: () => [-0.1381, 51.51]
        }
    },
    components: {
        MapStyleItem
    },
    data() {
        return {
            selected: '',
            isValid: false,
            isLoading: false,
            showError: false,
            isEditMode: false,
            customMapStyle: {
                url: '',
                name: 'Custom'
            },
            mapStyles: [
                {
                    url:
                        'mapbox://styles/benlarceysk/cm60rjvt4005c01s74u4k7f0g',
                    description:
                        'A complete basemap, perfect for incorporating your own data.',
                    name: 'Default'
                },
                {
                    url:
                        'mapbox://styles/benlarceysk/ck8enq76w032w1itcbgkkkrwc',
                    description:
                        'A complete basemap, perfect for incorporating your own data.',
                    name: 'Classic'
                },
                {
                    url:
                        'mapbox://styles/benlarceysk/cm7dehhn8006s01s2ecgf2o5o',
                    description:
                        'A complete basemap, perfect for incorporating your own data.',
                    name: 'Monochrome'
                },
                {
                  url:
                      'mapbox://styles/benlarceysk/cm7s4xbdn007q01sc3jvzfdai',
                  description:
                      'A complete basemap, perfect for incorporating your own data.',
                  name: 'Blueprint'
                },
                {
                    url:
                        'mapbox://styles/benlarceysk/cm7den4w700ai01s8ara32w6f',
                    description:
                        'A complete basemap, perfect for incorporating your own data.',
                    name: 'Neon'
                },
            ]
        };
    },
    computed: {
        hasChanged() {
            return this.currentUrl !== this.selected;
        },
        isInvalidCustomSelected() {
            return this.selected === this.customMapStyle.url && !this.isValid;
        }
    },
    methods: {
        onSave() {
            this.$modal.hide('select-map-style-modal');
            this.$emit('selected', this.selected);
        },
        onEdit() {
            this.customMapStyle.url = '';
            this.isValid = false;
            this.isEditMode = true;
        },
        reset() {
            const isCustom =
                !!this.currentUrl &&
                this.mapStyles.every(({ url }) => url !== this.currentUrl);

            if (isCustom) {
                this.isValid = true;
                this.customMapStyle.url =
                    this.customMapStyle.url || this.currentUrl;
            }

            this.showError = false;
            this.isEditMode = false;
            this.selected =
                this.selected || this.customMapStyle.url || this.currentUrl;
        },
        async applyMapStyle() {
            if (!this.customMapStyle.url) {
                return;
            }

            const [mapStyleId, nickname] = this.customMapStyle.url
                .split('/')
                .reverse();

            this.isLoading = true;

            try {
                const response = await fetch(
                    `https://api.mapbox.com/styles/v1/${nickname}/${mapStyleId}?access_token=pk.eyJ1IjoiYmVubGFyY2V5c2siLCJhIjoiY2pycXZnOHp5MDRuMDQ0cHJ4ZWttMGxsYSJ9.MQY0Fnhodssbf7FMG0EiHw`
                );

                if (!response.ok) {
                    this.isValid = false;
                    this.showError = true;

                    throw new Error(`Style error (HTTP ${response.status})`);
                }

                this.isValid = true;
                this.showError = false;
                this.isEditMode = false;
                this.selected = this.customMapStyle.url;
            } catch (err) {
                this.isValid = false;
                this.showError = true;

                throw err;
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
