<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <div>
                    <router-link
                        :to="{ name: 'editVenue' }"
                        class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                    >
                        <base-back-icon />
                        <span>Settings</span>
                    </router-link>
                </div>

                <base-page-title title="Branding" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                            >Logo</h3
                        >
                        <p class="mt-2 text-sm text-gray-500">
                            Add a logo to show on both your store and order
                            confirmation emails to customers.
                        </p>
                        <p class="mt-3 text-sm text-gray-500">
                            For the best results, upload a square image at the
                            highest resolution you have available and at least
                            160x160 pixels.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div class="mt-2 flex items-center">
                                <div
                                    class="block h-20 w-20 overflow-hidden rounded-full bg-gray-100"
                                >
                                    <svg
                                        class="h-full w-full text-gray-300"
                                        fill="currentColor"
                                        viewBox="0 0 24 24"
                                        v-if="!branding.logo"
                                    >
                                        <path
                                            d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                                        />
                                    </svg>
                                    <div
                                        v-else
                                        class="h-20 w-20 rounded-full shadow-inner"
                                    >
                                        <img
                                            :src="branding.logo"
                                            alt="Logo"
                                            class=""
                                        />
                                    </div>
                                </div>

                                <uploadcare-inline
                                    :ctxName="`${ctxName}logo`"
                                    class="w-9/12 bg-white"
                                    inputAcceptTypes="image/*"
                                    @success="onLogoSuccess"
                                >
                                </uploadcare-inline>
                            </div>
                            <div
                                v-if="isFeatureAvailable(FeaturesEnum.INTERNAL)"
                                class="mt-2 flex items-center"
                            >
                                <div
                                    class="block h-auto w-20 overflow-hidden rounded-md bg-gray-100"
                                >
                                    <svg
                                        class="h-full w-full text-gray-300"
                                        fill="currentColor"
                                        viewBox="0 0 24 24"
                                        v-if="!branding.logoNav"
                                    >
                                        <path
                                            d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                                        />
                                    </svg>
                                    <div
                                        v-else
                                        class="h-auto w-20 rounded-md shadow-inner"
                                    >
                                        <img
                                            :src="branding.logoNav"
                                            alt="Logo"
                                            class=""
                                        />
                                    </div>
                                </div>

                                <uploadcare-inline
                                    :ctxName="`${ctxName}navLogo`"
                                    class="w-9/12 bg-white"
                                    inputAcceptTypes="image/*"
                                    @success="onLogoNavSuccess"
                                >
                                </uploadcare-inline>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Meta title
                            <span class="text-sm text-gray-400">
                                (optional)
                            </span>
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Used to override the title tag. If left blank, the
                            store name will be used as default title value.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <base-input-field
                                type="text"
                                id="metaTitle"
                                v-model.trim="branding.metaTitle"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Meta description
                            <span class="text-sm text-gray-400">
                                (optional)
                            </span>
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Used to override the description tag. If left blank,
                            the store description will be used as default
                            description value.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:rounded-md">
                        <div
                            class="space-y-6 bg-white px-4 py-5 sm:rounded-md sm:p-6"
                        >
                            <div class="mt-1 sm:mt-0">
                                <div class="flex rounded-md shadow-sm">
                                    <base-text-area
                                        v-model.trim="branding.metaDescription"
                                        :rows="3"
                                        id="metaDescription"
                                        class="w-full"
                                        :maxLength="255"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                            >Custom branding</h3
                        >
                        <p class="mt-2 text-sm text-gray-500">
                            Create a completely bespoke design for your store
                            with custom fonts, colour schemes & more.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div class="mt-2 flex items-center">
                                <base-button
                                    @clicked="
                                        $router.push({ name: 'brandingUpsell' })
                                    "
                                    class="mt-2 sm:ml-3 sm:mt-0"
                                    buttonText="Enable custom branding"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Cover image
                            <span class="text-sm text-gray-400">
                                (optional)
                            </span>
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Upload a branded image to display at the top of your
                            store.
                        </p>
                        <p class="mt-2 text-sm text-gray-500">
                            For the best results upload an image larger than
                            800x320 pixels.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div>
                                <uploadcare-inline
                                    :ctxName="`${ctxName}cover`"
                                    inputAcceptTypes="image/*"
                                    @success="onCoverPhotoSuccess"
                                >
                                    <div
                                        :class="{
                                            'bg-gray-50': branding.headerImage
                                        }"
                                        class="mt-2 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pb-6 pt-6"
                                    >
                                        <div
                                            class="text-center"
                                            v-if="!branding.headerImage"
                                        >
                                            <p
                                                class="mt-1 text-xs text-gray-500"
                                            >
                                                PNG, JPG, GIF up to 10MB
                                            </p>
                                        </div>
                                        <div v-else class="w-full">
                                            <div
                                                class="relative mx-auto h-40 w-full max-w-md rounded-md bg-cover bg-center hover:opacity-75"
                                                :style="
                                                    'background-image: url(' +
                                                    branding.headerImage +
                                                    '/-/resize/1200x/-/format/auto/);'
                                                "
                                            >
                                                <div
                                                    class="flex h-full items-center justify-center opacity-0 transition duration-500 ease-in-out hover:opacity-100"
                                                >
                                                    <button
                                                        @click.stop="
                                                            branding.headerImage = null
                                                        "
                                                        type="button"
                                                        class="focus:outline-none ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </uploadcare-inline>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Favicon
                            <span class="text-sm text-gray-400">
                                (optional)
                            </span>
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Upload a favicon image to display as the icon in the
                            browser tab
                        </p>
                        <p class="mt-2 text-sm text-gray-500">
                            For the best results upload a square image 180x180
                            px.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div>
                                <uploadcare-inline
                                    :ctxName="`${ctxName}favIcon`"
                                    crop="512x512 minimum"
                                    inputAcceptTypes="image/*"
                                    @success="onFavIconSuccess"
                                >
                                    <div
                                        :class="{
                                            'bg-gray-50': branding.faviconImage
                                        }"
                                        class="mt-2 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pb-6 pt-6"
                                    >
                                        <div
                                            class="text-center"
                                            v-if="!branding.faviconImage"
                                        >
                                            <p
                                                class="mt-1 text-xs text-gray-500"
                                            >
                                                PNG or JPG up to 10MB
                                            </p>
                                        </div>
                                        <div v-else class="w-full">
                                            <div
                                                class="relative mx-auto h-40 max-w-md rounded-md bg-contain bg-center bg-no-repeat hover:opacity-75"
                                                :style="
                                                    'background-image: url(' +
                                                    branding.faviconImage +
                                                    '/-/resize/512x512/-/format/auto/);'
                                                "
                                            >
                                                <div
                                                    class="flex h-full items-center justify-center opacity-0 transition duration-500 ease-in-out hover:opacity-100"
                                                >
                                                    <button
                                                        @click.stop="
                                                            branding.faviconImage = null
                                                        "
                                                        type="button"
                                                        class="focus:outline-none ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </uploadcare-inline>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="branding.acceptsPat"
            class="hidden sm:block"
            aria-hidden="true"
        >
            <div class="py-5">
                <div class="border-t border-gray-200"></div>
            </div>
        </div>

        <div v-if="branding.acceptsPat">
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                            >Pay at table header image
                            <span class="text-sm text-gray-400"
                                >(optional)</span
                            >
                        </h3>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div>
                                <uploadcare-inline
                                    :ctxName="`${ctxName}patImage`"
                                    inputAcceptTypes="image/*"
                                    @success="onPatImageSuccess"
                                >
                                    <div
                                        :class="{
                                            'bg-gray-50': branding.patImage
                                        }"
                                        class="mt-2 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pb-6 pt-6"
                                    >
                                        <div
                                            class="text-center"
                                            v-if="!branding.patImage"
                                        >
                                            <p
                                                class="mt-1 text-xs text-gray-500"
                                            >
                                                PNG, JPG, GIF up to 10MB
                                            </p>
                                        </div>
                                        <div v-else class="w-full">
                                            <div
                                                class="relative mx-auto h-40 w-full max-w-md rounded-md bg-cover bg-center hover:opacity-75"
                                                :style="
                                                    'background-image: url(' +
                                                    branding.patImage +
                                                    '/-/format/auto/);'
                                                "
                                            >
                                                <div
                                                    class="flex h-full items-center justify-center opacity-0 transition duration-500 ease-in-out hover:opacity-100"
                                                >
                                                    <button
                                                        @click.stop="
                                                            branding.patImage = null
                                                        "
                                                        type="button"
                                                        class="focus:outline-none ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </uploadcare-inline>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="isOrderAndPay" class="hidden sm:block" aria-hidden="true">
            <div class="py-5">
                <div class="border-t border-gray-200"></div>
            </div>
        </div>

        <div v-if="isOrderAndPay">
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                            >Splash Screen
                            <span class="text-sm text-gray-400"
                                >(optional)</span
                            >
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            We'll show use this image as the background for the
                            table selection process, it's optional but if you do
                            want to upload a branded image we recommend a size
                            of 2048x2732 pixels.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div>
                                <uploadcare-inline
                                    :ctxName="`${ctxName}tableScreen`"
                                    inputAcceptTypes="image/*"
                                    @success="onTableScreenImageSuccess"
                                >
                                    <div
                                        :class="{
                                            'bg-gray-50':
                                                branding.tableScreenImage
                                        }"
                                        class="mt-2 flex cursor-pointer justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pb-6 pt-6"
                                    >
                                        <div
                                            class="text-center"
                                            v-if="!branding.tableScreenImage"
                                        >
                                            <p
                                                class="mt-1 text-xs text-gray-500"
                                            >
                                                PNG, JPG, GIF up to 10MB
                                            </p>
                                        </div>
                                        <div v-else class="w-full">
                                            <div
                                                class="relative mx-auto h-40 w-full max-w-md rounded-md bg-cover bg-center hover:opacity-75"
                                                :style="
                                                    'background-image: url(' +
                                                    branding.tableScreenImage +
                                                    '/-/format/auto/);'
                                                "
                                            >
                                                <div
                                                    class="flex h-full items-center justify-center opacity-0 transition duration-500 ease-in-out hover:opacity-100"
                                                >
                                                    <button
                                                        @click.stop="
                                                            branding.tableScreenImage = null
                                                        "
                                                        type="button"
                                                        class="focus:outline-none ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </uploadcare-inline>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                            >Brand colour
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Set a custom colour we'll use to accent your store.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:rounded-md">
                        <div
                            class="space-y-6 bg-white px-4 py-5 sm:rounded-md sm:p-6"
                        >
                            <div>
                                <label
                                    class="block text-sm font-medium text-gray-700"
                                    >Brand Colour
                                    <span class="text-gray-400"
                                        >(optional)</span
                                    ></label
                                >
                                <v-swatches
                                    class="mt-3"
                                    v-model="branding.primaryColour"
                                    show-fallback
                                    :swatches="colorSwatches"
                                    fallback-input-type="color"
                                    fallback-ok-text="Select"
                                ></v-swatches>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Currency Symbol
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Show the currency symbol ({{
                                venue.currencyCode | currency
                            }}) on the menu
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div>
                                <toggle-switch
                                    v-model="branding.showCurrencySymbol"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="isFeatureAvailable(FeaturesEnum.INTERNAL)">
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Large images
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Show large images on the menu
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div>
                                <toggle-switch v-model="branding.largeImages" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3 class="text-base font-semibold leading-6 text-gray-800">
                            Modal Image Style
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Select the size of images displayed in your modal.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <select 
                                v-model="branding.modalImageStyle"
                                class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            >
                                <option value="medium">Medium</option>
                                <option value="large">Large</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="isFeatureAvailable(FeaturesEnum.INTERNAL)">
          <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="sm:pl-0 sm:pr-5">
                <h3 class="text-base font-semibold leading-6 text-gray-800">
                  Low stock display
                </h3>
                <p class="mt-2 text-sm text-gray-500">
                  Choose how inventory is displayed on item modals.
                </p>
              </div>
            </div>
            <div class="mt-5 md:col-span-2 md:mt-0">
              <div class="shadow sm:overflow-hidden sm:rounded-md">
                <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <select
                      v-model="branding.showInventoryQuantity"
                      class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  >
                    <option value="hide">Hide</option>
                    <option value="low_only">Low only (10 or less)</option>
                    <option value="exact">Always display inventory</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <BaseSpacerWithRuler />

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Menu Notice
                            <span class="text-sm text-gray-400">
                                (optional)
                            </span>
                        </h3>
                        <div class="text-sm text-gray-500">
                            <p class="mt-2">
                                Show a message beneath your menu
                            </p>
                            <p class="mt-2">
                                Tip: We'll automatically make links clickable
                            </p>
                        </div>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:rounded-md">
                        <div
                            class="space-y-6 bg-white px-4 py-5 sm:rounded-md sm:p-6"
                        >
                            <div class="mt-1 sm:col-span-2 sm:mt-0">
                                <base-text-area
                                    v-model.trim="branding.menuNotice"
                                    :rows="4"
                                    id="menuNotice"
                                    class="w-full"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Closed Notice
                        </h3>
                        <div class="text-sm text-gray-500">
                            <p class="mt-2">
                                Customise the message shown to customers when
                                your store is set to not accepting orders.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:rounded-md">
                        <div
                            class="space-y-6 bg-white px-4 py-5 sm:rounded-md sm:p-6"
                        >
                            <div class="mt-1 sm:mt-0">
                                <base-text-area
                                    v-model.trim="branding.busyMessage"
                                    :rows="4"
                                    :errors="$v.branding.busyMessage.$error"
                                    id="busyMessage"
                                    class="w-full"
                                />
                                <span
                                    v-if="$v.branding.busyMessage.$error"
                                    class="text-xs text-red-600"
                                >
                                    This field is required
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <base-spacer-with-ruler
            v-if="isFeatureAvailable(FeaturesEnum.INTERNAL)"
        />

        <div v-if="isFeatureAvailable(FeaturesEnum.INTERNAL) || isFeatureAvailable(FeaturesEnum.SAAS)">
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Heading font
                            <span class="text-sm text-gray-400">
                                (optional)
                            </span>
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Upload a custom font to be display in the headers of
                            your store.
                        </p>
                        <p class="mt-2 text-sm text-gray-500">
                            For the best results, upload a font in WOFF2 or WOFF
                            format no larger than 50KB.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-4 sm:px-6">
                            <div>
                                <uploadcare-inline
                                    :ctxName="`${ctxName}heading_font`"
                                    inputAcceptTypes=".woff,.woff2"
                                    @success="onHeadingFontSuccess"
                                >
                                    <div
                                        v-if="branding.fonts && branding.fonts.heading"
                                        class="mt-2 flex justify-center bg-gray-50 rounded-md border-2 border-dashed border-gray-300 px-6 pb-6 pt-6"
                                    >
                                        <div
                                            class="w-full"
                                        >
                                            <div
                                                class="relative mx-auto w-full max-w-md rounded-md bg-cover bg-center"
                                            >
                                                <div
                                                    class="flex h-full items-center justify-center transition duration-500 ease-in-out"
                                                >
                                                    <span class="px-3 py-2">
                                                        {{
                                                            branding.fonts
                                                                .heading.name
                                                        }}.{{
                                                            branding.fonts
                                                                .heading
                                                                .extension
                                                        }}
                                                    </span>
                                                  <base-button
                                                      @clicked="branding.fonts.heading = null"
                                                      size="sm"
                                                      buttonText="Remove"
                                                  />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </uploadcare-inline>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="isFeatureAvailable(FeaturesEnum.INTERNAL) || isFeatureAvailable(FeaturesEnum.SAAS)">
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Body font
                            <span class="text-sm text-gray-400">
                                (optional)
                            </span>
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Upload a custom font to be display in the body of
                            your store.
                        </p>
                        <p class="mt-2 text-sm text-gray-500">
                            For the best results, upload a font in WOFF2 or WOFF
                            format no larger than 50KB.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 sm:px-6">
                            <div>
                                <uploadcare-inline
                                    :ctxName="`${ctxName}body_font`"
                                    @success="onBodyFontSuccess"
                                    inputAcceptTypes=".woff,.woff2"
                                    crop="512x512 minimum"
                                >
                                    <div
                                        v-if="
                                                branding.fonts &&
                                                branding.fonts.body
                                            "
                                        class="mt-2 flex justify-center rounded-md bg-gray-50 border-2 border-dashed border-gray-300 px-6 pb-6 pt-6"
                                    >
                                        <div

                                            class="w-full"
                                        >
                                            <div
                                                class="relative mx-auto max-w-md rounded-md bg-contain bg-center bg-no-repeat"
                                            >
                                                <div
                                                    class="flex h-full items-center justify-center transition duration-500 ease-in-out"
                                                >
                                                    <span class="px-3 py-2">
                                                        {{
                                                            branding.fonts.body
                                                                .name
                                                        }}.{{
                                                            branding.fonts.body
                                                                .extension
                                                        }}
                                                    </span>

                                                    <button
                                                        @click.stop="
                                                            branding.fonts.body = null
                                                        "
                                                        type="button"
                                                        class="focus:outline-none ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </uploadcare-inline>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
            <span class="inline-flex rounded-md shadow-sm" @click="saveVenue">
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading"
                >
                    Save
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import VSwatches from 'vue-swatches';
import { mapActions, mapGetters } from 'vuex';
import { FeaturesEnum } from '@/enums';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';
import UploadcareInline from '@/components/widgets/UploadcareInline';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'BrandingSettings',
    props: {
        venueId: {
            type: Number,
            required: true
        },
        venue: {
            type: Object,
            required: true
        }
    },
    metaInfo: {
        title: 'Branding Settings'
    },
    validations: {
        branding: {
            busyMessage: {
                required
            }
        }
    },
    data() {
        return {
            FeaturesEnum,
            isLoading: false,
            primaryColour: '#161e2e',
            branding: {
                ...this.venue,
            },
            ctxName: this.venue.slug,
            colorSwatches: [
                '#000000',
                '#222831',
                '#14213d',
                '#393e46',
                '#583d72',
                '#9f5f80',
                '#99c1de',
                '#84a59d',
                '#ffd369',
                '#ffba93',
                '#f5cac3',
                '#ff8e71'
            ]
        };
    },
    components: { UploadcareInline, VSwatches, ToggleSwitch },
    methods: {
        ...mapActions({
            updateVenueSettings: 'venues/update'
        }),
        async saveVenue() {
            this.isLoading = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                this.isLoading = false;

                return false;
            }

            try {
                const payload = {
                    id: this.venueId,
                    headerImage: this.branding.headerImage,
                    faviconImage: this.branding.faviconImage,
                    logo: this.branding.logo,
                    logoNav: this.branding.logoNav,
                    tableScreenImage: this.branding.tableScreenImage,
                    patImage: this.branding.patImage,
                    primaryColour: this.branding.primaryColour || '',
                    showCurrencySymbol: this.branding.showCurrencySymbol,
                    largeImages: this.branding.largeImages,
                    modalImageStyle: this.branding.modalImageStyle,
                    menuNotice: this.branding.menuNotice,
                    busyMessage: this.branding.busyMessage,
                    showInventoryQuantity: this.branding.showInventoryQuantity,
                    fonts: {
                        ...(this.branding.fonts && this.branding.fonts.heading
                            ? { heading: this.branding.fonts.heading }
                            : null),
                        ...(this.branding.fonts && this.branding?.fonts.body
                            ? { body: this.branding.fonts.body }
                            : null)
                    },
                    metaTitle: this.branding.metaTitle,
                    metaDescription: this.branding.metaDescription
                };

                await this.updateVenueSettings(payload);

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        onCoverPhotoSuccess(event) {
            this.branding.headerImage = event.cdnUrl;
        },
        onHeadingFontSuccess(event) {
            if (Array.isArray(event) && !event.length) {
                return;
            }

            const parts =
                event.name.indexOf('.') > 0
                    ? event.name.toLowerCase().split('.')
                    : [];

            this.branding.fonts = {
                ...this.branding.fonts,
                heading: {
                    url: event.cdnUrl,
                    name:
                        parts
                            .shift()
                            .trim()
                            .replace(/[^\w\s-]/g, '')
                            .replace(/[\s_-]+/g, '-')
                            .replace(/^-+|-+$/g, '') || 'undefined',
                    extension: parts.pop() || 'undefined'
                }
            };
        },
        onBodyFontSuccess(event) {
            if (Array.isArray(event) && !event.length) {
                return;
            }

            const parts =
                event.name.indexOf('.') > 0
                    ? event.name.toLowerCase().split('.')
                    : [];

            this.branding.fonts = {
                ...this.branding.fonts,
                body: {
                    url: event.cdnUrl,
                    name:
                        parts
                            .shift()
                            .trim()
                            .replace(/[^\w\s-]/g, '')
                            .replace(/[\s_-]+/g, '-')
                            .replace(/^-+|-+$/g, '') || 'undefined',
                    extension: parts.pop() || 'undefined'
                }
            };
        },
        onFavIconSuccess(event) {
            this.branding.faviconImage = event.cdnUrl;
        },
        onLogoSuccess(event) {
            this.branding.logo = event.cdnUrl;
        },
        onTableScreenImageSuccess(event) {
            this.branding.tableScreenImage = event.cdnUrl;
        },
        onPatImageSuccess(event) {
            this.branding.patImage = event.cdnUrl;
        },
        onLogoNavSuccess(event) {
            this.branding.logoNav = event.cdnUrl;
        },
        imageProgress(uploadInfo) {
            if (uploadInfo.state === 'uploading') {
                this.isImageReady = false;
            }

            if (uploadInfo.state === 'ready') {
                this.isImageReady = true;
            }
        }
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable'
        }),
        isOrderAndPay() {
            return this.branding.acceptsInStore;
        }
    }
};
</script>

<style>
.vue-swatches__fallback__input {
    padding: 1px !important;
    border-radius: 5px !important;
    border: 1px solid white !important;
}
</style>
