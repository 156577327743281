<template>
    <div :class="{ 'thumbnail-only': thumbnailOnly }">
        <input
            :id="inputId"
            :ref="inputId"
            :value="value"
            :placeholder="placeholder"
            type="text"
            class="pr-8 focus:border-blue-300 focus:shadow-outline-blue"
            :class="[
                thumbnailOnly
                    ? 'h-7 w-7 cursor-pointer rounded-lg border-0 bg-transparent p-0 text-transparent'
                    : 'w-full rounded-md border-gray-300 py-1 text-sm text-gray-900 shadow-sm transition duration-150 ease-in-out'
            ]"
            @input="onInput"
            @change="onChange"
        />
    </div>
</template>

<script>
import Coloris from '@melloware/coloris';
import '@melloware/coloris/dist/coloris.css';
import { isMobile } from 'mobile-device-detect';

export default {
    props: {
        value: {
            type: String,
            required: false,
            default: ''
        },
        alpha: {
            type: Boolean,
            required: false,
            default: true
        },
        format: {
            type: String,
            required: false,
            default: 'hex'
        },
        swatches: {
            type: Array,
            required: false,
            default: () => []
        },
        thumbnailOnly: {
            type: Boolean,
            required: false,
            default: false
        },
        defaultColour: {
            type: String,
            required: false,
            default: '#ffffff'
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            inputId: `coloris-${Math.random().toString(36).substring(2)}`
        };
    },
    watch: {
        value() {
            this.triggerInputEvent();
        },
        swatches() {
            Coloris.set({ swatches: this.swatches });
        }
    },
    mounted() {
        this.initColoris();
    },
    beforeDestroy() {
        Coloris.close();
    },
    methods: {
        initColoris() {
            Coloris.init();
            Coloris({
                el: `#${this.inputId}`,
                swatches: this.swatches,
                focusInput: !this.isMobile,
                alpha: this.alpha,
                format: this.format,
                wrap: true,
                eyeDropper: true
            });
        },
        triggerInputEvent() {
            if (this.$refs[this.inputId]) {
                this.$nextTick(() => {
                    this.$refs[this.inputId].dispatchEvent(
                        new CustomEvent('input', {
                            bubbles: true,
                            detail: { isRefresh: true }
                        })
                    );
                });
            }
        },
        onInput(event) {
            if (event.detail?.isRefresh) {
                return;
            }

            this.$emit('input', event.target.value);
        },
        onChange(event) {
            this.$emit('change', event.target.value);
        }
    },
    computed: {
        isMobile() {
            return isMobile;
        }
    }
};
</script>

<style>
.clr-field {
    width: 140px;
}

.clr-field button {
    background-image: unset;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border: 1px solid #d1d5db;
}

.clr-field:focus-within button {
    border: 1px solid #93c5fd;
}

.clr-field button::after {
    box-shadow: unset;
    border-radius: 6px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.thumbnail-only .clr-field button {
    width: 100%;
    height: 100%;
    border-radius: 7px;
    background-image: unset;
}
</style>
