import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const languages = [
  'en', 'es', 'fr', 'nl', 'de',
  'es-419', 'fi', 'et', 'hu', 'it',
  'no', 'pl', 'pt', 'sk-SK', 'sr',
  'sv', 'tr', 'uk', 'zh-CN', 'zh-TW',
  'af', 'da', 'el'
];

function loadLocaleMessages() {
  const messages = {};
  languages.forEach((lang) => {
    // Synchronously require the locale file.
    // This will be bundled at build-time if you use Webpack or similar.
    messages[lang] = require(`./locales/${lang}.json`);
  });
  return messages;
}

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: loadLocaleMessages()
});
