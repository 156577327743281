export function cleanInstagramUsername(username) {
    if (!username) return '';
    
    try {
        // Check if it's a URL
        if (username.includes('instagram.com') || username.includes('://')) {
            const url = new URL(username.startsWith('http') ? username : `https://${username}`);
            // Extract username from path
            const pathParts = url.pathname.split('/').filter(Boolean);
            return pathParts[0] || '';
        }
    } catch (e) {
        // If URL parsing fails, treat as raw username
    }
    
    // Remove @ if present and trailing slashes
    return username.replace(/^@/, '').replace(/\/+$/, '');
}
