<template>
    <div
        class="md:flex md:flex-shrink-0 print:hidden"
        @keydown.esc="sidebarOpen = false"
        v-if="
            $route.path !== '/login' &&
            $route.path !== '/signup' &&
            $route.path !== '/reset' &&
            $route.name !== 'liveOrders' &&
            $route.name !== 'createVenue' &&
            $route.name !== 'change' &&
            $route.name !== 'createMenu' &&
            $route.name !== 'createMenuImport' &&
            $route.name !== 'menuImportSpreadsheet' &&
            $route.name !== 'signupWithReferral' &&
            $route.name !== 'liveTables' &&
            $route.name !== 'oauthLogin' &&
            $route.name !== 'oauthConsent' &&
            $route.name !== 'discountsImport' &&
            $route.name !== 'storeDesigner'
        "
    >
        <!-- Off-canvas menu for mobile -->
        <div v-if="$store.state.ui.sidebarOpen" class="lg:hidden">
            <transition
                enter-active-class="transition-opacity ease-linear duration-300"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition-opacity ease-linear duration-300"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div
                    @click="$store.commit('ui/UPDATE_SIDEBAR', false)"
                    class="fixed inset-0 z-30 transition-opacity duration-300 ease-linear"
                >
                    <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
                </div>
            </transition>

            <div
                v-show="$store.state.ui.sidebarOpen"
                class="fixed inset-0 z-40 flex"
            >
                <transition
                    enter-active-class="transition ease-in-out duration-300 transform"
                    enter-class="-translate-x-full"
                    enter-to-class="translate-x-0"
                    leave-active-class="transition ease-in-out duration-300 transform"
                    leave-class="translate-x-0"
                    leave-to-class="-translate-x-full"
                >
                    <MobileSidebar v-once />
                </transition>
                <div class="w-14 flex-shrink-0">
                    <!-- Force sidebar to shrink to fit close icon -->
                </div>
            </div>
        </div>

        <!-- Static sidebar for desktop -->

        <DesktopSidebar v-once />
    </div>
</template>

<script>
import MobileSidebar from './MobileSidebar.vue';
import DesktopSidebar from './DesktopSidebar.vue';

export default {
    name: 'sidebar-container',
    components: {
        MobileSidebar,
        DesktopSidebar
    }
};
</script>

<style>

.slide-enter-active,
.slide-leave-active {
    transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
    transform: translateX(-100%);
    transition: all 150ms ease-in 0s;
}

</style>
