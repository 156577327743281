<template>
    <div
        :class="{
            ' cursor-not-allowed opacity-50': disabled
        }"
    >
        <slot name="label">
            <label
                v-if="label"
                :for="id"
                v-tooltip="tooltip"
                class="mb-1 flex items-center text-sm font-medium leading-5 text-gray-700"
            >
                {{ label }}
                <span v-if="labelPostfix" class="font-normal text-gray-500">
                    {{ labelPostfix }}
                </span>
                <base-question-icon v-if="tooltip" class="ml-1" />
            </label>
        </slot>

        <slot name="caption">
            <p v-if="caption" class="mb-1 text-sm leading-5 text-gray-500">
                {{ caption }}
            </p>
        </slot>

        <div
            :class="{ 'flex rounded-md shadow-sm': prefix }"
            class="sm:col-span-2"
        >
            <span
                v-if="prefix"
                class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm"
            >
                {{ prefix }}
            </span>

            <div
                class="w-full"
                :class="[
                    {
                        'relative mt-1 rounded-md shadow-sm': maxLength
                    },
                    wrapperClass
                ]"
            >
                <textarea
                    v-bind="{
                        id,
                        rows,
                        placeholder,
                        disabled,
                        maxLength,
                        min,
                        max
                    }"
                    :value="value"
                    @change="$emit('input', $event.target.value)"
                    class="focus:outline-none form-input block w-full border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-gray-800 focus:shadow-outline-black focus:ring-gray-800 focus:ring-offset-1 sm:text-sm sm:leading-5"
                    :class="{
                        'border-red-300': errors,
                        'rounded-none rounded-r-md': prefix,
                        'rounded-md': !prefix,
                        'pr-14': maxLength,
                        'pointer-events-none': disabled
                    }"
                />
                <div
                    v-if="maxLength"
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                >
                    <span
                        v-if="value"
                        class="text-gray-500 sm:text-sm sm:leading-5"
                    >
                        {{ value.length }}/{{ maxLength }}
                    </span>
                </div>
            </div>
        </div>

        <slot name="errorMessage">
            <p class="mt-2 text-xs text-red-400" v-if="errors">
                {{ errorMessage }}
            </p>
        </slot>

        <slot name="tip">
            <p v-if="tip" class="mt-2 text-sm text-gray-500">
                {{ tip }}
            </p>
        </slot>
    </div>
</template>

<script>
export default {
    name: 'BaseTextArea',
    props: {
        disabled: Boolean,
        wrapperClass: String,
        rows: Number,
        label: String,
        id: [String, Number],
        tip: String,
        errorMessage: String,
        labelPostfix: String,
        prefix: String,
        maxLength: Number,
        min: Number,
        max: Number,
        placeholder: String,
        caption: String,
        errors: [String, Object, Boolean],
        tooltip: String,
        value: [String, Number]
    }
};
</script>
