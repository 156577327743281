var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex w-0 flex-1 flex-col overflow-hidden"},[_c('ToggleSidebar'),_c('main',{staticClass:"focus:outline-none relative z-0 flex-1 overflow-y-auto",attrs:{"tabindex":"0"}},[_c('div',{staticClass:"pb-6 pt-2 md:py-6"},[_c('div',{staticClass:"mx-auto px-4 sm:px-6 lg:px-8"},[_c('div',{staticClass:"md:flex md:items-center md:justify-between"},[_c('div',{staticClass:"min-w-0 flex-1"},[_c('BasePageTitle',{attrs:{"title":_vm.$t('views.discounts.title')}})],1),_c('div',{staticClass:"mt-4 flex justify-end sm:mr-4 md:ml-4 md:mt-0"},[_c('ExportButton',{on:{"clicked":_vm.showExportCsvModal}}),_c('base-button',{attrs:{"buttonText":_vm.$t('views.discounts.import'),"size":"sm"},on:{"clicked":function($event){return _vm.$router.push({ name: 'discountsImport' })}}}),_c('base-button',{staticClass:"ml-3",attrs:{"size":"sm","button-text":_vm.$t('views.discounts.createDiscount'),"button-style":"action"},on:{"clicked":function($event){return _vm.$router.push({ name: 'newDiscount' })}}})],1),_c('BaseSpinner',{attrs:{"isLoading":_vm.isLoading}})],1)]),_c('div',{staticClass:"mx-auto px-0 py-4 sm:px-6 md:px-8"},[_c('div',{staticClass:"rounded bg-white shadow-md sm:rounded-lg"},[_c('div',{staticClass:"flex flex-col justify-between px-2 py-2.5 sm:flex-row sm:px-6"},[_c('ul',{staticClass:"mb-2 flex flex-row items-center sm:mb-0"},_vm._l((_vm.discountFilters),function(filter){return _c('li',{key:filter.value,staticClass:"mr-1 cursor-pointer rounded-md px-3 py-1 text-xs transition duration-150 ease-in-out hover:bg-gray-50",class:{
                                    'bg-gray-100':
                                        filter.value === _vm.serverParams.status
                                },on:{"click":function($event){return _vm.updateParams({
                                        status: filter.value,
                                        page: 1
                                    })}}},[_vm._v(" "+_vm._s(filter.label)+" ")])}),0),_c('div',{staticClass:"flex items-center"},[_c('search-input',{staticClass:"mr-3",attrs:{"min":3,"max":9,"size":"sm","placeholder":_vm.$t('views.discounts.searchPlaceholder')},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('venue-selector',{key:_vm.venues ? _vm.venues.length : 0,attrs:{"venues":_vm.venues,"multiple-select":true,"allow-empty":true,"size":"sm"},on:{"selected":_vm.onVenueChange}})],1)]),_c('vue-good-table',{staticClass:"border-b border-gray-200",attrs:{"mode":"remote","columns":_vm.columns,"rows":_vm.discountCodes,"styleClass":"tailwind-table","row-style-class":"mb-1 cursor-pointer","totalRows":_vm.totalRecords,"pagination-options":{
                            enabled: true,
                            perPage: _vm.serverParams.limit,
                            dropdownAllowAll: false,
                            rowsPerPageLabel: '',
                            perPageDropdown: [10, 20, 50, 100, 200],
                            setCurrentPage: _vm.serverParams.page
                        }},on:{"on-row-click":_vm.goToDiscountDetails,"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'code')?_c('div',{staticClass:"group flex items-center"},[_c('div',[_c('div',{staticClass:"flex items-center font-mono text-sm font-bold leading-5 text-gray-600"},[_vm._v(" "+_vm._s(props.row.code.toUpperCase())+" "),_c('div',{staticClass:"opacity-0 inline-flex group-hover:opacity-100 mx-1 transition duration-150 ease-in-out",on:{"click":function($event){$event.stopPropagation();_vm.copyToClipboard(props.row.code.toUpperCase())}}},[_c('base-clipboard-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Click to copy code'),expression:"'Click to copy code'"}],staticClass:"w-4 h-4"})],1),(props.row.autoApply)?_c('span',{staticClass:"inline-flex items-center rounded-md bg-green-50 px-2 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-300"},[_vm._v("AUTO")]):_vm._e()]),(
                                            props.row.type ===
                                            _vm.DiscountTypeEnum.FIXED
                                        )?_c('div',{staticClass:"text-xs leading-5 text-gray-500"},[_vm._v(" "+_vm._s(_vm._f("currency")((props.row.codeAmount / 100)))+" "+_vm._s(_vm.$t('views.discounts.offEntireOrder'))),(props.row.excludeDelivery)?_c('span',[_vm._v(_vm._s(_vm.$t('views.discounts.excludingDelivery')))]):_vm._e()]):_vm._e(),(
                                            props.row.type ===
                                            _vm.DiscountTypeEnum.PERCENT
                                        )?_c('div',{staticClass:"text-xs leading-5 text-gray-500"},[_vm._v(" "+_vm._s(( props.row.codeAmount / 10 ).toFixed(1))+"% "+_vm._s(_vm.$t('views.discounts.offEntireOrder'))),(props.row.excludeDelivery)?_c('span',[_vm._v(_vm._s(_vm.$t('views.discounts.excludingDelivery')))]):_vm._e()]):_vm._e(),(
                                            props.row.type ===
                                            _vm.DiscountTypeEnum.FREE_DELIVERY
                                        )?_c('div',{staticClass:"text-xs leading-5 text-gray-500"},[_vm._v(" "+_vm._s(_vm.$t('views.discounts.freeDelivery'))+" ")]):_vm._e(),(
                                            props.row.type ===
                                            _vm.DiscountTypeEnum.BUY_ONE_GET_ONE_FREE
                                        )?_c('div',{staticClass:"text-xs leading-5 text-gray-500"},[_vm._v(" "+_vm._s(_vm.$t('views.discounts.buyOneGetOne'))+" "+_vm._s(props.row.codeAmount / 10)+"% "+_vm._s(_vm.$t('views.discounts.offTheSecond'))+" ")]):_vm._e()])]):(
                                    props.column.field === 'archived'
                                )?_c('status-badge',{attrs:{"status":_vm.discountStatus(props.row)}}):(
                                    props.column.field === 'usageCount'
                                )?_c('div',{staticClass:"text-sm leading-5 text-gray-500"},[_vm._v(" "+_vm._s(props.row.usageCount)+"/"+_vm._s(props.row.usageLimit === 0 ? '∞' : props.row.usageLimit)+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('template',{slot:"emptystate"},[_c('div',{staticClass:"flex h-40 w-full items-center justify-center bg-white"},[(!_vm.isLoading && _vm.totalRecords === 0)?_c('h3',{staticClass:"text-sm font-medium text-gray-700"},[_vm._v(" "+_vm._s(_vm.$t('views.discounts.noDiscountsFound'))+" ")]):_c('span',{staticClass:"spinner"})])]),_c('template',{slot:"loadingContent"},[_c('div',{staticClass:"flex h-40 w-full items-center justify-center bg-white"},[_c('span',{staticClass:"spinner"})])])],2)],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }