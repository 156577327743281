<template>
    <div
        class="flex flex-col justify-start px-4 py-12 w-full min-h-screen bg-gray-50 sm:justify-center sm:px-6 lg:px-8"
    >
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
            <div
                class="px-4 py-8 bg-white shadow-xl sm:px-10 sm:px-5 sm:py-10 sm:rounded-md md:px-14 md:py-14"
            >
                <h2
                    class="mb-6 text-left text-gray-700 text-2xl font-bold leading-9"
                >
                    QR Builder
                </h2>

                <base-input-field
                    type="text"
                    label="URL"
                    id="vendor-id"
                    :disabled="false"
                    v-model="value"
                >
                </base-input-field>
                <div
                    class="block h-64 p-4 rounded-sm shadow-inner cursor-pointer m-10"
                >
                    <qr-code
                        class="mx-auto rounded-lg"
                        :url="value"
                        ref="qr"
                        :fileName="`${value}-qr-code`"
                    ></qr-code>
                </div>
                <span class="inline-flex pl-4">
                    <div
                        v-click-outside="onClickOutside"
                        class="relative print:hidden mr-4 select-none md:mr-6 mt-10"
                    >
                        <button
                            type="button"
                            @click="showMoreActions = !showMoreActions"
                            class="inline-flex shadow-sm items-center px-4 py-2 hover:text-gray-500 text-gray-700 active:text-gray-800 text-sm font-medium leading-5 active:bg-gray-50 bg-white border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out"
                        >
                            Download QR Code
                        </button>
                        <transition
                            enter-active-class="transition ease-out duration-100"
                            enter-class="opacity-0 scale-95"
                            enter-to-class="opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-class="opacity-100 scale-100"
                            leave-to-class="opacity-0 scale-95"
                        >
                            <div
                                v-if="showMoreActions"
                                class="absolute z-50 left-0 bottom-12 mt-2 w-48 rounded-md shadow-lg origin-top-right"
                            >
                                <div
                                    class="bg-white rounded-md shadow-sm overflow-hidden"
                                    role="menu"
                                    aria-orientation="vertical"
                                    aria-labelledby="options-menu"
                                >
                                    <span
                                        class="py-1 w-full text-center group flex items-center px-4 py-2 text-gray-700 hover:text-gray-900 focus:text-gray-900 text-sm leading-5 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none cursor-pointer"
                                        role="menuitem"
                                        @click="saveQR('svg')"
                                    >
                                        <download-icon
                                            class="h-5 w-5 mr-2 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                                        />

                                        Download as SVG
                                    </span>
                                    <div class="border-t border-gray-100"></div>
                                    <span
                                        class="py-1 w-full text-center group flex items-center px-4 py-2 text-gray-700 hover:text-gray-900 focus:text-gray-900 text-sm leading-5 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none cursor-pointer"
                                        role="menuitem"
                                        @click="saveQR('png')"
                                    >
                                        <download-icon
                                            class="h-5 w-5 mr-2 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                                        />
                                        Download as PNG
                                    </span>
                                </div>
                            </div>
                        </transition>
                    </div>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import QrCode from '@/components/QrCode';
import vClickOutside from 'v-click-outside';
import DownloadIcon from '@/components/icons/DownloadIcon';

export default {
    name: 'QrBuilder',
    components: {
        QrCode,
        DownloadIcon
    },
    metaInfo: {
        title: 'QR Builder'
    },
    data() {
        return {
            value: 'https://storekit.com',
            showMoreActions: false
        };
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    methods: {
        saveQR(type) {
            this.$refs.qr.download(type);
        },
        onClickOutside() {
            this.showMoreActions = false;
        }
    }
};
</script>

<style scoped>
.spinner:after {
    left: auto;
    right: 1em;
    border-right-color: white;
}
</style>
