<template>
    <modal
        name="snooze-item-availability-modal"
        width="600px"
        height="auto"
        class="snooze-item-availability-modal overflow-visible"
        :clickToClose="true"
        @before-close="beforeClose"
        @before-open="beforeOpen"
        :adaptive="true"
    >
        <div
            class="transform rounded-lg bg-white p-6 shadow-xl transition-all sm:w-full"
        >
            <div class="text-left">
                <h3 class="text-lg font-medium font-heading leading-6 text-gray-900">
                  Snooze <b>{{product.name}}</b>:
                </h3>
                <div class="mt-4 flex items-center justify-between">
                    <div class="flex w-full items-center">
                        <label
                            v-for="(option, key) in options"
                            @click="selectOption(option.value)"
                            :key="key"
                            class="focus:outline-none mr-2 flex flex-shrink-0 cursor-pointer items-center justify-center rounded-md border py-2 px-3 text-sm font-medium transition-colors hover:border-indigo-600"
                            :class="{
                                'cursor-not-allowed opacity-25': !option.active,
                                'border-transparent bg-indigo-600 text-white hover:bg-indigo-700 hover:text-white':
                                    optionSelected === option.value,
                                'hover:text-indigo-600':
                                    optionSelected !== option.value
                            }"
                        >
                            <span id="size-choice-0-label">
                                {{ option.label }}
                            </span>
                        </label>
                    </div>
                </div>
              <div class="flex items-center sm:flex-1 mt-4">
                        <span class="mr-2 min-w-max text-gray-600">
                            or until
                        </span>
                <DateRangeSelector
                    @date-updated="selectPicker"
                    :styles="pickerStyles"
                    aligned="left"
                    :singleDate="true"
                    :allowFuture="true"
                    :disallowPast="true"
                    :time-picker="true"
                />
              </div>
            </div>
            <div
                class="mt-5 flex-row-reverse justify-between sm:mt-8 flex w-auto"
                :class="{ spinner: isLoading }"
            >

              <base-button
                  :disabled="!optionSelected && !dateSelected"
                  @clicked="snooze"
                  size="sm"
                  buttonText="Snooze"
                  button-style="action"
              />
              <base-button
                  @clicked="$modal.hide('snooze-item-availability-modal')"
                  size="sm"
                  buttonText="Close"
              />
            </div>
        </div>
    </modal>
</template>

<script>
import DateRangeSelector from '@/components/widgets/DateRangeSelector';

import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

// Configure plugins
dayjs.extend(weekday);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);

export default {
    name: 'SnoozeItemAvailabilityModal',
    components: { DateRangeSelector },
    props: {
      venueId: {
        type: Number,
        required: true
      }
    },
    data() {
        return {
            isLoading: false,
            options: [
                { label: '1 Hr', value: '1hr', active: true },
                { label: '2 Hr', value: '2hr', active: true },
                { label: '6 Hr', value: '6hr', active: true },
              { label: 'Tomorrow 8AM', value: 'tomorrow8am', active: true },
              { label: 'Indefinitely', value: 'indefinetly', active: true },
                // { label: 'Next Monday 8AM', value: 'nextmonday8am', active: false }
            ],
            pickerStyles: 'min-w-max rounded-md transition-color font-medium',
            dateSelected: null,
            optionSelected: null,
            calculatedDates: null,
            product: {}
        };
    },
    watch: {
        dateSelected(value) {
            this.pickerStyles =
                'min-w-max rounded-md transition-color font-medium ' +
                (value
                    ? 'bg-indigo-600 text-white hover:text-white border-indigo-600'
                    : 'hover:border-indigo-600 hover:text-indigo-600');
        }
    },
    methods: {
        beforeOpen({ params }) {
            Object.assign(this.$data, this.$options.data.apply(this));
            this.product = params.product;
        },
        selectPicker({ endDate }) {
            this.dateSelected = endDate;
            this.optionSelected = null;
        },
        selectOption(option) {
            this.optionSelected = option;
            this.dateSelected = null;
        },
        async snooze() {
            try {
                this.isLoading = true;

              // Handle indefinite unavailability separately
              if (this.optionSelected === 'indefinetly') {
                await this.$axios.put(`/products/${this.product.id}`, {
                  isAvailable: false
                });

                this.$emit('product-snoozed', { indefinite: true });
                this.$modal.hide('snooze-item-availability-modal');

                this.$notify({
                  group: 'settings',
                  type: 'success',
                  duration: 2000,
                  text: 'Product marked as unavailable'
                });

                this.isLoading = false;
                return;
              }

                const rightNow = dayjs();
                let endDate;

                if (this.dateSelected) {
                    endDate = dayjs(this.dateSelected);
                } else {
                    endDate = dayjs();
                    
                    if (this.optionSelected === 'tomorrow8am') {
                        // Set to tomorrow at 8 AM in venue timezone
                        endDate = endDate.add(1, 'day').set('hour', 8).set('minute', 0).set('second', 0);
                    }
                    else if (this.optionSelected === 'indefinetly') {
                        endDate = endDate.add(10, 'year');
                    }
                    else if (this.optionSelected === 'nextmonday8am') {
                        // Find next Monday
                        // Use weekday plugin to find next Monday (1 = Monday in weekday plugin)
                        const monday = 1;
                        const today = endDate.weekday();
                        const daysUntilMonday = today <= monday ? monday - today : 7 - (today - monday);
                        endDate = endDate.add(daysUntilMonday, 'days').set('hour', 8).set('minute', 0).set('second', 0);
                    } else {
                        // Parse hours from string like '1hr', '2hr', etc.
                        const hours = parseInt(this.optionSelected.replace('hr', ''));
                        endDate = endDate.add(hours, 'hour');
                    }
                }

                // Ensure dates are in ISO format and end is after start
                const start = rightNow.toISOString();
                const end = endDate.toISOString();

                if (!end || !start || !endDate.isAfter(rightNow)) {  // Using isSameOrAfter plugin
                    throw new Error('Invalid date range: end must be after start');
                }

                await this.$axios.post(`/products/snooze`, {
                    id: this.product.id,
                    venueId: this.venueId,
                    start,
                    end
                });

                this.$emit('product-snoozed', { start, end });
                this.$modal.hide('snooze-item-availability-modal');

                this.$notify({
                    group: 'settings',
                    type: 'success',
                    duration: 2000,
                    text: 'Product snoozed!'
                });
            } catch (error) {
                this.$notify({
                    group: 'settings',
                    type: 'error',
                    duration: 5000,
                    text: `Failed to update product availability: ${error.message}`
                });
            } finally {
                this.isLoading = false;
            }
        },
        beforeClose() {
            this.optionSelected = null;
            this.dateSelected = null;
        }
    }
};
</script>

<style>
.snooze-item-availability-modal .vm--modal {
    overflow: visible;
}
</style>
