<template>
    <button
        @click="$emit('clicked')"
        type="button"
        class="focus:outline-none group flex cursor-pointer items-center rounded-md px-2 py-1 text-xs leading-5 font-medium text-gray-600 hover:text-gray-700 hover:bg-gray-200 focus:bg-gray-300 sm:mr-2"
    >
      <svg
          class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500 group-hover:text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
        ></path>
      </svg>
      Export <span v-if="count" class="ml-1">({{count}})</span>
    </button>
</template>

<script>
export default {
    name: 'ExportButton',
    props: {
      count: {
        type: Number,
        default: 0
      }
    }
};
</script>
