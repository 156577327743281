module.exports = class DeliveryProvidersEnum {
    static get ORKESTRO() {
        return 'orkestro';
    }

    static get ORKESTRO_DEMO() {
        return 'orkestro_';
    }

    static get NASH() {
        return 'nash';
    }

    static get SHIPDAY() {
        return 'shipday';
    }

    static get PEDAL_ME() {
        return 'pedalme';
    }

    static get UBER_DIRECT() {
        return 'uberDirect';
    }

    static providerImage(deliveryProvider) {
        switch (deliveryProvider) {
            case this.ORKESTRO:
                return 'https://ucarecdn.com/b2672608-826b-4819-a8e1-bd958df2def8/';
            case this.NASH:
                return 'https://ucarecdn.com/3595a6df-0d5c-4dbe-839f-ece596dfbb83/-/format/auto/-/resize/200x/';
            case this.SHIPDAY:
                return 'https://ucarecdn.com/f787a41a-d881-482e-9522-7aa199c2fc84/-/format/auto/-/resize/200x/';
            case this.PEDAL_ME:
                return 'https://ucarecdn.com/721c860f-411b-47f8-bd18-a6d3b06adffe/-/format/auto/-/resize/200x/';
            case this.UBER_DIRECT:
                return 'https://ucarecdn.com/035e915c-d3a0-41de-905d-16d6202834eb/-/format/auto/-/resize/200x/';
            default:
                return false;
        }
    }

    static providerDashboardLink(deliveryProvider, deliveryProviderId) {
        switch (deliveryProvider) {
            case this.ORKESTRO:
                return `https://dash.orkestro.com//#/jobs/${deliveryProviderId}`;
            case this.ORKESTRO_DEMO:
                return `https://dash.sandbox.orkestro.com//#/jobs/${deliveryProviderId}`;
            case this.NASH:
                return `https://portal.usenash.com/active/${deliveryProviderId}/`;
            case this.SHIPDAY:
                return 'https://dispatch.shipday.com/dashboard';
            case this.PEDAL_ME:
                return `https://pedalme.app/bookings/${deliveryProviderId}/edit`;
            case this.UBER_DIRECT:
                return `https://direct.uber.com/${deliveryProviderId}`;
            default:
                return false;
        }
    }

    static get options() {
        return {
            ORKESTRO: this.ORKESTRO,
            NASH: this.NASH,
            SHIPDAY: this.SHIPDAY,
            PEDAL_ME: this.PEDAL_ME,
            ORKESTRO_DEMO: this.ORKESTRO_DEMO,
            UBER_DIRECT: this.UBER_DIRECT
        };
    }
};
