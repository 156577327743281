export default class FeaturesEnum {
    static get AIRSHIP() {
        return 'AIRSHIP';
    }

    static get ADYEN() {
        return 'ADYEN';
    }

    static get MULTI_MENU() {
        return 'MULTI_MENU';
    }

    static get CATEGORY_AVAILABILITY() {
        return 'CATEGORY_AVAILABILITY';
    }

    static get INTERNAL() {
        return 'INTERNAL';
    }

    static get ITEM_IDS() {
        return 'ITEM_IDS';
    }

    static get INSTAGRAM() {
        return 'INSTAGRAM';
    }

    static get PARAGON() {
        return 'PARAGON';
    }

    static get SPROUT() {
        return 'SPROUT';
    }

    static get CENTEGRA() {
        return 'CENTEGRA';
    }

    static get IKENTOO() {
        return 'IKENTOO';
    }

    static get IKENTOO_BETA() {
        return 'IKENTOO_BETA';
    }

    static get COMTREX() {
        return 'COMTREX';
    }

    static get MAILCHIMP() {
        return 'MAILCHIMP';
    }


    static get PAT() {
        return 'PAT';
    }

    static get TOGGLE() {
        return 'TOGGLE';
    }

    static get SAAS() {
        return 'SAAS';
    }

    static get CATEGORY_IMAGE() {
        return 'CATEGORY_IMAGE';
    }

    static get LOYALTY() {
        return 'LOYALTY';
    }

    static get OPENTABLE() {
        return 'OPENTABLE';
    }

    static get FRESHKDS() {
        return 'FRESHKDS';
    }

    static get ZONES() {
        return 'ZONES';
    }

    static get PLU() {
        return 'PLU';
    }

    static get TISSL() {
        return 'TISSL';
    }

    static get CANCEL_ORDER() {
        return 'CANCEL_ORDER';
    }

    static get AUTO_APPLY() {
        return 'AUTO_APPLY';
    }

    static get ZONAL() {
        return 'ZONAL';
    }

    static get CATERING() {
        return 'CATERING';
    }

    static get INVOICE() {
        return 'INVOICE';
    }

    static get BAKERY(){
        return 'BAKERY';
    }

    static get TRANSLATION_EDITOR() {
        return 'TRANSLATION_EDITOR';
    }

    static get STORE_DESIGNER() {
        return 'STORE_DESIGNER';
    }

    static get MEGA_MENU() {
        return 'MEGA_MENU';
    }

    static get options() {
        return {
            AIRSHIP: this.AIRSHIP,
            ADYEN: this.ADYEN,
            MULTI_MENU: this.MULTI_MENU,
            CATEGORY_AVAILABILITY: this.CATEGORY_AVAILABILITY,
            INTERNAL: this.INTERNAL,
            INSTAGRAM: this.INSTAGRAM,
            PARAGON: this.PARAGON,
            SPROUT: this.SPROUT,
            CENTEGRA: this.CENTEGRA,
            IKENTOO: this.IKENTOO,
            IKENTOO_BETA: this.IKENTOO_BETA,
            COMTREX: this.COMTREX,
            MAILCHIMP: this.MAILCHIMP,
            PAT: this.PAT,
            TOGGLE: this.TOGGLE,
            SAAS: this.SAAS,
            CATEGORY_IMAGE: this.CATEGORY_IMAGE,
            LOYALTY: this.LOYALTY,
            OPENTABLE: this.OPENTABLE,
            FRESHKDS: this.FRESHKDS,
            ZONES: this.ZONES,
            PLU: this.PLU,
            TISSL: this.TISSL,
            CANCEL_ORDER: this.CANCEL_ORDER,
            AUTO_APPLY: this.AUTO_APPLY,
            ZONAL: this.ZONAL,
            CATERING: this.CATERING,
            ITEM_IDS: this.ITEM_IDS,
            INVOICE: this.INVOICE,
            BAKERY: this.BAKERY,
            TRANSLATION_EDITOR: this.TRANSLATION_EDITOR,
            STORE_DESIGNER: this.STORE_DESIGNER,
            MEGA_MENU: this.MEGA_MENU
        };
    }
}
