module.exports = class ItemNotesTypesEnum {
    static get DISABLED() {
        return 'disabled';
    }

    static get OPTIONAL_HIDDEN() {
        return 'optional_hidden';
    }

    static get OPTIONAL_EXPANDED() {
        return 'optional_expanded';
    }

    static get options() {
        return {
            DISABLED: this.DISABLED,
            OPTIONAL_HIDDEN: this.OPTIONAL_HIDDEN,
            OPTIONAL_EXPANDED: this.OPTIONAL_EXPANDED
        };
    }
};
