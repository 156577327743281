import { render, staticRenderFns } from "./DeliveryManagement.vue?vue&type=template&id=662064d8&scoped=true"
import script from "./DeliveryManagement.vue?vue&type=script&lang=js"
export * from "./DeliveryManagement.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/components/order/delivery-management.css?vue&type=style&index=0&id=662064d8&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "662064d8",
  null
  
)

export default component.exports