<template>
    <div
        class="flex min-h-screen w-full flex-col justify-center bg-sk-silver-grey py-12 sm:px-6 lg:px-8"
    >
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <img
                class="mx-auto h-12 w-auto"
                :src="whitelabelLogoUrl"
                alt="Logo"
                v-if="whitelabelLogoUrl"
            />
            <h2
                class="mt-6 text-center text-3xl font-extrabold leading-9 text-gray-900"
            >
                Change your password
            </h2>
            <p class="max-w mt-2 text-center text-sm leading-5 text-gray-600">
                Or
                <router-link
                    :to="{ name: 'login' }"
                    class="focus:outline-none font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:underline"
                >
                    login to your account
                </router-link>
            </p>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
                <form @submit.prevent="changePassword()">
                    <div class="mt-6">
                        <label
                            for="password"
                            class="block text-sm font-medium leading-5 text-gray-700"
                        >
                            Password
                        </label>
                        <div class="mt-1 rounded-md shadow-sm">
                            <input
                                v-model="user.password"
                                id="password"
                                type="password"
                                autocomplete="new-password"
                                required
                                class="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                :class="{
                                    'border-red-300': $v.user.password.$error
                                }"
                            />
                        </div>
                        <div v-if="$v.user.password.$error" class="mt-1">
                            <span class="py-2 text-xs text-red-600">
                                Your password must be at least 8 characters long
                            </span>
                        </div>
                        <div class="mt-1" v-if="emailUnique">
                            <span class="py-2 text-xs text-red-600">{{
                                emailUnique
                            }}</span>
                        </div>
                    </div>

                    <div class="mt-6">
                        <span
                            class="block w-full rounded-md shadow-sm"
                            :class="{ spinner: isLoading }"
                        >
                            <button
                                :disabled="isLoading"
                                class="focus:outline-none flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                            >
                                Change password
                            </button>
                        </span>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { minLength } from 'vuelidate/lib/validators';

export default {
    name: 'ChangePassword',
    metaInfo: {
        title: 'Change Password'
    },
    validations: {
        user: {
            password: {
                minLength: minLength(6)
            }
        }
    },
    computed: {
        whitelabelLogoUrl() {
            return process.env.VUE_APP_WIHTELABEL_LOGO || null;
        }
    },
    data() {
        return {
            isLoading: false,
            emailUnique: null,
            token: null,
            user: {
                password: ''
            }
        };
    },
    methods: {
        changePassword() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }

            this.isLoading = true;
            this.$store
                .dispatch('user/CHANGE_PASSWORD', {
                    password: this.user.password,
                    token: this.$route.query.token,
                    password_confirmation: this.user.password
                })
                .then(() => {
                    this.$router.push({ name: 'login' });
                })
                .catch(e => {
                    if (e.response?.status === 401) {
                        this.emailUnique =
                            'Reset timed out, please request a new password reset';
                    }
                    throw new Error(`API ${e}`);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }
};
</script>

<style scoped>
.spinner:after {
    left: auto;
    right: 1em;
    border-right-color: white;
}
</style>
