<template>
    <div>
        <div class="mb-4 print:hidden" v-if="!embedded">
            <div>
                <BasePageTitle title="Feedback overview" />
            </div>
            <div class="mt-1 flex flex-col sm:mt-2 sm:flex-row sm:flex-wrap">
                <PrintButton />
                <ExportButton @clicked="showExportCsvModal" />
            </div>
        </div>
        <div class="relative mb-4 mt-2 flex items-center print:hidden">
            <DateRangeSelector v-on:date-updated="updateReport" />
        </div>
        <div
            class="mb-4 flex justify-between gap-10 rounded-sm bg-white p-4 shadow"
        >
            <div class="flex w-full max-w-2xl flex-col gap-8">
                <div class="flex flex-col gap-3">
                    <div v-if="average">
                        <span class="text-2xl font-extrabold">
                            Your overall rating: {{ average }}
                        </span>
                        <span class="ml-1">
                            (
                            {{
                                ratingTotalCount > 50 ? '50+' : ratingTotalCount
                            }}
                            {{ ratingTotalCount > 1 ? 'reviews' : 'review' }}
                            )
                        </span>
                    </div>

                    <span> Here's how customers rated you. </span>
                </div>
                <div
                    class="flex w-full flex-col-reverse gap-2"
                >
                    <div
                        v-for="rating in 5"
                        :key="rating"
                        class="flex w-full gap-2"
                    >
                        <div class="flex w-9 items-center justify-between">
                            <span class="text-green-600">{{ rating }}</span>
                            <star-icon size="sm" color="#059669" />
                        </div>
                        <div
                            class="flex h-full w-full items-center justify-center px-1"
                        >
                            <div
                                class="h-2 w-full overflow-hidden rounded-md bg-gray-200 shadow-inner"
                            >
                                <div
                                    v-if="distribution"
                                    class="h-full bg-green-600"
                                    :style="`width: ${getRatingPercentageRatio(
                                        rating
                                    )}%`"
                                />
                            </div>
                        </div>
                        <div class="w-10 text-right">
                            <span v-if="distribution" class="text-gray-500">
                                {{ getRatingPercentageRatio(rating) }}%
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <h3 class="text-right text-3xl font-bold text-gray-700">
                    {{ reviewedPercentage || 0 }}%
                </h3>
                <span class="text-sm text-gray-600"
                    >Of requests received feedback</span
                >
            </div>
        </div>

        <div class="mt-8 bg-white shadow">
            <vue-good-table
                v-if="!isLoading && reportData && reportData.length"
                :isLoading.sync="isLoading"
                class="border-b border-gray-200"
                styleClass="tailwind-table"
                :pagination-options="{
                    enabled: false,
                    perPage: 100
                }"
                :columns="columns"
                :rows="reportData"
            >
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'rating'">
                        <div
                            class="bg-orange-50 flex h-8 w-10 items-center justify-center rounded-lg text-yellow-300"
                        >
                            <svg
                                class="h-4 w-4"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                ></path>
                            </svg>
                            <span
                                class="block text-sm font-medium text-gray-600"
                                >{{
                                    props.formattedRow[props.column.field]
                                }}</span
                            >
                        </div>
                    </span>
                    <span v-else-if="props.column.field === 'venueId'">
                        <div>
                            <span class="block">{{
                                venueById(
                                    props.formattedRow[props.column.field]
                                )?.name
                            }}</span>
                        </div>
                    </span>
                    <span
                        v-else-if="
                            props.column.field === 'email' && props.row.email
                        "
                    >
                        <CustomerDetailsTableItem :email="props.row.email" />
                    </span>
                    <span v-else-if="props.column.field === 'comments'">
                        <span
                            v-if="!props.row.comments"
                            class="px-4 text-gray-300"
                            >No comments</span
                        >
                        <div v-else>
                            <v-dropdown
                                distance="16"
                                :triggers="['click']"
                                popperClass="review-comments-popover"
                            >
                                <div
                                    class="group flex cursor-pointer select-none items-center rounded-md px-4 py-2 hover:bg-gray-100"
                                >
                                    <span class="mr-2 block line-clamp-2">
                                        {{
                                            props.formattedRow[
                                                props.column.field
                                            ]
                                        }}
                                    </span>
                                    <span
                                        class="m-auto block h-4 w-4 text-gray-600 opacity-0 group-hover:opacity-100"
                                    >
                                        <svg
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            focusable="false"
                                            aria-hidden="true"
                                        >
                                            <path d="M5 8l5 5 5-5H5z"></path>
                                        </svg>
                                    </span>
                                </div>

                                <template #popper>
                                    <ReviewCommentsTooltip
                                        :comments="props.row.comments"
                                    />
                                </template>
                            </v-dropdown>
                        </div>
                    </span>
                    <span
                        v-else-if="
                            props.column.field === 'referenceId' &&
                            props.row.referenceId
                        "
                    >
                      <base-button
                        :button-text="props.formattedRow[props.column.field].length >
                                10
                                    ? 'View Bill'
                                    : 'View Order'"
                        @click.native="navigateToReference($event, props)"
                        size="sm"
                      />
                    </span>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StarIcon from '@/components/common/StarIcon';
import PrintButton from '@/components/helpers/PrintButton';
import ExportButton from '@/components/helpers/ExportButton';
import DateRangeSelector from '@/components/widgets/DateRangeSelector';
import ReviewCommentsTooltip from '@/components/widgets/ReviewCommentsTooltip';
import CustomerDetailsTableItem from '@/components/widgets/CustomerDetailsTableItem';

export default {
    name: 'FeedbackOverviewReport',
    props: {
        selectedVenues: {
            type: Array,
            required: true
        },
        embedded: {
            type: Boolean,
            required: false,
            default: false
        },
        venues: {
            type: [Array, Number],
            required: true
        }
    },
    components: {
        StarIcon,
        PrintButton,
        ExportButton,
        DateRangeSelector,
        ReviewCommentsTooltip,
        CustomerDetailsTableItem
    },
    computed: {
        ...mapGetters({
            getDateRange: 'report/getDateRange'
        }),
        ratingTotalCount() {
            return this.distribution
                ? this.distribution.reduce(
                      (sum, item) => sum + parseInt(item.count, 10),
                      0
                  )
                : 0;
        },
        reviewedPercentage() {
            if (this.reviewStats && this.reviewStats.length) {
                const reviewComplete = this.reviewStats.find(x => x.complete);

                const reviewIncomplete = this.reviewStats.find(
                    x => !x.complete
                );

                const reviewCompleteCount = reviewComplete
                    ? reviewComplete.count
                    : 0;
                const reviewIncompleteCount = reviewIncomplete
                    ? reviewIncomplete.count
                    : 0;

                const totalCount =
                    parseInt(reviewCompleteCount) +
                    parseInt(reviewIncompleteCount);

                const percentage =
                    totalCount > 0 ? reviewCompleteCount / totalCount : 0;

                return (percentage * 100).toFixed(1);
            }

            return 0;
        },
        csvData() {
            const csvReportData = this.reportData.map(item => ({
                comments: item.comments,
                rating: item.rating,
                email: item.email,
                date: this.$moment(item.created_at).format('YYYY-MM-DD')
            }));

            return csvReportData;
        }
    },
    watch: {
        selectedVenues() {
            this.fetchReviews();
        }
    },
    data() {
        return {
            isLoading: true,
            average: null,
            distribution: null,
            reviewStats: null,
            dateRange: {
                startDate: null,
                endDate: null
            },
            columns: [
                {
                    label: 'Rating',
                    field: 'rating',
                    type: 'number',
                    sortable: true,
                    firstSortType: 'desc',
                    tdClass:
                        'px-6 py-3 whitespace-nowrap text-sm leading-5 text-gray-500',
                    thClass:
                        'px-6 bg-gray-100 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider'
                },
                {
                    label: 'Comments',
                    field: 'comments',
                    type: 'text',
                    sortable: false,
                    formatFn: this.formatComment,
                    tdClass:
                        'px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-600',
                    thClass:
                        'px-8 bg-gray-100 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider'
                },
                {
                    label: 'Date',
                    field: 'created_at',
                    sortable: true,
                    firstSortType: 'desc',
                    type: 'date',
                    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
                    dateOutputFormat: 'MMM do yy',
                    tdClass:
                        'px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500',
                    thClass:
                        'px-6 bg-gray-100 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider'
                },
                {
                    label: 'Store',
                    field: 'venueId',
                    sortable: false,
                    type: 'text',
                    tdClass:
                        'px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500',
                    thClass:
                        'px-6 bg-gray-100 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider'
                },
                {
                    label: 'Customer',
                    field: 'email',
                    sortable: false,
                    type: 'text',
                    tdClass:
                        'px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-600',
                    thClass:
                        'px-6 bg-gray-100 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider'
                },
                {
                    label: 'Transaction',
                    field: 'referenceId',
                    type: 'number',
                    sortable: false,
                    width: '100px',
                    tdClass:
                        'px-6 py-4 whitespace-nowrap text-right text-sm leading-5 text-gray-500',
                    thClass:
                        'px-6 text-right bg-gray-100 py-3 bg-gray-50 text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider'
                }
            ],
            reportData: null,
            csvTitle: 'Reviews',
            fields: [
                { label: 'Comments', value: 'comments' },
                { label: 'Rating', value: 'rating' },
                { label: 'Email', value: 'email' },
                { label: 'Date', value: 'created_at' }
            ]
        };
    },
    created() {
        this.dateRange = this.getDateRange || this.dateRange;

        this.fetchReviews();
    },
    methods: {
        updateReport(val) {
            this.dateRange = val;
            this.fetchReviews();
        },
        async fetchReviews() {
            this.isLoading = true;

            const params = {
                venueIds: this.selectedVenues,
                from: this.dateRange.startDate,
                to: this.dateRange.endDate
            };

            try {
                const { data } = await this.$axios.get('/reports/feedback', {
                    params
                });

                this.reportData = data.reviews;
                this.average = parseFloat(data.average) || null;
                this.distribution = data.distribution;
                this.reviewStats = data.reviewStats;
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        print() {
            window.print();
        },
        formatCurrency: function (value) {
            return this.$options.filters.currency(parseInt(value) / 100);
        },
        formatComment(value) {
            if (!value) {
                return '';
            }

            return value.length > 50 ? value.substring(0, 50) + '...' : value;
        },
        venueById(id) {
            return this.venues.find(venue => venue.id === id);
        },
        showExportCsvModal() {
            this.$modal.show('export-csv-modal', {
                header: this.csvTitle,
                csvData: this.csvData,
                fields: this.fields
            });
        },
        navigateToReference($event, { row }) {
            const blank = $event.metaKey || $event.ctrlKey;
            if (row.referenceId.length > 10) {
                this.navigateToBill(blank, row.referenceId, row.venueId);
                return;
            }
            this.navigateToOrder(blank, row.referenceId);
        },
        navigateToOrder(blank, id) {
            if (!blank) {
                this.$router.push({
                    name: 'orderDetail',
                    params: {
                        id
                    }
                });
                return;
            }
            const routeData = this.$router.resolve({
                name: 'orderDetail',
                params: {
                    id
                }
            });
            window.open(routeData.href, '_blank');
        },
        navigateToBill(blank, bill, venueId) {
            if (!blank) {
                this.$router.push({
                    name: 'tables',
                    query: { bill, venueId }
                });
                return;
            }
            const routeData = this.$router.resolve({
                name: 'tables',
                query: { bill, venueId }
            });
            window.open(routeData.href, '_blank');
        },
        getRatingPercentageRatio(rating) {
            const item = this.distribution && this.distribution.find(item => item.rating === rating);

            if (item) {
                return (
                    (parseInt(item.count, 10) / this.ratingTotalCount) *
                    100
                ).toFixed();
            }

            return '0';
        }
    }
};
</script>
