<template>
    <div class="flex w-full flex-col gap-2 overflow-hidden pb-2">
        <div
            class="flex w-max cursor-pointer items-end justify-start gap-1 rounded py-0.5 hover:bg-gray-100"
            @click="isOpened = !isOpened"
        >
            <label
                class="inline cursor-pointer select-none text-xs font-medium leading-5 tracking-wide text-gray-700 line-clamp-1"
            >
                {{ title }}
            </label>
            <svg
                viewBox="0 0 16 16"
                version="1.1"
                class="mb-0.5 h-4 w-4 transition duration-300 ease-in-out"
                :class="{ 'rotate-180 transform': !isOpened }"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
            >
                <polygon points="8,5 13,10 3,10" />
            </svg>
        </div>

        <div v-if="isOpened" class="flex w-full flex-col gap-2 px-1 pt-2">
            <div class="flex items-end justify-between">
                <label
                    class="text-xs font-medium leading-4 tracking-wide text-gray-700"
                >
                    {{ subtitle }}
                </label>
                <span class="text-xs">{{ computedValue }}</span>
            </div>
            <input
                v-model.number="computedValue"
                class="accent-black h-1.5 w-full cursor-pointer appearance-none rounded-md bg-gray-200"
                type="range"
                :step="step"
                :max="max"
                :min="min"
                @input="rangeChanged"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [Number, null],
            required: false,
            default: null
        },
        keyName: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: false,
            default: ''
        },
        min: {
            type: Number,
            required: false,
            default: 1
        },
        max: {
            type: Number,
            required: false,
            default: 5
        },
        step: {
            type: Number,
            required: false,
            default: 1
        }
    },
    data() {
        return {
            isOpened: false
        };
    },
    computed: {
        computedValue: {
            get() {
                return this.value || this.min;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        rangeChanged(event) {
            const value = event.target?.value && parseInt(event.target?.value);

            this.$emit('change', this.keyName, value);
        }
    }
};
</script>
