import store from '@/store';

export function componentLoader(fn) {
    return () => {
        // Loading begins now
        store.commit('ui/LOADING_BAR_TASK_BEGIN');

        // Call when loading is done
        const done = () => store.commit('ui/LOADING_BAR_TASK_END');

        const promise = fn();
        promise.then(done, done);
        return promise;
    };
}

export function createRouteLoader(fn) {
    return {
        component: componentLoader(fn)
    };
}
