<template>
    <div class="relative">
        <mobile-device-warning
            v-if="isMobile && !loadDesigner"
            @continue="loadDesigner = true"
        />

        <div v-else class="fixed inset-0 flex flex-col">
            <designer-header v-model="selectedDevice">
              <template #left>
                <button
                    v-if="isMobile"
                    class="focus:outline-none -ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 transition duration-150 ease-in-out hover:text-gray-900 focus:bg-gray-200"
                    @click="$modal.show('store-designer-options-modal')"
                >
                    <svg
                        class="h-6 w-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M4 6h16M4 12h16M4 18h16"
                        />
                    </svg>
                </button>
                <button
                    class="mr-2 hidden items-center justify-center rounded-md bg-gray-200 px-3 py-2 text-xs font-semibold text-gray-700 hover:bg-gray-300 sm:flex"
                    @click="shareTheme"
                    v-tooltip="'Click to open a theme preview link you can share with others'"
                >
                    <svg class="mr-2 h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                    </svg>
                    Share
                </button>
                <div
                    class="mr-4 hidden flex-grow-0 items-center justify-center gap-1 rounded-md bg-gray-200 px-1 py-0.5 sm:flex"
                >
                  <label
                      class="inline-flex items-center gap-2 rounded p-1.5"
                      v-tooltip="'Light Mode'"
                      :class="{
                        'cursor-default bg-white shadow-sm': !isDarkMode,
                        'cursor-pointer hover:bg-gray-100': isDarkMode
                    }"
                  >
                    <input
                        v-model="isDarkMode"
                        :value="false"
                        type="radio"
                        class="hidden"
                    />
                    <base-sun-icon class="h-4 w-4" />
                  </label>
                  <label
                      class="inline-flex items-center gap-2 rounded p-1.5"
                      v-tooltip="'Dark Mode'"
                      :class="{
                        'cursor-default bg-white shadow-sm': isDarkMode,
                        'cursor-pointer hover:bg-gray-100': !isDarkMode
                    }"
                  >
                    <input
                        v-model="isDarkMode"
                        :value="true"
                        type="radio"
                        class="hidden"
                    />
                    <base-moon-icon class="h-4 w-4" />
                  </label>
                </div>
              </template>
              <template #right>
                <base-button
                  :disabled="!hasChanged || isLoading"
                  button-text="Publish changes"
                  button-style="action"
                  size="sm"
                  @clicked="saveVenue"
                  v-tooltip="'Save and publish changes to your store'"
              >
                  <template #left-icon>
                    <base-doc-icon class="mr-2 h-4 w-4" />
                  </template>
                  <template
                    v-if="isFeatureAvailable(FeaturesEnum.INTERNAL)"
                    #right-icon
                  >
                    <button @click.stop="showMultipleVenueSelectModal">
                      <base-chevron-icon
                        class="rounded hover:bg-gray-600"
                      />
                    </button>
                  </template>
                </base-button>
              </template>
            </designer-header>

            <div class="flex h-full overflow-hidden">
                <component
                    :is="isMobile ? 'modal' : 'div'"
                    v-bind="elementProps"
                >
                    <div
                        v-if="isMobile"
                        class="flex w-full justify-between p-2"
                    >
                        <base-page-title title="Store Designer" />
                        <button
                            @click="$modal.hide('store-designer-options-modal')"
                        >
                            <base-close-icon />
                        </button>
                    </div>

                    <category-accordion
                        v-model="isOpen.images"
                        title="Images"
                    />
                    <div
                        v-show="isOpen.images"
                        class="flex w-full flex-col items-center border-b border-gray-200 justify-center gap-2 px-2 py-4"
                    >
                        <image-category
                            title="FAVICON"
                            key-name="faviconImage"
                            :ctx-name="`${ctxName}favImage`"
                            :is-edit-mode="isEdit.faviconImage"
                            :image-url="formData.faviconImage || ''"
                            :has-init-value="!!initValues.faviconImage"
                            @success="
                                (key, event) => {
                                    onSuccess(key, event);
                                    triggerAnimation();
                                }
                            "
                            @edit="isEdit.faviconImage = true"
                            @cancel-edit="onCancelEdit"
                            @restore-image="restoreInitValue"
                            @image-removed="onRemoved"
                        />
                        <image-category
                            title="LOGO"
                            key-name="logo"
                            :ctx-name="`${ctxName}logo`"
                            :is-edit-mode="isEdit.logo"
                            :image-url="formData.logo || ''"
                            :has-init-value="!!initValues.logo"
                            @success="onSuccess"
                            @edit="isEdit.logo = true"
                            @cancel-edit="onCancelEdit"
                            @restore-image="restoreInitValue"
                            @image-removed="onRemoved"
                        />
                        <image-category
                            title="LOGO NAV"
                            key-name="logoNav"
                            :ctx-name="`${ctxName}logoNav`"
                            :is-edit-mode="isEdit.logoNav"
                            :image-url="formData.logoNav || ''"
                            :has-init-value="!!initValues.logoNav"
                            @success="onSuccess"
                            @edit="isEdit.logoNav = true"
                            @cancel-edit="onCancelEdit"
                            @restore-image="restoreInitValue"
                            @image-removed="onRemoved"
                        />
                        <image-category
                            title="HEADER IMAGE"
                            key-name="headerImage"
                            :ctx-name="`${ctxName}headerImage`"
                            :is-edit-mode="isEdit.headerImage"
                            :image-url="formData.headerImage || ''"
                            :has-init-value="!!initValues.headerImage"
                            @success="onSuccess"
                            @edit="isEdit.headerImage = true"
                            @cancel-edit="onCancelEdit"
                            @restore-image="restoreInitValue"
                            @image-removed="onRemoved"
                        />
                    </div>

                    <category-accordion v-model="isOpen.menu" title="Menu" />
                    <div
                        v-show="isOpen.menu"
                        class="flex w-full flex-col items-center justify-center gap-4 border-b border-gray-200 px-2 py-4"
                    >
                        <toggle-category
                            v-model="formData.showCurrencySymbol"
                            key-name="showCurrencySymbol"
                            title="Show a currency symbol"
                            @change="onSettingChange"
                        />
                        <toggle-category
                            v-model="formData.largeImages"
                            key-name="largeImages"
                            title="Large images"
                            @change="onSettingChange"
                        />
                    </div>

                    <category-accordion
                        v-model="isOpen.menuItems"
                        title="Menu items"
                    />
                    <div
                        v-show="isOpen.menuItems"
                        class="flex w-full flex-col items-center justify-center gap-3 border-b border-gray-200 px-2 py-4"
                    >
                        <radio-button-category
                            v-model="formData.showInventoryQuantity"
                            :options="stockOptions"
                            tooltip="Choose how inventory is displayed on item modals"
                            key-name="showInventoryQuantity"
                            title="Low stock display"
                            is-init-opened
                            is-column
                            @change="onSettingChange"
                        />
                        <radio-button-category
                            v-model="formData.modalImageStyle"
                            :options="modalImageStyleOptions"
                            tooltip="Select the size of images displayed in your modal"
                            key-name="modalImageStyle"
                            title="Modal image style"
                            is-init-opened
                            is-column
                            @change="onSettingChange"
                        />
                        <toggle-category
                            v-model="formData.ageGated"
                            key-name="ageGated"
                            title="Age gated"
                            @change="onSettingChange"
                        />
                    </div>

                    <category-accordion
                        v-model="isOpen.typography"
                        title="Typography"
                    />
                    <div
                        v-show="isOpen.typography"
                        class="flex w-full flex-col items-center justify-center gap-4 border-b border-gray-200 px-2 py-4"
                    >
                        <font-category
                            v-model="formData.bodyTextTransform"
                            title="BODY"
                            key-name="body"
                            :font="formData.bodyFont || {}"
                            :ctx-name="`${ctxName}bodyFont`"
                            :is-edit-mode="isEdit.bodyFont"
                            :has-init-value="!!initValues.bodyFont"
                            @success="onSuccess"
                            @edit="isEdit.bodyFont = true"
                            @cancel-edit="onCancelEdit"
                            @restore-font="restoreInitValue"
                            @font-removed="onRemoved"
                            @transform="onSettingChange"
                        />
                        <font-category
                            v-model="formData.headingTextTransform"
                            title="HEADING"
                            key-name="heading"
                            :font="formData.headingFont || {}"
                            :ctx-name="`${ctxName}headingFont`"
                            :is-edit-mode="isEdit.headingFont"
                            :has-init-value="!!initValues.headingFont"
                            @success="onSuccess"
                            @edit="isEdit.headingFont = true"
                            @cancel-edit="onCancelEdit"
                            @restore-font="restoreInitValue"
                            @font-removed="onRemoved"
                            @transform="onSettingChange"
                        />
                        <font-category
                            title="ACCENT"
                            key-name="accent"
                            :font="formData.accentFont || {}"
                            :ctx-name="`${ctxName}accentFont`"
                            :is-edit-mode="isEdit.accentFont"
                            :has-init-value="!!initValues.accentFont"
                            is-transform-disabled
                            @success="onSuccess"
                            @edit="isEdit.accentFont = true"
                            @cancel-edit="onCancelEdit"
                            @restore-font="restoreInitValue"
                            @font-removed="onRemoved"
                            @transform="onSettingChange"
                        />
                    </div>

                    <category-accordion
                        v-model="isOpen.description"
                        title="Description"
                    />
                    <div
                        v-show="isOpen.description"
                        class="flex w-full flex-col items-center border-b border-gray-200 justify-center gap-4 py-2 px-2"
                    >
                        <div class="w-full">
                            <label
                                for="about"
                                class="pl-1 text-xs font-medium leading-5 tracking-wide text-gray-700"
                            >
                                About
                            </label>
                            <textarea
                                id="about"
                                v-model.trim="formData.description"
                                rows="6"
                                class="form-input mt-1 block w-full rounded-md shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                @input="
                                    onSettingChange(
                                        'description',
                                        formData.description
                                    )
                                "
                            />
                        </div>
                    </div>

                    <category-accordion
                        v-model="isOpen.branding"
                        title="Colours"
                    />
                    <div
                        v-show="isOpen.branding"
                        class="flex w-full flex-col items-center justify-center gap-4 border-b border-gray-200 px-2 py-4"
                    >
                        <div
                            v-if="isFeatureAvailable(FeaturesEnum.INTERNAL)"
                            class="flex w-full items-start justify-between pb-2"
                        >
                            <div class="flex flex-col items-start gap-2">
                                <button
                                    class="text-right text-xs text-blue-700 hover:text-blue-500"
                                    @click="copyBranding"
                                >
                                    Copy Branding
                                </button>
                                <base-checkbox
                                    v-model="modifiedOnly"
                                    id="modifiedOnly"
                                    tooltip="If checked, only the modified settings will be copied."
                                >
                                    <template #label>
                                        <span class="text-xs text-gray-600">
                                            Modified only
                                        </span>
                                    </template>
                                </base-checkbox>
                            </div>
                            <button
                                class="text-right text-xs text-blue-700 hover:text-blue-500"
                                @click="pasteBranding"
                            >
                                Paste Branding
                            </button>
                        </div>

                      <div class="border-t border-gray-200 w-full"></div>

                      <div class="flex w-full flex-col gap-1">
                            <span
                                class="mt-1 mb-2 text-sm font-semibold leading-4 tracking-wide"
                            >
                                General
                            </span>
                          <colour-category
                              v-for="item in generalBranding"
                              :key="item.keyName"
                              :value="formData[item.keyName]"
                              :key-name="item.keyName"
                              :title="item.title"
                              :default-colour="item.default"
                              :swatches="swatches"
                              @change="trackChanges"
                              @input="
                                      value =>
                                        onSettingChange(item.keyName, value)
                                  "
                          />
                      </div>
                      <div class="border-t border-gray-200 w-full"></div>

                      <div class="flex w-full flex-col gap-1">
                            <span
                                class="mt-1 mb-2 text-sm font-semibold leading-4 tracking-wide"
                            >
                                Text
                            </span>
                            <colour-category
                                v-for="item in textBranding"
                                :key="item.keyName"
                                :value="formData[item.keyName]"
                                :key-name="item.keyName"
                                :title="item.title"
                                :default-colour="item.default"
                                :swatches="swatches"
                                @change="trackChanges"
                                @input="
                                    value =>
                                        onSettingChange(item.keyName, value)
                                "
                            />
                        </div>
                        <div class="border-t border-gray-200 w-full"></div>
                        <div class="flex w-full flex-col gap-1">
                            <span
                                class="mt-1 mb-2 text-sm font-semibold leading-4 tracking-wide"
                            >
                                Buttons
                            </span>
                            <colour-category
                                v-for="item in buttonBranding"
                                :key="item.keyName"
                                :value="formData[item.keyName]"
                                :key-name="item.keyName"
                                :title="item.title"
                                :default-colour="item.default ?? undefined"
                                :swatches="swatches"
                                @change="trackChanges"
                                @input="
                                    value =>
                                        onSettingChange(item.keyName, value)
                                "
                            />
                            <border-category
                                :value="formData.borderButtonPrimary"
                                key-name="borderButtonPrimary"
                                title="Button border"
                                :swatches="swatches"
                                @change="trackChanges"
                                @input="
                                    value =>
                                        onSettingChange(
                                            'borderButtonPrimary',
                                            value
                                        )
                                "
                            />
                            <radio-button-category
                                v-model="formData.borderRadiusButton"
                                :options="borderRadiusOptions"
                                key-name="borderRadiusButton"
                                title="Border radius"
                                container-classes="rounded-md overflow-hidden p-1 w-full h-10 bg-gray-100"
                                is-input-hidden
                                @change="onSettingChange"
                            >
                                <template v-slot:default="{ option }">
                                    <div
                                        v-tooltip="option.text"
                                        class="flex h-full grow basis-1/4 cursor-pointer items-center justify-center rounded-md *:border-gray-400 hover:bg-gray-50 peer-checked:border peer-checked:border-gray-300 peer-checked:bg-white peer-checked:*:border-gray-700"
                                    >
                                        <div
                                            class="relative size-5 overflow-hidden border-inherit"
                                        >
                                            <div
                                                class="absolute inset-0 size-10 border-2 border-inherit"
                                                :class="[option.class]"
                                            />
                                        </div>
                                    </div>
                                </template>
                            </radio-button-category>
                            <radio-button-category
                                v-model="formData.buttonFont"
                                :options="buttonFontOptions"
                                default-value="body"
                                key-name="buttonFont"
                                title="Button font"
                                container-classes="rounded-md py-1 px-2 h-10 bg-gray-100"
                                is-input-hidden
                                @change="onSettingChange"
                            >
                                <template v-slot:default="{ option }">
                                    <div
                                        v-tooltip="
                                            option.isDisabled &&
                                            'Set in Typography'
                                        "
                                        class="flex h-full w-full cursor-pointer items-center justify-center truncate rounded-md border border-transparent px-1 *:border-gray-400 hover:bg-gray-50 peer-checked:border-gray-300 peer-checked:bg-white peer-checked:*:border-gray-700 peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                    >
                                        <font-preview
                                            class="text-lg"
                                            :text="option.text"
                                            :font="option.font"
                                        />
                                    </div>
                                </template>
                            </radio-button-category>
                        </div>
                      <div class="border-t border-gray-200 w-full"></div>

                        <div class="flex w-full flex-col gap-1">
                            <span
                                class="mt-1 mb-2 text-sm font-semibold leading-4 tracking-wide"
                            >
                                Menu Items
                            </span>
                            <colour-category
                                v-for="item in menuItemBranding"
                                :key="item.keyName"
                                :value="formData[item.keyName]"
                                :key-name="item.keyName"
                                :title="item.title"
                                :default-colour="item.default"
                                :swatches="swatches"
                                @change="trackChanges"
                                @input="
                                    value =>
                                        onSettingChange(item.keyName, value)
                                "
                            />
                            <box-shadow-category
                                :value="
                                    formData.boxShadowModifierCard ||
                                    '0px 1px 0px 1px #00000014'
                                "
                                key-name="boxShadowModifierCard"
                                title="Box shadow modifier card"
                                class="mt-2"
                                :swatches="swatches"
                                @change="trackChanges"
                                @input="
                                    value =>
                                        onSettingChange(
                                            'boxShadowModifierCard',
                                            value
                                        )
                                "
                            />
                            <border-category
                                :value="formData.borderBottomMenuItem"
                                key-name="borderBottomMenuItem"
                                title="Border bottom menu item"
                                class="mt-1"
                                :swatches="swatches"
                                @change="trackChanges"
                                @input="
                                    value =>
                                        onSettingChange(
                                            'borderBottomMenuItem',
                                            value
                                        )
                                "
                            />
                            <border-category
                                :value="formData.borderMenuItem"
                                key-name="borderMenuItem"
                                title="Border menu item"
                                class="mt-2"
                                :swatches="swatches"
                                @change="trackChanges"
                                @input="
                                    value =>
                                        onSettingChange('borderMenuItem', value)
                                "
                            />
                            <border-category
                                :value="formData.borderSelectedItem"
                                key-name="borderSelectedItem"
                                title="Border selected item colour"
                                class="mt-2"
                                :swatches="swatches"
                                @change="trackChanges"
                                @input="
                                    value =>
                                        onSettingChange(
                                            'borderSelectedItem',
                                            value
                                        )
                                "
                            />
                            <box-shadow-category
                                :value="formData.boxShadowMenuItem"
                                key-name="boxShadowMenuItem"
                                title="Box shadow menu item"
                                class="mt-2"
                                :swatches="swatches"
                                @change="trackChanges"
                                @input="
                                    value =>
                                        onSettingChange(
                                            'boxShadowMenuItem',
                                            value
                                        )
                                "
                            />
                            <radio-button-category
                                :options="menuItemsPerRowOptions"
                                :value="formData.menuItemWidth"
                                key-name="menuItemWidth"
                                title="Menu items per row"
                                class="mt-2"
                                @change="onSettingChange"
                                @input="
                                    value =>
                                        trackChanges('menuItemWidth', value)
                                "
                            />
                            <range-category
                                :value="formData.lineClampMenuItem"
                                key-name="lineClampMenuItem"
                                title="Menu item name line clamp"
                                subtitle="Lines"
                                class="mt-2"
                                :max="4"
                                @change="onSettingChange"
                                @input="
                                    value =>
                                        trackChanges('lineClampMenuItem', value)
                                "
                            />
                        </div>
                    </div>

                    <template v-if="hasTableSection">
                        <category-accordion
                            v-model="isOpen.tables"
                            title="Tables"
                        />
                        <div
                            v-show="isOpen.tables"
                            class="flex w-full flex-col items-center justify-center gap-4 border-b border-gray-200 px-2 pb-4 pt-2"
                        >
                            <button
                                class="ml-auto text-right text-xs text-blue-700 hover:text-blue-500"
                                @click="toggleSelectionModal"
                            >
                                Toggle Modal
                            </button>
                            <toggle-category
                                v-model="formData.showTableSelectionModal"
                                keyName="showTableSelectionModal"
                                title="Always show the table selection screen"
                                @change="onSettingChange"
                            />
                            <div class="w-full">
                                <label
                                    for="orderInstructions"
                                    class="pl-1 text-xs font-medium leading-5 tracking-wide text-gray-700"
                                >
                                    Order instructions
                                </label>
                                <textarea
                                    id="orderInstructions"
                                    v-model.trim="formData.orderInstructions"
                                    rows="6"
                                    class="form-input mt-1 block w-full rounded-md shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                    @input="
                                        onSettingChange(
                                            'orderInstructions',
                                            formData.orderInstructions
                                        )
                                    "
                                />
                            </div>
                            <image-category
                                title="Splash screen image"
                                key-name="tableScreenImage"
                                :ctx-name="`${ctxName}tableScreenImage`"
                                :is-edit-mode="isEdit.tableScreenImage"
                                :image-url="formData.tableScreenImage || ''"
                                :has-init-value="!!initValues.tableScreenImage"
                                @success="onSuccess"
                                @edit="isEdit.tableScreenImage = true"
                                @cancel-edit="onCancelEdit"
                                @restore-image="restoreInitValue"
                                @image-removed="onRemoved"
                            />
                        </div>
                    </template>

                    <category-accordion
                        v-model="isOpen.menuNotice"
                        title="Menu notice"
                    />
                    <div
                        v-show="isOpen.menuNotice"
                        class="flex w-full flex-col items-center justify-center gap-4 border-b border-gray-200 px-2 pb-4 pt-2"
                    >
                        <text-category
                            v-model="formData.menuNotice"
                            key-name="menuNotice"
                            placeholder="Leave a notice…"
                            label="Display a message at the bottom of your menu.
                            Typically used to display a calorie or allergen
                            notice, or your service charge policy."
                            @focus="scrollTo"
                            @change="onSettingChange"
                        />
                    </div>

                    <category-accordion
                        v-model="isOpen.busyMessage"
                        title="Busy Message"
                        @input="toggleBusyMessageModal"
                    />
                    <div
                        v-show="isOpen.busyMessage"
                        class="flex w-full flex-col items-center justify-center gap-4 border-b border-gray-200 px-2 pb-4 pt-2"
                    >
                        <label
                            for="busyMessage"
                            class="text-xs text-gray-700"
                        >
                          Customise the message displayed when your store is closed or snoozed.
                        </label>
                        <textarea
                            id="busyMessage"
                            v-model.trim="formData.busyMessage"
                            rows="6"
                            class="form-input mt-1 block w-full rounded-md shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            :class="{
                                'border-red-300': $v.formData.busyMessage.$error
                            }"
                            @focus="scrollTo('busyMessage')"
                            @input="
                                onSettingChange(
                                    'busyMessage',
                                    formData.busyMessage
                                )
                            "
                        />
                    </div>
                    <template v-if="isPickupOrDelivery">
                        <category-accordion
                            v-model="isOpen.orderStatus"
                            title="Order status"
                        />
                        <div v-show="isOpen.orderStatus" class="w-full">
                            <label
                                for="about"
                                class="pl-2 text-xs font-medium leading-5 tracking-wide text-gray-700"
                            >
                                Change the map style
                            </label>
                            <map-style-item
                                changeable
                                name="Base"
                                class="m-2 rounded-md border border-gray-300"
                                :coordinates="coordinates"
                                :map-style="
                                    formData.mapStyleUrl ||
                                    'mapbox://styles/benlarceysk/cm60rjvt4005c01s74u4k7f0g'
                                "
                                @show-modal="openSelectMapStyleModal"
                            />
                        </div>
                    </template>

                    <div
                        v-if="false"
                        class="py-3 px-3">
                      <span class="text-gray-800 font-heading font-semibold">Apps</span>
                    </div>

<!--                  @TODO store designer apps -->
                    <category-accordion
                        v-if="false"
                        v-model="isOpen.leat"
                        title="Leat"
                    >
                      <template #icon>
                        <img src="https://ucarecdn.com/2c21b704-29d3-4dc1-935f-608e9010c8c8/-/format/auto/" class="h-4 w-4 mr-2" alt="Leat Logo">
                      </template>
                    </category-accordion>

                    <div v-if="isMobile" class="mt-10 flex flex-grow flex-col justify-end px-2">
                        <view-selector
                            v-model="selectedView"
                            :views="views"
                            class="flex items-center justify-center gap-2"
                        />

                    </div>
                </component>
                <div
                    class="flex flex-1 items-start justify-center overflow-hidden p-3 px-4"
                    :class="{ 'bg-sk-black': isDarkMode }"
                >
                    <div
                        class="flex h-full w-full flex-col overflow-hidden rounded-md shadow-md"
                        :class="{ 'rounded-2xl': selectedDevice === 'mobile' }"
                        :style="deviceWidth"
                    >
                        <div
                            class="flex h-12 w-full items-center justify-center border-b border-gray-200 bg-gray-100 px-6 sm:justify-start"
                            :class="{
                                'bg-gray-100 border-b border-gray-200': selectedDevice !== 'mobile',
                                'border-none': selectedDevice === 'mobile',
                            }"
                            :style="selectedDevice !== 'mobile' ? {} : { backgroundColor: formData.primaryColour }"
                        >
                            <div
                                class="hidden w-1/3 flex-none flex-shrink gap-3 sm:flex"
                            >
                                <div
                                    v-for="index in 3"
                                    :key="`button-${index}`"
                                    :class="{
                                        'bg-red-500': index === 1,
                                        'bg-yellow-500': index === 2,
                                        'bg-green-500': index === 3
                                    }"
                                    class="h-2.5 w-2.5 rounded-full"
                                />
                            </div>
                            <div
                                class="h-full w-3/4 min-w-0 self-center justify-self-center p-2 sm:w-1/3"
                            >
                                <div
                                    class="flex h-full w-full items-center justify-center gap-1.5 overflow-hidden rounded-md bg-gray-50 px-3 shadow"
                                >
                                    <img
                                        v-if="formData.faviconImage"
                                        :src="`${formData.faviconImage}-/resize/48x48/`"
                                        class="rounded-sm w-5 h-5"
                                        :class="{ animate: isAnimated }"
                                        alt="FavIcon"
                                        @animationend="isAnimated = false"
                                    />
                                    <span class="truncate text-sm">
                                        {{ source.text }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <iframe
                            ref="iframe"
                            :src="source.value"
                            scale="0.75"
                            class="flex h-full w-full"
                        />
                    </div>
                </div>
                <div
                    v-if="!isMobile"
                    class="h-full w-64 overflow-auto bg-white"
                >
                    <div class="flex w-full flex-col justify-center p-2">
                        <div class="flex justify-evenly px-1 py-3">
                            <view-selector
                                v-model="selectedView"
                                :views="views"
                                class="flex flex-col items-start justify-center gap-2"
                            />
                        </div>
                      <div class="flex w-full px-1 mt-4 justify-between">
                        <base-button
                            size="sm"
                            button-text="Undo"
                            :disabled="!canUndo"
                            @clicked="undo"
                        >
                          <template #right-icon>
                            <base-curved-arrow-icon
                                class="ml-1 h-3 w-3"
                            />
                          </template>
                        </base-button>

                        <base-button
                            size="sm"
                            button-text="Redo"
                            :disabled="!canRedo"
                            @clicked="redo"
                        >
                          <template #left-icon>
                            <base-curved-arrow-icon
                                class="mr-1 h-3 w-3"
                                style="transform: scaleX(-1)"
                            />
                          </template>
                        </base-button>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <multiple-venue-select-modal
            :is-loading="isLoading"
            @save-venues="saveMultiple"
        />
        <select-map-style-modal
            :current-url="
                formData.mapStyleUrl ||
                'mapbox://styles/benlarceysk/cm60rjvt4005c01s74u4k7f0g'
            "
            :coordinates="coordinates"
            @selected="onMapStyleSelected"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { isMobile } from 'mobile-device-detect';
import { required } from 'vuelidate/lib/validators';
import { FeaturesEnum, FulfillmentMethodsEnum } from '@/enums';
import FontPreview from '@/components/storeDesigner/FontPreview';
import MapStyleItem from '@/components/storeDesigner/MapStyleItem';
import ViewSelector from '@/components/storeDesigner/ViewSelector';
import TextCategory from '@/components/storeDesigner/TextCategory';
import FontCategory from '@/components/storeDesigner/FontCategory';
import RangeCategory from '@/components/storeDesigner/RangeCategory';
import ImageCategory from '@/components/storeDesigner/ImageCategory';
import DesignerHeader from '@/components/storeDesigner/DesignerHeader';
import BorderCategory from '@/components/storeDesigner/BorderCategory';
import ColourCategory from '@/components/storeDesigner/ColourCategory';
import ToggleCategory from '@/components/storeDesigner/ToggleCategory';
import SelectMapStyleModal from '@/components/modals/SelectMapStyleModal';
import BoxShadowCategory from '@/components/storeDesigner/BoxShadowCategory';
import CategoryAccordion from '@/components/storeDesigner/CategoryAccordion';
import MobileDeviceWarning from '@/components/storeDesigner/MobileDeviceWarning';
import RadioButtonCategory from '@/components/storeDesigner/RadioButtonCategory';
import MultipleVenueSelectModal from '@/components/modals/MultipleVenueSelectModal';

export default {
    name: 'StoreDesigner',
    metaInfo: {
        title: 'Store Designer'
    },
    props: {
        venueId: {
            type: Number,
            required: true
        },
        venue: {
            type: Object,
            required: true
        }
    },
    components: {
        FontPreview,
        MapStyleItem,
        ViewSelector,
        TextCategory,
        FontCategory,
        ImageCategory,
        RangeCategory,
        BorderCategory,
        ColourCategory,
        ToggleCategory,
        DesignerHeader,
        BoxShadowCategory,
        CategoryAccordion,
        MobileDeviceWarning,
        RadioButtonCategory,
        SelectMapStyleModal,
        MultipleVenueSelectModal
    },
    data() {
        const defaultDevice =
            this.venue.acceptsPat || this.venue.acceptsInStore || isMobile
                ? 'mobile'
                : 'desktop';
        const isPickupOrDelivery =
            this.venue.acceptsDelivery || this.venue.acceptsPickup;

        const availableViews = [{ label: 'Menu', value: 'menu' }];

        const randomOrderId = Math.random().toString(36).substring(4);

        if (isPickupOrDelivery && !this.venue.acceptsInStore) {
            availableViews.push({
                label: 'Order status',
                value: `orders/${randomOrderId}/status`,
                query: `&fulfillmentMethod=${FulfillmentMethodsEnum.PICKUP}`
            });
        }

        if (this.venue.acceptsInStore) {
            availableViews.push({
                label: 'Thank you',
                value: `orders/${randomOrderId}/status`,
                query: `&fulfillmentMethod=${FulfillmentMethodsEnum.IN_STORE}`
            });
        }

        const defaultValues = {
            buttonFont: null,
            colorBgPage: null,
            menuItemWidth: null,
            colorBgButton: null,
            colorBgPrimary: null,
            colorTextPrice: null,
            colorTextMuted: null,
            borderMenuItem: null,
            colorTextAccent: null,
            colorTextButton: null,
            modalImageStyle: null,
            colorBgSecondary: null,
            // colorBgBrandPage: null,
            colorTextHeading: null,
            colorTextPrimary: null,
            bodyTextTransform: null,
            colorTextItemBody: null,
            lineClampMenuItem: null,
            // colorBgPageDarker: null,
            borderRadiusButton: null,
            borderSelectedItem: null,
            colorTextSecondary: null,
            // colorBgBrandBanner: null,
            colorBgModifierCard: null,
            colorTextSubheading: null,
            borderButtonPrimary: null,
            colorHighlightBorder: null,
            borderBottomMenuItem: null,
            headingTextTransform: null,
            boxShadowModifierCard: null,
            colorSecondaryActions: null,
            colorBgModalOverlay: null,
            colorBgSecondaryTransparent: null,
            colorBgSelectedModifierCard: null,

            showTableSelectionModal: false,
            showInventoryQuantity: 'hide',
            showCurrencySymbol: false,
            orderInstructions: null,
            tableScreenImage: null,
            primaryColour: null,
            faviconImage: null,
            largeImages: false,
            mapStyleUrl: null,
            busyMessage: null,
            headerImage: null,
            headingFont: null,
            accentFont: null,
            menuNotice: null,
            ageGated: false,
            bodyFont: null,
            logoNav: null,
            logo: null
        };


        const generalBranding = [
          {
            keyName: 'primaryColour',
            title: 'Mobile browser and desktop nav',
            default: '#292a31'
          },
          {
            keyName: 'colorBgPage',
            title: 'Page background',
            default: '#fafafa'
          },
          {
            keyName: 'colorBgPrimary',
            title: 'Primary backgrounds',
            default: '#ffffff'
          },
          {
            keyName: 'colorBgSecondary',
            title: 'Secondary backgrounds',
            default: '#ffffff'
          }
        ]

        const buttonBranding = [
            {
                keyName: 'colorTextButton',
                title: 'Button text',
                default: '#ffffff'
            },
            {
                keyName: 'colorBgButton',
                title: 'Button background',
                default: '#292a31'
            }
        ];

        const menuItemBranding = [
            // {
            //     keyName: 'colorBgBrandPage',
            //     title: 'Background brand page colour',
            //     default: '#fafafa'
            // },
            // {
            //     keyName: 'colorBgPageDarker',
            //     title: 'Background brand page darker colour',
            //     default: '#f4f5f5'
            // },
            // {
            //     keyName: 'colorBgBrandBanner',
            //     title: 'Background brand banner colour',
            //     default: '#ffffff'
            // },
            {
                keyName: 'colorHighlightBorder',
                title: 'Highlight border colour',
                default: '#172d48'
            },
            {
                keyName: 'colorSecondaryActions',
                title: 'Secondary actions colour',
                default: '#292a31'
            },
            {
              keyName: 'colorBgModalOverlay',
              title: 'Overlay background',
              default: 'rgba(0, 0, 0, 0.5)'
            },
            {
              keyName: 'colorBgSecondaryTransparent',
              title: 'Secondary background transparent',
              default: 'rgba(255,255,255,0.75)'
            },
            {
                keyName: 'colorBgModifierCard',
                title: 'Modifier card background',
                default: '#e9e9e925'
            },
            {
                keyName: 'colorBgSelectedModifierCard',
                title: 'Selected modifier card background',
                default: '#e9e9e96c'
            }
        ];

        const textBranding = [
            {
              keyName: 'colorTextHeading',
              title: 'Category and item names',
              default: '#161e2e'
            },
            {
              keyName: 'colorTextSubheading',
              title: 'Category navigation and modifier group names',
              default: '#252f3f'
            },
            {
                keyName: 'colorTextPrice',
                title: 'Prices',
                default: '#4b5563'
            },
            {
                keyName: 'colorTextMuted',
                title: 'Item descriptions (preview)',
                default: '#6b7280'
            },
            {
              keyName: 'colorTextItemBody',
              title: 'Item descriptions (full)',
              default: '#2d3748'
            },
            {
                keyName: 'colorTextAccent',
                title: 'Accent colour',
                default: '#233876'
            },
            {
                keyName: 'colorTextPrimary',
                title: 'Primary colour',
                default: '#2d3748'
            },
            {
                keyName: 'colorTextSecondary',
                title: 'Secondary colour',
                default: '#4a5568'
            }
        ];

        return {
            isLoading: false,
            isDarkMode: false,
            skipRestore: false,
            isPickupOrDelivery,
            ctxName: this.venue.slug,
            selectedDevice: defaultDevice,
            initValues: { ...defaultValues },
            formData: { ...defaultValues },
            otherBrandingProps: null,
            loadDesigner: !isMobile,
            views: availableViews,
            selectedView: { label: 'Menu', value: 'menu' },
            selectedVenueIds: [],
            modifiedOnly: true,
            isAnimated: false,
            menuItemBranding,
            buttonBranding,
            textBranding,
            generalBranding,
            FeaturesEnum,
            menuItemsPerRowOptions: [
                { text: 'One item', value: '100%' },
                { text: 'Two items', value: '49%' }
            ],
            stockOptions: [
                { text: 'Hide', value: 'hide' },
                { text: 'Low only (10 or less)', value: 'low_only' },
                { text: 'Always display inventory', value: 'exact' }
            ],
            modalImageStyleOptions: [
                { text: 'Medium', value: 'medium' },
                { text: 'Large', value: 'large' }
            ],
            borderRadiusOptions: [
                { text: 'None', value: 'none', class: 'rounded-none' },
                { text: 'Small', value: 'sm', class: 'rounded-sm' },
                { text: 'Large', value: 'lg', class: 'rounded-lg' },
                { text: 'Full', value: 'full', class: 'rounded-full' }
            ],

            isOpen: {
                leat: false,
                menu: false,
                images: false,
                tables: false,
                branding: false,
                menuItems: false,
                typography: false,
                menuNotice: false,
                description: false,
                orderStatus: false,
                busyMessage: false
            },
            isEdit: {
                tableScreenImage: false,
                faviconImage: false,
                headerImage: false,
                headingFont: false,
                accentFont: false,
                bodyFont: false,
                logoNav: false,
                logo: false
            },
            history: {
                undoStack: [],
                redoStack: []
            },
            swatches: [
                '#000000',
                '#222831',
                '#ffffff',
                '#393e46',
                '#583d72',
                '#9f5f80',
                '#99c1de',
                '#84a59d',
                '#ffd369',
                '#f5cac3',
                '#ff8e71'
            ]
        };
    },
    validations: {
        formData: {
            busyMessage: { required }
        }
    },
    computed: {
        ...mapGetters({
            availableVenues: 'venues/getVenues',
            isFeatureAvailable: 'user/isFeatureAvailable'
        }),
        hasChanged() {
            return (
                JSON.stringify(this.formData) !==
                JSON.stringify(this.initValues)
            );
        },
        publicHostname() {
            return process.env.VUE_APP_WHITELABEL_PUBLIC_HOSTNAME;
        },
        source() {
            const isLocal = this.publicHostname === 'localhost:8081';
            const { slug } = this.venue;

            const base = `http${isLocal ? '' : 's'}://${this.publicHostname}`;
            const query = this.selectedView.query || '';

            return {
                base,
                text: `${base}/${slug}/${this.selectedView.value}`,
                value: `${base}/${slug}/${this.selectedView.value}?storeDesigner=true${query}`
            };
        },
        deviceWidth() {
            switch (this.selectedDevice) {
                case 'desktop':
                    return 'width: 1500px; min-width: 773px';
                case 'tablet':
                    return 'width: 772px; min-width: 640px';
                case 'mobile':
                    return 'width: 390px; max-height: 980px';
                default:
                    return 'width: 100%';
            }
        },
        hasTableSection() {
            return this.venue.acceptsInStore;
        },
        isMobile() {
            return isMobile;
        },
        brandingColourKeys() {
            return [
                'buttonFont',
                'menuItemWidth',
                'borderMenuItem',
                'boxShadowMenuItem',
                'lineClampMenuItem',
                'borderRadiusButton',
                'borderSelectedItem',
                'borderButtonPrimary',
                'borderBottomMenuItem',
                'boxShadowModifierCard',
                ...[
                    ...this.textBranding,
                    ...this.buttonBranding,
                    ...this.generalBranding,
                    ...this.menuItemBranding
                ].map(({ keyName }) => keyName)
            ];
        },
        canUndo() {
            return this.history.undoStack.length > 0;
        },
        canRedo() {
            return this.history.redoStack.length > 0;
        },
        elementProps() {
            const modalProps = {
                class: 'store-designer-options-modal',
                name: 'store-designer-options-modal',
                classes: 'pb-4 flex flex-col mt-40',
                scrollable: true,
                adaptive: false,
                height: '100%',
                width: '100%'
            };
            const sideMenuProps = {
                style: 'scrollbar-width: thin; scrollbar-gutter: stable',
                class: 'h-full w-80 overflow-auto bg-white pb-10 pl-1 shadow-md'
            };

            return isMobile ? modalProps : sideMenuProps;
        },
        coordinates() {
            return this.venue.address?.lng && this.venue.address?.lat
                ? [this.venue.address.lat, this.venue.address.lng]
                : undefined;
        },
        buttonFontOptions() {
            const baseValues = [
                { text: 'body', value: 'body' },
                { text: 'heading', value: 'heading' },
                { text: 'accent', value: 'accent' }
            ];

            return baseValues.map(item => {
                return {
                    ...item,
                    font: this.formData[`${item.value}Font`] ?? null,
                    isDisabled: !this.formData[`${item.value}Font`]
                };
            });
        }
    },
    watch: {
        selectedView(view, oldView) {
            if (view.modal === 'product') {
                const message = {
                    type: 'openModal',
                    value: 'item-modal',
                    item: view.item
                };

                const time = oldView.value === 'menu' ? 0 : 500;

                setTimeout(() => {
                    this.postMessage(message);
                }, time);
            }

            if (oldView.modal === 'product') {
                const message = { type: 'closeModal', value: 'item-modal' };

                this.postMessage(message);
            }
        },
        selectedDevice() {
            this.setDevice();
        },
    },
    created() {
        const {
            mapStyle: { mapStyleUrl },
            fonts: { body: bodyFont, heading: headingFont, accent: accentFont },
            branding: {
                buttonFont,
                colorBgPage,
                menuItemWidth,
                colorBgButton,
                colorBgPrimary,
                colorTextPrice,
                colorTextMuted,
                borderMenuItem,
                colorTextAccent,
                colorTextButton,
                colorBgSecondary,
                // colorBgBrandPage,
                colorTextHeading,
                colorTextPrimary,
                bodyTextTransform,
                boxShadowMenuItem,
                colorTextItemBody,
                lineClampMenuItem,
                // colorBgPageDarker,
                colorBgModalOverlay,
                colorBgSecondaryTransparent,
                borderRadiusButton,
                borderSelectedItem,
                colorTextSecondary,
                // colorBgBrandBanner,
                colorBgModifierCard,
                colorTextSubheading,
                borderButtonPrimary,
                colorHighlightBorder,
                borderBottomMenuItem,
                headingTextTransform,
                boxShadowModifierCard,
                colorSecondaryActions,
                colorBgSelectedModifierCard,
                ...otherBrandingProps
            },
            showTableSelectionModal,
            showInventoryQuantity,
            showCurrencySymbol,
            orderInstructions,
            tableScreenImage,
            modalImageStyle,
            primaryColour,
            faviconImage,
            description,
            headerImage,
            largeImages,
            busyMessage,
            menuNotice,
            ageGated,
            logoNav,
            logo
        } = {
            ...this.venue,
            branding: {
                ...this.venue.branding,
                ...this.getBranding(
                    [
                        ...this.brandingColourKeys,
                        'headingTextTransform',
                        'bodyTextTransform'
                    ],
                    this.venue.branding
                )
            },
            fonts: {
                body: this.venue.fonts?.body ?? null,
                accent: this.venue.fonts?.accent ?? null,
                heading: this.venue.fonts?.heading ?? null
            },
            mapStyle: {
                mapStyleUrl: this.venue.mapStyle?.mapStyleUrl ?? null
            }
        };

        this.otherBrandingProps = otherBrandingProps;
        this.initValues = {
            showTableSelectionModal,
            showInventoryQuantity,
            showCurrencySymbol,
            orderInstructions,
            tableScreenImage,
            modalImageStyle,
            primaryColour,
            faviconImage,
            description,
            headerImage,
            largeImages,
            mapStyleUrl,
            headingFont,
            busyMessage,
            accentFont,
            menuNotice,
            bodyFont,
            ageGated,
            logoNav,
            logo,

            buttonFont,
            colorBgPage,
            menuItemWidth,
            colorBgButton,
            colorBgPrimary,
            colorTextPrice,
            colorTextMuted,
            borderMenuItem,
            colorTextAccent,
            colorTextButton,
            colorBgSecondary,
            // colorBgBrandPage,
            colorTextHeading,
            colorTextPrimary,
            bodyTextTransform,
            boxShadowMenuItem,
            colorTextItemBody,
            lineClampMenuItem,
            // colorBgPageDarker,
            borderRadiusButton,
            borderSelectedItem,
            colorTextSecondary,
            // colorBgBrandBanner,
            colorBgModifierCard,
            colorTextSubheading,
            borderButtonPrimary,
            colorHighlightBorder,
            borderBottomMenuItem,
            headingTextTransform,
            boxShadowModifierCard,
            colorSecondaryActions,
            colorBgModalOverlay,
            colorBgSecondaryTransparent,
            colorBgSelectedModifierCard
        };

        this.formData = { ...this.initValues };

        this.getMenuCategories();
        this.getTables();
        this.enableMessageReceive();
    },
    mounted() {
        this.updateColoursPreset();
    },

    beforeDestroy() {
        this.disableMessageReceive();
    },

    beforeRouteLeave(to, from, next) {
        if (!this.hasChanged) {
            return next();
        }

        const answer = window.confirm(
            "Unpublished changes will be lost. Do you want to continue?"
        );

        return answer ? next() : next(false);
    },
    methods: {
        ...mapActions({
            fetchVenues: 'venues/fetch',
            updateVenueSettings: 'venues/update'
        }),
        enableMessageReceive() {
            window.addEventListener('message', this.onMessageReceived);
        },
        disableMessageReceive() {
            window.removeEventListener('message', this.onMessageReceived);
        },
        onMessageReceived(event) {
            if (event.origin !== this.source.base) {
                return;
            }

            if (event.data?.type === 'syncSettings') {
                this.setDevice();
                this.syncSettings(this.initValues, false);
            }
        },
        onMapStyleSelected(mapStyleUrl) {
            this.formData.mapStyleUrl = mapStyleUrl;

            this.onSettingChange('mapStyleUrl', mapStyleUrl);
        },
        async getMenuCategories() {
            if (!this.venue.menu[0] || !this.venue.menu[0].id) {
                return;
            }

            const products = [];
            const menuId = this.venue.menu[0].id;
            this.isLoading = true;

            try {
                const {
                    data: { categories }
                } = await this.$axios.get(
                    `/categories/${menuId}?showAll=false`
                );

                for (const category of categories) {
                    if (!category.products?.length) {
                        continue;
                    }

                    products.push(...category.products);
                }

                if (products.length) {
                    this.views.push({
                        label: `Product (${products[0].name})`,
                        value: 'menu',
                        modal: 'product',
                        item: products[0]
                    });
                }
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        async getTables() {
            if (!this.venue.acceptsPat) {
                return;
            }

            try {
                const { data } = await this.$axios.get(
                    `/venues/${this.venueId}/tables?showAll=false`
                );

                if (data?.length && data[0].tables?.length) {
                    const [{ tables }] = data;

                    this.views.push({
                        label: `LandingPage (${tables[0].name})`,
                        value: `table/${tables[0].id}`
                    });
                }
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        async copyBranding() {
            const brandingColours = {
                primaryColour: this.formData.primaryColour,
                ...this.getBranding(
                    this.brandingColourKeys,
                    this.formData,
                    this.modifiedOnly
                )
            };

            try {
                await navigator.clipboard.writeText(
                    JSON.stringify(brandingColours, null, 2)
                );
                this.$notify({
                    group: 'settings',
                    title: 'Copied to clipboard'
                });
            } catch (err) {
                throw new Error('Failed to copy:', err);
            }
        },
        async pasteBranding() {
            try {
                const brandingColours = await navigator.clipboard.readText();

                if (!brandingColours) {
                    this.$notify({
                        group: 'settings',
                        title: 'No branding colours found'
                    });

                    return;
                }

                const parsedColours = JSON.parse(brandingColours);

                this.history.undoStack.push(this.cloneData());
                this.history.redoStack = [];

                this.formData = { ...this.formData, ...parsedColours };

                for (const key in parsedColours) {
                    this.onSettingChange(key, parsedColours[key]);
                }
            } catch (err) {
                throw new Error('Failed to paste:', err);
            }
        },
        async saveVenue(multiple = false) {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.isLoading = true;

            try {
                const brandingPayload = this.getBranding(
                    [
                        ...this.brandingColourKeys,
                        'headingTextTransform',
                        'bodyTextTransform'
                    ],
                    this.formData,
                    true
                );

                const payload = {
                    id: this.venueId,
                    logo: this.formData.logo,
                    logoNav: this.formData.logoNav,
                    ageGated: this.formData.ageGated,
                    menuNotice: this.formData.menuNotice,
                    busyMessage: this.formData.busyMessage,
                    description: this.formData.description,
                    largeImages: this.formData.largeImages,
                    headerImage: this.formData.headerImage,
                    faviconImage: this.formData.faviconImage,
                    modalImageStyle: this.formData.modalImageStyle,
                    primaryColour: this.formData.primaryColour || '',
                    tableScreenImage: this.formData.tableScreenImage,
                    orderInstructions: this.formData.orderInstructions,
                    showCurrencySymbol: this.formData.showCurrencySymbol,
                    showInventoryQuantity: this.formData.showInventoryQuantity,
                    showTableSelectionModal: this.formData
                        .showTableSelectionModal,
                    fonts: {
                        ...(this.formData.accentFont
                            ? { accent: this.formData.accentFont }
                            : null),
                        ...(this.formData.headingFont
                            ? { heading: this.formData.headingFont }
                            : null),
                        ...(this.formData.bodyFont
                            ? { body: this.formData.bodyFont }
                            : null)
                    },
                    branding: {
                        ...this.otherBrandingProps,
                        ...brandingPayload
                    },
                    mapStyle: {
                        mapStyleUrl: this.formData.mapStyleUrl,
                        ...(this.venue.mapStyle?.mapMarkerStoreImg
                            ? {
                                  mapMarkerStoreImg: this.venue.mapStyle
                                      .mapMarkerStoreImg
                              }
                            : null)
                    }
                };

                if (multiple && this.selectedVenueIds.length) {
                    for (const id of this.selectedVenueIds) {
                        await this.updateVenueSettings({ ...payload, id });
                    }

                    this.$modal.hide('multiple-venue-select-modal');
                } else {
                    await this.updateVenueSettings(payload);
                }

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });

                this.initValues = { ...this.formData };

                Object.keys(this.isEdit).forEach(key => {
                    this.isEdit[key] = false;
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },

        setDevice() {
            const message = { type: 'device', value: this.selectedDevice };

            this.postMessage(message);
        },

        scrollTo(destination) {
            const message = {
                type: 'scroll',
                value: destination
            };

            this.postMessage(message);
        },

        toggleBusyMessageModal(value) {
            this.onSettingChange('enabled', !value);
        },

        toggleSelectionModal() {
            const message = {
                type: 'toggleModal',
                value: 'table-selection-modal'
            };

            this.postMessage(message);
        },

        onSettingChange(key, value) {
            const message = { key, value, type: 'settingChange' };

            this.postMessage(message);
        },

        postMessage(message) {
            this.$refs.iframe.contentWindow.postMessage(
                message,
                this.source.base
            );
        },

        onSuccess(key, event) {
            const fontKeys = ['bodyFont', 'headingFont', 'accentFont'];

            if (fontKeys.includes(key)) {
                const parts =
                    event.name.indexOf('.') > 0
                        ? event.name.toLowerCase().split('.')
                        : [];

                this.formData[key] = {
                    extension: parts.pop() || 'undefined',
                    url: event.cdnUrl,
                    name:
                        parts
                            .shift()
                            .trim()
                            .replace(/[^\w\s-]/g, '')
                            .replace(/[\s_-]+/g, '-')
                            .replace(/^-+|-+$/g, '') || 'undefined'
                };
            } else {
                this.formData[key] = event.cdnUrl;
            }

            this.onSettingChange(key, this.formData[key]);
        },

        onCancelEdit(key) {
            this.isEdit[key] = false;

            this.restoreInitValue(key);
        },

        onRemoved(key) {
            if (this.formData[key] !== null) {
                this.formData[key] = null;
                this.triggerSkipRestore();

                this.isEdit[key] = true;

                this.onSettingChange(key, this.formData[key]);
                
                if (this.formData.buttonFont === key.replace('Font', '')) {
                    this.formData.buttonFont = null;
                }
            }
        },

        restoreInitValue(key) {
            if (
                !this.skipRestore &&
                this.formData[key] !== this.initValues[key]
            ) {
                this.formData[key] = this.initValues[key];

                this.onSettingChange(key, this.formData[key]);
            }
        },

        triggerSkipRestore() {
            this.skipRestore = true;

            setTimeout(() => {
                this.skipRestore = false;
            }, 500);
        },

        getBranding(keys, source, modifiedOnly = false) {
            const branding = {
                ...Object.fromEntries(
                    keys.map(key => [key, source?.[key] ?? null])
                )
            };

            if (modifiedOnly) {
                for (const key in branding) {
                    if (branding[key] === null) {
                        delete branding[key];
                    }
                }
            }

            return branding;
        },

        triggerAnimation() {
            this.isAnimated = true;
        },

        async showMultipleVenueSelectModal() {
            if (!this.hasChanged || this.isLoading) {
                return;
            }

            if (!this.availableVenues.length) {
                try {
                    this.isLoading = true;

                    await this.fetchVenues();
                } catch (error) {
                    throw new Error(`API ${error}`);
                } finally {
                    this.isLoading = false;
                }
            }

            const venues =
                this.availableVenues.length &&
                this.availableVenues.some(({ id }) => this.venueId === id)
                    ? this.availableVenues
                    : [this.venue, ...this.availableVenues];

            this.$modal.show('multiple-venue-select-modal', {
                selectedIds: this.selectedVenueIds,
                venueId: this.venueId,
                venues
            });
        },

        saveMultiple(ids) {
            this.selectedVenueIds = ids;

            this.saveVenue(true);
        },

        trackChanges(key, value) {
            this.history.undoStack.push(this.cloneData());

            this.history.redoStack = [];

            this.formData[key] = value;

            this.updateColoursPreset(true, key, value);
        },
        undo() {
            if (this.canUndo) {
                this.history.redoStack.push(this.cloneData());

                const snapShot = this.history.undoStack.pop();

                this.syncSettings(snapShot);
            }
        },
        redo() {
            if (this.canRedo) {
                this.history.undoStack.push(this.cloneData());

                const snapShot = this.history.redoStack.pop();

                this.syncSettings(snapShot);
            }
        },
        syncSettings(snapShot = {}, setSnapShot = true) {
            const changedKeys = Object.keys(snapShot).filter(
                key =>
                    snapShot[key] !== this.formData[key] &&
                    (this.brandingColourKeys.includes(key) || !setSnapShot)
            );

            if (changedKeys.length) {
                for (const key of changedKeys) {
                    this.onSettingChange(
                        key,
                        setSnapShot ? snapShot[key] : this.formData[key]
                    );
                }
            }

            if (setSnapShot) {
                this.formData = snapShot;
            }
        },
        cloneData() {
            return JSON.parse(JSON.stringify(this.formData));
        },
        openSelectMapStyleModal() {
            this.$modal.show('select-map-style-modal');
        },

        async shareTheme() {
            try {
                const brandingPayload = this.getBranding(
                    [
                        ...this.brandingColourKeys,
                        'headingTextTransform',
                        'bodyTextTransform'
                    ],
                    this.formData,
                    true
                );

                const themePayload = {
                    venueId: this.venueId,
                    theme: {
                        logo: this.formData.logo,
                        logoNav: this.formData.logoNav,
                        ageGated: this.formData.ageGated,
                        menuNotice: this.formData.menuNotice,
                        description: this.formData.description,
                        largeImages: this.formData.largeImages,
                        headerImage: this.formData.headerImage,
                        faviconImage: this.formData.faviconImage,
                        modalImageStyle: this.formData.modalImageStyle,
                        primaryColour: this.formData.primaryColour || '',
                        showCurrencySymbol: this.formData.showCurrencySymbol,
                        showInventoryQuantity: this.formData.showInventoryQuantity,
                        fonts: {
                            ...(this.formData.headingFont ? { heading: this.formData.headingFont } : null),
                            ...(this.formData.bodyFont ? { body: this.formData.bodyFont } : null)
                        },
                        ...Object.entries(brandingPayload).reduce((acc, [key, value]) => ({
                            ...acc,
                            [key]: value
                        }), {}),
                        ...Object.entries(this.otherBrandingProps).reduce((acc, [key, value]) => ({
                            ...acc,
                            [key]: value
                        }), {}),
                        mapStyle: {
                            mapStyleUrl: this.formData.mapStyleUrl,
                            ...(this.venue.mapStyle?.mapMarkerStoreImg
                                ? {
                                      mapMarkerStoreImg: this.venue.mapStyle
                                          .mapMarkerStoreImg
                                  }
                                : null)
                        }
                    }
                };

                const { data } = await this.$axios.post('/theme-preview', themePayload);
                
                const previewUrl = `${this.source.text}?theme=${data.id}`;
                window.open(previewUrl, '_blank');

                this.$notify({
                    group: 'settings',
                    title: 'Theme preview link created',
                    text: 'Opening preview in new tab'
                });
            } catch (error) {
                this.$notify({
                    group: 'settings',
                    type: 'error',
                    title: 'Failed to share theme preview'
                });
            }
        },
        updateColoursPreset(update = false, key, value) {
            const complexValueKeys = [
                'borderMenuItem',
                'boxShadowMenuItem',
                'borderSelectedItem',
                'borderButtonPrimary',
                'borderBottomMenuItem',
                'boxShadowModifierCard'
            ];
            const colourKeys = [
                ...this.textBranding,
                ...this.buttonBranding,
                ...this.generalBranding,
                ...this.menuItemBranding
            ].map(({ keyName }) => keyName);

            let colorValues = [];

            if (
                (update &&
                    ![...colourKeys, ...complexValueKeys].includes(key)) ||
                this.swatches.includes(value)
            ) {
                return;
            }

            if (update && complexValueKeys.includes(key)) {
                const colour = value
                    .split(' ')
                    .find(v => v.startsWith('#') || v.startsWith('rgb'));

                if (this.swatches.includes(colour)) {
                    return;
                }

                colorValues = [colour];
            }

            if (update && !complexValueKeys.includes(key)) {
                colorValues = [value];
            }

            if (!update) {
                colorValues = [
                    ...colourKeys
                        .map(key => this.formData[key])
                        .filter(value => !!value),
                    ...complexValueKeys
                        .map(key => this.formData[key])
                        .filter(v => !!v)
                        .flatMap(v => v.split(' '))
                        .filter(v => v.startsWith('#') || v.startsWith('rgb'))
                ];
            }

            this.swatches = [...colorValues, ...this.swatches].slice(0, 11);
        }
    }
};
</script>

<style>
.store-designer-options-modal .vm--modal {
    overflow: auto;
}
</style>

<style scoped>
.animate {
    animation: fly-in 1s 0.3s 1;
}

@keyframes fly-in {
    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    20% {
        transform: scale3d(1.25, 1.25, 1.25);
    }
    40% {
        transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
        opacity: 1;
        transform: scale3d(1.1, 1.1, 1.1);
    }
    80% {
        transform: scale3d(0.97, 0.97, 0.97);
    }
    100% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}
</style>
