<template>
  <div class="group col-span-6 rounded-sm cursor-pointer">
    <router-link :is="integration.routeName ? 'router-link' : 'span'" :to="{ name: routerLink }" class="hover:bg-gray-50 py-3 border-b transition-all duration-300 ease-in-out flex justify-between items-center px-5 ">
      <div class="flex">
        <div class="mr-2 sm:mr-4 flex-shrink-0">
          <img :src="integration.icon" alt="" class="mt-1 w-6 sm:w-8 h-6 sm:h-8 rounded">
        </div>
        <div class="">
          <h4
              class="group text-sm text-gray-700 font-semibold flex items-center group-hover:text-indigo-700"
          >{{ integration.title }}
            <span v-if="integration.isSaas" class="opacity-0 group-hover:opacity-100 transition-opacity ease-in-out duration-300">
              <svg
                  v-tooltip="`To use the ${integration.title} integration you'll need to be subscribed to storekit plus`"
                  class="ml-1 h-4 w-4"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
              >
                <path
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm.53 5.47a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l1.72-1.72v5.69a.75.75 0 001.5 0v-5.69l1.72 1.72a.75.75 0 101.06-1.06l-3-3z"
                ></path>
              </svg>
            </span>
            <span v-if="integration.internal" class="ml-2">
              [Internal]
            </span>
          </h4>
          <p class="text-sm text-gray-600 group-hover:text-gray-700"
          >
            {{ integration.description }}
          </p>
        </div>
      </div>
      <div v-show="isIntegrationConnected(integration.value)">
        <span class="flex items-center text-sm font-medium">
          <span class="mr-2 text-green-600">
              <base-tick-icon />
          </span>
          <span class="hidden sm:inline-block">
            Connected
          </span>
        </span>
      </div>
    </router-link>
  </div>
</template>

<script>
import {FeaturesEnum} from "@/enums";
import {mapGetters} from "vuex";

export default {
  name: 'IntegrationListItem',
  props: {
    integration: {
      type: Object,
      required: true,
      FeaturesEnum
    },
    venue: {
      type: Object,
      required: true
    },
    userParagonIntegrations: {
      type: Object,
      required: false
    }
  },
  computed: {
    ...mapGetters({
      isFeatureAvailable: 'user/isFeatureAvailable'
    }),
    routerLink() {
      if (this.integration.isSaas && !this.isFeatureAvailable(FeaturesEnum.SAAS) && !this.isFeatureAvailable(FeaturesEnum.INTERNAL)) {
        return 'upgrade';
      }
      else {
        return this.integration.routeName;
      }
    }
  },
  methods: {
    isIntegrationConnected(integation) {
      switch (integation) {
        case 'mailchimp':
          return this.userParagonIntegrations?.mailchimp?.enabled;
        case 'opentable':
          return this.venue.openTableWebhookEnabled;
        case 'deliverect':
          return this.venue.deliverectId;
        case 'nash':
          return this.venue.nashId;
        case 'comtrex':
          return this.venue.posProvider === 'comtrex';
        case 'centegra':
          return this.venue.posProvider === 'centegra';
        case 'tissl':
          return this.venue.posProvider === 'tissl';
        case 'meta':
          return this.venue.facebookPixelId;
        case 'google-tag-manager':
          return this.venue.googleTagManagerId;
        case 'toggle':
          return !!this.venue?.integrations?.toggle;
        case 'fresh-kds':
          return !!this.venue?.integrations?.freshkds;
        case 'orkestro':
          return this.venue.orkestroEnabled;
        case 'klaviyo':
          return this.venue.klaviyoApiKey;
        case 'tipjar':
          return this.venue.tipjarShortcode;
        case 'airship':
          return !!this.venue?.integrations?.airship;
        case 'piggy':
          return this.venue?.piggySettings?.enabled;
        case 'stampede':
          return this.userParagonIntegrations?.custom.stampede?.enabled;
        case 'webhooks':
          return false;
        case 'lightspeed':
          return this.venue.posProvider === 'ikentoo';
        case 'atreemo':
          return this.venue?.integrations?.atreemo?.siteId;
        case 'shipday':
          return this.venue?.integrations?.shipday?.enabled;
        case 'giftpro':
          return this.venue?.integrations?.giftpro;
        case 'pedalme':
          return this.venue?.integrations?.pedalme?.enabled;
        case 'como':
          return !!this.venue?.integrations?.como?.enabled;
        case 'stream':
          return this.venue?.posProvider === 'stream';
        default:
          return false;
      }
    }
  }
};
</script>
