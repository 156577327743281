export default class FulfillmentMethodsEnum {
    static get IN_STORE() {
        return 'InStore';
    }

    static get DELIVERY() {
        return 'Delivery';
    }

    static get PICKUP() {
        return 'Pickup';
    }

    static get CATERING_DELIVERY() {
        return 'CateringDelivery';
    }

    static get CATERING_PICKUP() {
        return 'CateringPickup';
    }

    static get options() {
        return {
            IN_STORE: this.IN_STORE,
            DELIVERY: this.DELIVERY,
            PICKUP: this.PICKUP,
            CATERING_DELIVERY: this.CATERING_DELIVERY,
            CATERING_PICKUP: this.CATERING_PICKUP
        };
    }

    static isDeliveryMethod(fulfillmentMethod) {
        return fulfillmentMethod === this.DELIVERY || fulfillmentMethod === this.CATERING_DELIVERY;
    }

    static isPickupMethod(fulfillmentMethod) {
        return fulfillmentMethod === this.PICKUP || fulfillmentMethod === this.CATERING_PICKUP;
    }

    static isCateringMethod(fulfillmentMethod) {
        return fulfillmentMethod === this.CATERING_PICKUP || fulfillmentMethod === this.CATERING_DELIVERY;
    }

    static nonCateringOptions() {
        return [
            { name: this.IN_STORE, label: 'Dine In', icon: 'InstoreIcon' },
            { name: this.DELIVERY, label: 'Delivery', icon: 'DeliveryIcon' },
            { name: this.PICKUP, label: 'Pickup', icon: 'PickupIcon' }
        ];
    }
}
