import { render, staticRenderFns } from "./EditMenu.vue?vue&type=template&id=75fa8ba7&scoped=true"
import script from "./EditMenu.vue?vue&type=script&lang=js"
export * from "./EditMenu.vue?vue&type=script&lang=js"
import style0 from "./EditMenu.vue?vue&type=style&index=0&id=75fa8ba7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75fa8ba7",
  null
  
)

export default component.exports