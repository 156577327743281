var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
        ' cursor-not-allowed opacity-50': _vm.disabled
    }},[_vm._t("label",function(){return [(_vm.label)?_c('label',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltip),expression:"tooltip"}],staticClass:"mb-1 flex items-center text-sm font-medium leading-5 text-gray-700",attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.labelPostfix)?_c('span',{staticClass:"font-normal text-gray-500"},[_vm._v(" "+_vm._s(_vm.labelPostfix)+" ")]):_vm._e(),(_vm.tooltip)?_c('base-question-icon',{staticClass:"ml-1"}):_vm._e()],1):_vm._e()]}),_vm._t("caption",function(){return [(_vm.caption)?_c('p',{staticClass:"mb-1 text-sm leading-5 text-gray-500"},[_vm._v(" "+_vm._s(_vm.caption)+" ")]):_vm._e()]}),_c('div',{staticClass:"sm:col-span-2",class:{ 'flex rounded-md shadow-sm': _vm.prefix }},[(_vm.prefix)?_c('span',{staticClass:"inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm"},[_vm._v(" "+_vm._s(_vm.prefix)+" ")]):_vm._e(),_c('div',{staticClass:"w-full",class:[
                {
                    'relative mt-1 rounded-md shadow-sm': _vm.maxLength
                },
                _vm.wrapperClass
            ]},[_c('textarea',_vm._b({staticClass:"focus:outline-none form-input block w-full border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-gray-800 focus:shadow-outline-black focus:ring-gray-800 focus:ring-offset-1 sm:text-sm sm:leading-5",class:{
                    'border-red-300': _vm.errors,
                    'rounded-none rounded-r-md': _vm.prefix,
                    'rounded-md': !_vm.prefix,
                    'pr-14': _vm.maxLength,
                    'pointer-events-none': _vm.disabled
                },domProps:{"value":_vm.value},on:{"change":function($event){return _vm.$emit('input', $event.target.value)}}},'textarea',{
                    id: _vm.id,
                    rows: _vm.rows,
                    placeholder: _vm.placeholder,
                    disabled: _vm.disabled,
                    maxLength: _vm.maxLength,
                    min: _vm.min,
                    max: _vm.max
                },false)),_vm._v(" "),(_vm.maxLength)?_c('div',{staticClass:"pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"},[(_vm.value)?_c('span',{staticClass:"text-gray-500 sm:text-sm sm:leading-5"},[_vm._v(" "+_vm._s(_vm.value.length)+"/"+_vm._s(_vm.maxLength)+" ")]):_vm._e()]):_vm._e()])]),_vm._t("errorMessage",function(){return [(_vm.errors)?_c('p',{staticClass:"mt-2 text-xs text-red-400"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()]}),_vm._t("tip",function(){return [(_vm.tip)?_c('p',{staticClass:"mt-2 text-sm text-gray-500"},[_vm._v(" "+_vm._s(_vm.tip)+" ")]):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }