<template>
    <div class="mt-5 bg-white shadow print:shadow-none sm:rounded-md">
        <div class="px-4 sm:col-span-2 sm:px-6 lg:px-8">
            <div class="mt-3 flex flex-col print:block">
                <div class="-mx-4 -my-2 overflow-x-auto py-2 sm:-mx-6 lg:-mx-8">
                    <div
                        class="inline-block min-w-full overflow-hidden align-middle sm:rounded-sm print:overflow-visible"
                    >
                        <table class="w-full min-w-full px-3 sm:px-4 lg:px-6">
                            <thead>
                                <tr>
                                    <th
                                        class="border-b border-gray-200 bg-gray-100 px-3 py-3 text-left text-xs font-medium text-gray-500 sm:rounded-tl-md sm:px-4"
                                    >
                                        Items <span class="font-mono">({{countOrderItems}})</span>
                                    </th>
                                    <th
                                        class="border-b border-gray-200 bg-gray-100 px-3 py-3 text-right text-xs font-medium text-gray-500 sm:px-4"
                                    >
                                        Quantity
                                    </th>

                                    <th
                                        class="border-b border-gray-200 bg-gray-100 px-3 py-3 text-right text-xs font-medium text-gray-500 sm:rounded-tr-md sm:px-4"
                                    >
                                        Price
                                    </th>
                                </tr>
                            </thead>
                            <tbody
                                class="bg-white"
                                v-if="
                                    order && order.items && order.items.length
                                "
                            >
                                <order-menu-item
                                    :item="item"
                                    v-for="(item, index) in order.items"
                                    :key="item.id + '-' + index"
                                />
                            </tbody>
                            <tbody
                                class="bg-white"
                                v-if="
                                    order && order.items && order.items.length
                                "
                            >
                            </tbody>
                        </table>
                        <ul class="w-full px-3 pb-4 sm:px-4">
                            <li
                                v-if="order.serviceCharge"
                                class="mt-3 flex flex-auto flex-row justify-between text-sm text-gray-600"
                            >
                                <span class="total"> {{ order.venue.serviceChargeLabel || 'Service charge' }} </span>
                                <span class="font-mono text-gray-900">
                                    {{ (order.serviceCharge / 100) | currency }}
                                </span>
                            </li>
                            <li
                                class="mt-1 flex flex-auto flex-row justify-between text-sm text-gray-600 sm:mt-2"
                                v-if="
                                    order.fulfillmentMethod === 'Delivery' &&
                                    order.deliveryFee
                                "
                            >
                                <span class="total"> Delivery Fee </span>
                                <span class="font-mono text-gray-900">
                                    {{ (order.deliveryFee / 100) | currency }}
                                </span>
                            </li>
                            <li
                                class="mt-1 flex flex-auto flex-row justify-between text-sm text-gray-600 sm:mt-2"
                                v-if="order.tip > 0"
                            >
                                <span class="total">  {{ order.venue.tipLabel || 'Gratuity' }} </span>
                                <span class="font-mono text-gray-900">{{
                                    (order.tip / 100) | currency
                                }}</span>
                            </li>
                            <li
                                class="mt-1 flex flex-auto flex-row justify-between text-sm text-gray-600 sm:mt-2"
                                v-if="
                                    order.orderDiscountCode &&
                                    order.orderDiscountCode.length
                                "
                            >
                                <span class="total">
                                    Discount
                                    <router-link
                                        :to="{
                                            name: 'editDiscount',
                                            params: {
                                                id: order.discountCodeId
                                            }
                                        }"
                                        class="rounded-sm font-mono hover:bg-gray-100"
                                    >
                                        [{{ order.orderDiscountCode[0].code }}]
                                    </router-link>
                                </span>
                                <span class="font-mono text-red-800">
                                    ({{
                                        (order.discountAmount / 100) | currency
                                    }})
                                </span>
                            </li>
                            <li
                                v-if="loyaltyDiscount > 0"
                                class="mt-1 flex flex-auto flex-row justify-between text-sm text-gray-600 sm:mt-2"
                            >
                                <span class="total"> Loyalty discount </span>
                                <span class="font-mono text-red-800">
                                    ({{ (loyaltyDiscount / 100) | currency }})
                                </span>
                            </li>
                            <li
                                v-if="order.tip || order.deliveryFee || order.serviceCharge || order.discountAmount"
                                class="mt-2 h-px border-t border-dashed border-gray-200"
                            >
                            </li>
                            <li
                                class="mt-2 flex flex-auto flex-row justify-between py-2 font-heading text-sm font-bold text-gray-900"
                            >
                                <span class="total"> Total </span>
                                <span class="price">
                                    {{ (order.total / 100) | currency }}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OrderMenuItem from '@/components/OrderMenuItem';

export default {
    name: 'OrderLineItems',
    components: {
        OrderMenuItem
    },
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    computed: {
        countOrderItems() {
            if (!this.order || !Array.isArray(this.order.items) || this.order.items.length === 0) {
                return '';
            }
            return this.order.items.reduce((acc, item) => {
                return acc + item.quantity;
            }, 0);
        },
        loyaltyDiscount() {
            const { customer } = this.order;
            const piggyDiscountSum = customer?.piggy?.piggyProductDiscount || 0;
            const comoDiscountSum = customer?.como?.discountSum || 0;

            return piggyDiscountSum || comoDiscountSum;
        }
    }
};
</script>
