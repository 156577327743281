<template>
    <div
        class="flex min-h-screen w-full flex-col bg-gray-50 py-12 sm:px-6 lg:px-8"
    >
        <div class="rounded-sm bg-white p-12 shadow-sm">
            <div v-show="!isLoading">
                <BasePageTitle
                    class="mb-6"
                    title="Select your Lightspeed location:"
                />

                <div v-for="business of businesses" :key="business.id">
                    <div
                        v-for="location of business.businessLocations"
                        :key="location.id"
                    >
                        <button
                            @click="selectLocation(location.id)"
                            type="submit"
                            class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                        >
                            {{ business.name }} - {{ location.name }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'lightspeedAuth',
    metaInfo: {
        title: 'Lightspeed Auth'
    },
    data() {
        return {
            isLoading: false,
            isSuccess: false,
            clientId: null,
            clientSecret: null,
            user: {
                password: ''
            },
            venues: [],
            selectedVenue: {},
            integrationExists: false,
            businesses: []
        };
    },
    async mounted() {
        this.isLoading = true;
        this.getToken();

        this.fetchVenues();
    },

    methods: {
        async getToken() {
            try {
                if (this.$route.query.code) {
                    await this.$axios.post('/ikentoo/token', {
                        code: this.$route.query.code,
                        venueId: parseInt(this.$route.query.state)
                    });
                }

                await this.getBusinesses();
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        venueById(id) {
            return this.venues.find(venue => venue.id === id);
        },
        async getBusinesses() {
            const { data } = await this.$axios.get('/ikentoo/businesses', {
                params: {
                    venueId: this.$route.query.state
                }
            });

            this.businesses = data;
        },

        async selectLocation(id) {
            try {
                this.isLoading = true;
                const venueId = parseInt(this.$route.query.state);
                await this.$axios.post('/ikentoo/businesses', {
                    ikentooId: id,
                    venueId
                });

                this.$notify({
                    group: 'settings',
                    title: 'Lightspeed Connected!'
                });

                const venue = this.venueById(venueId);

                setTimeout(() => {
                    this.$router.replace({
                        name: 'lightspeedSettings',
                        params: { id: venue.slug }
                    });
                }, 1000);
            } catch (error) {
                this.isLoading = false;
                throw new Error(`API ${error}`);
            }
        },

        async fetchVenues() {
            this.isLoading = true;

            try {
                const { data } = await this.$axios.get('/venues');

                this.venues = data;

            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
