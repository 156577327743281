var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-4"},[_c('div',{staticClass:"mb-4 flex justify-center text-lg font-bold"},[_c('span',{staticClass:"my-auto cursor-pointer",on:{"click":_vm.back}},[_c('base-back-icon')],1),_c('span',{staticClass:"flex flex-1 justify-center font-heading"},[_vm._v(_vm._s(_vm.isEditMode ? 'Edit zone' : 'Add new zone'))])]),(!_vm.zoneTypeSelected)?_c('div',[_c('p',{staticClass:"text-sm text-gray-700 mb-4"},[_vm._v("Select a delivery zone type for your area")]),_c('div',{staticClass:"space-y-3"},_vm._l((_vm.availableZoneTypes),function(zone){return _c('div',{key:zone.key,staticClass:"border border-gray-700 rounded-md p-3 cursor-pointer hover:bg-gray-900 group hover:text-white transition-colors duration-200",on:{"click":function($event){return _vm.selectZoneType(zone.key)}}},[_c('div',{staticClass:"flex flex-col"},[_c('h3',{staticClass:"text-sm font-medium text-gray-900 group-hover:text-white"},[_vm._v(_vm._s(zone.name))]),_c('p',{staticClass:"mt-1 text-xs text-gray-600 group-hover:text-gray-50"},[_vm._v(_vm._s(zone.description))])])])}),0)]):_c('div',{staticClass:"mt-4"},[_c('base-input-field',{staticClass:"mt-3",attrs:{"label":"Name","id":"name","placeholder":"e.g. Zone 1","errors":_vm.$v.formData.name.$error,"errorMessage":"Please give this zone a name."},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),(_vm.showRadiusInput)?_c('base-input-field',{staticClass:"mt-3",attrs:{"label":"Driving distance","suffix":"miles","id":"radius","placeholder":"e.g. 2.4","errors":_vm.$v.formData.radius.$error,"errorMessage":_vm.$v.formData.radius.$error && !_vm.$v.formData.radius.required
                    ? 'Driving distance is required.'
                    : 'Radius value must be positive.',"type":"number"},model:{value:(_vm.formData.radius),callback:function ($$v) {_vm.$set(_vm.formData, "radius", $$v)},expression:"formData.radius"}}):_vm._e(),_c('div',{staticClass:"mt-3"},[_c('label',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Customers will be charged this fee for delivery within this zone.'),expression:"'Customers will be charged this fee for delivery within this zone.'"}],staticClass:"flex items-center text-sm font-medium leading-5 text-gray-700",attrs:{"for":"zone-fee"}},[_vm._v("Delivery Fee "),_c('base-question-icon',{staticClass:"ml-1 text-gray-700"})],1),_c('div',{staticClass:"relative mt-1 w-full rounded-md shadow-sm"},[_c('div',{staticClass:"pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"},[_c('span',{staticClass:"text-gray-500 sm:text-sm sm:leading-5"},[_vm._v(" "+_vm._s(_vm.currency.symbol)+" ")])]),_c('currency-input',{staticClass:"form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5",class:{
                        'border-red-300': _vm.$v.formData.deliveryFee.$error
                    },attrs:{"id":"zone-fee","placeholder":"4.50","currency":null,"locale":"en-GB","value-as-integer":true,"allow-negative":false},model:{value:(_vm.formData.deliveryFee),callback:function ($$v) {_vm.$set(_vm.formData, "deliveryFee", $$v)},expression:"formData.deliveryFee"}})],1),(_vm.$v.formData.deliveryFee.$error)?_c('p',{staticClass:"mt-2 text-xs text-red-600"},[_vm._v(" Delivery fee is required, enter 0 to not charge a fee. ")]):_vm._e()]),_c('div',{staticClass:"py-3 flex flex-col"},[_c('label',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Enable minimum spend requirements for this zone. Customers will need to spend at least this amount to place an order if their address falls within this zone. This value will override the store minimum spend requirement.'),expression:"'Enable minimum spend requirements for this zone. Customers will need to spend at least this amount to place an order if their address falls within this zone. This value will override the store minimum spend requirement.'"}],staticClass:"flex items-center text-sm font-medium leading-5 text-gray-700 pb-2"},[_vm._v(" Minimum spend required "),_c('base-question-icon',{staticClass:"ml-1 text-gray-700"})],1),_c('base-small-toggle-switch',{model:{value:(_vm.requiresMinSpend),callback:function ($$v) {_vm.requiresMinSpend=$$v},expression:"requiresMinSpend"}})],1),_c('div',{directives:[{name:"auto-animate",rawName:"v-auto-animate"}]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.requiresMinSpend),expression:"requiresMinSpend"}],staticClass:"mt-1"},[_c('label',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                        'The amount customers will need to spend to place an order if their address falls within this zone. Calculated as the sum of items, before gratuity and delivery fees.'
                    ),expression:"\n                        'The amount customers will need to spend to place an order if their address falls within this zone. Calculated as the sum of items, before gratuity and delivery fees.'\n                    "}],staticClass:"flex items-center text-sm font-medium leading-5 text-gray-700",attrs:{"for":"zone-minDeliveryAmount"}},[_vm._v("Minimum spend amount "),_c('base-question-icon',{staticClass:"ml-1 text-gray-700"})],1),_c('div',{staticClass:"relative mt-1 w-full rounded-md shadow-sm"},[_c('div',{staticClass:"pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"},[_c('span',{staticClass:"text-gray-500 sm:text-sm sm:leading-5"},[_vm._v(" "+_vm._s(_vm.currency.symbol)+" ")])]),_c('currency-input',{staticClass:"form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5",class:{
                            'border-red-300':
                                _vm.$v.formData.minDeliveryAmount.$error
                        },attrs:{"id":"zone-minDeliveryAmount","placeholder":"12.50","currency":null,"locale":"en-GB","value-as-integer":true,"allow-negative":false},model:{value:(_vm.formData.minDeliveryAmount),callback:function ($$v) {_vm.$set(_vm.formData, "minDeliveryAmount", $$v)},expression:"formData.minDeliveryAmount"}})],1),(_vm.$v.formData.minDeliveryAmount.$error)?_c('p',{staticClass:"mt-2 text-xs text-red-600"},[_vm._v(" Minimum delivery is required. ")]):_vm._e()]),(_vm.isCountryValid && _vm.selectedTab === 'postcodeZones')?_c('div',{staticClass:"mt-2"},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700",attrs:{"for":"postCodes"}},[_vm._v("Select postcodes")]),(_vm.postcodes)?_c('multi-select',{attrs:{"id":"postCodes","placeholder":"Postcodes","allow-empty":false,"searchable":true,"options":_vm.postcodes,"multiple":true,"close-on-select":true},on:{"select":_vm.addEditLayer,"remove":_vm.remove,"close":function($event){_vm.showAllPostcodes = false}},scopedSlots:_vm._u([{key:"selection",fn:function({ values, remove }){return (!_vm.showAllPostcodes)?[_c('div',{staticClass:"multiselect__tags-wrap"},[_vm._l((values.slice(
                                    0,
                                    3
                                )),function(option,index){return _c('span',{key:index,staticClass:"multiselect__tag"},[_vm._v(" "+_vm._s(option)+" "),_c('span',{staticClass:"multiselect__tag-icon",on:{"click":function($event){return remove(option)}}})])}),(values.length > 3)?_c('span',{staticClass:"multiselect__tag cursor-pointer",on:{"click":function($event){_vm.showAllPostcodes = true}}},[_vm._v(" +"+_vm._s(values.length - 3)+" more ")]):_vm._e()],2)]:undefined}}],null,true),model:{value:(_vm.formData.postCodes),callback:function ($$v) {_vm.$set(_vm.formData, "postCodes", $$v)},expression:"formData.postCodes"}}):_vm._e(),(_vm.$v.formData.postCodes.$error)?_c('p',{staticClass:"mt-2 text-xs text-red-600"},[_vm._v(" Please select at least one postcode. ")]):_vm._e()],1):_vm._e()]),(_vm.$v.coordinates.$error)?_c('p',{staticClass:"mt-2 text-xs text-red-600"},[_vm._v(" Please draw a delivery zone before saving.")]):_vm._e(),_c('div',{staticClass:"mt-4 flex w-full items-center justify-between"},[(_vm.isEditMode)?_c('span',{staticClass:"cursor-pointer rounded-md px-3 py-1 text-xs font-medium text-gray-500 hover:bg-red-100 hover:text-red-500",on:{"click":_vm.deleteZone}},[_vm._v("Delete zone")]):_vm._e(),_c('base-button',{attrs:{"buttonText":_vm.isEditMode ? 'Save' : 'Create zone',"size":"sm"},on:{"clicked":function($event){_vm.isEditMode ? _vm.editDeliveryZone() : _vm.addDeliveryZone()}}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }