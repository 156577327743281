<template>
    <div class="flex w-full flex-col gap-2 overflow-hidden pb-2">
        <div
            class="flex w-max cursor-pointer items-end justify-start gap-1 rounded py-0.5 hover:bg-gray-100"
            @click="isOpened = !isOpened"
        >
            <label
                class="inline cursor-pointer text-xs select-none font-medium leading-5 tracking-wide text-gray-700  line-clamp-1"
            >
                {{ title }}
            </label>
            <svg
                viewBox="0 0 16 16"
                version="1.1"
                class="mb-0.5 h-4 w-4 transition duration-300 ease-in-out"
                :class="{ 'rotate-180 transform': !isOpened }"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
            >
                <polygon points="8,5 13,10 3,10" />
            </svg>
        </div>

        <template v-if="isOpened">
            <div class="flex w-full items-end gap-2 pt-2 px-1">
                <div class="flex w-full flex-col gap-1.5">
                    <label
                        class="text-xs font-medium leading-4 tracking-wide text-gray-700"
                    >
                        Style
                    </label>
                    <select
                        v-model="style"
                        class="focus:outline-none inline-block w-full rounded-md border border-gray-300 py-1 pl-3 pr-9 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                        @change="borderChanged"
                    >
                        <option
                            v-for="option of styleOptions"
                            :key="option"
                            :value="option"
                            :selected="option === style"
                        >
                            {{ option }}
                        </option>
                    </select>
                </div>
                <colour-picker
                    v-model="color"
                    class="w-full"
                    :swatches="swatches"
                    @input="borderChanged(false)"
                    @change="borderChanged"
                />
            </div>
            <div class="flex w-full flex-col gap-2 pt-2 px-1">
                <div class="flex items-end justify-between">
                    <label
                        class="text-xs font-medium leading-4 tracking-wide text-gray-700"
                    >
                        Width
                    </label>
                    <span class="text-xs">{{ widthPx }}</span>
                </div>
                <input
                    v-model="width"
                    class="h-1.5 w-full cursor-pointer accent-black appearance-none rounded-md bg-gray-200"
                    type="range"
                    step="1"
                    max="10"
                    min="0"
                    @input="borderChanged"
                />
            </div>
        </template>
    </div>
</template>

<script>
import ColourPicker from '@/components/storeDesigner/ColourPicker';

export default {
    props: {
        value: {
            type: [String, null],
            required: false,
            default: null
        },
        keyName: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        swatches: {
            type: Array,
            required: false,
            default: () => [
                '#000000',
                '#222831',
                '#ffffff',
                '#393e46',
                '#583d72',
                '#9f5f80',
                '#99c1de',
                '#84a59d',
                '#ffd369',
                '#f5cac3',
                '#ff8e71'
            ]
        }
    },
    components: {
        ColourPicker
    },
    data() {
        return {
            color: '#000000',
            width: 1,
            style: 'solid',
            isOpened: false,
            styleOptions: [
                'solid',
                'dotted',
                'dashed',
                'double',
                'groove',
                'ridge',
                'inset',
                'outset',
                'none'
            ]
        };
    },
    computed: {
        computedValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        widthPx() {
            return `${this.width}px`;
        },
        border() {
            return `${this.widthPx} ${this.style} ${this.color}`.trim();
        }
    },
    watch: {
        value() {
            this.extractValues();
        }
    },
    created() {
        this.extractValues();
    },
    methods: {
        extractValues() {
            const values = this.value ? this.value.split(' ') : [];

            this.color =
                values.find(
                    value => value.includes('#') || value.includes('rgb')
                ) || '#000000';
            this.style =
                values.find(value => this.styleOptions.includes(value)) ||
                'solid';
            this.width = (
                values.find(value => value.includes('px')) || '0'
            ).replace('px', '');
        },
        borderChanged(changeImmediately = true) {
            this.computedValue = this.border;

            if (changeImmediately) {
                this.$emit('change', this.keyName, this.border);
            }
        }
    }
};
</script>
