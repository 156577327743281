<template>
    <div class="flex w-0 flex-1 flex-col overflow-hidden">
        <main class="focus:outline-none relative z-0 h-full flex-1">
            <div
                class="mx-auto flex max-w-7xl flex-row items-center justify-between px-4 sm:px-6 lg:px-8"
            >
                <div
                    class="h-4 w-4"
                    :class="{ 'text-gray-900 spinner': isLoading }"
                >
                    <span></span>
                </div>
            </div>
            <div
                class="mx-auto flex h-full max-w-7xl flex-col justify-center px-4 sm:px-6 md:px-8"
            >
                <base-page-title title="Import discounts from a spreadsheet" />

                <div class="flex flex-col md:flex-row">
                    <div
                        class="mr-3 mt-6 flex w-full flex-row bg-white shadow sm:rounded-lg md:w-1/2"
                    >
                        <div class="w-full px-4 py-5 sm:p-6">
                            <div>
                                <p class="text-sm text-gray-600"
                                    >You'll need to prepare a CSV or Excel file
                                    to upload your discounts, we've created some
                                    example files to help you get started.
                                    <br /><br />If you have any questions, use
                                    the live chat button to get help!</p
                                >

                                <div class="mt-3 flex items-center">
                                    <a
                                        href="https://ucarecdn.com/a2f208d6-7c45-4fd1-b77c-52a1a094e186/BulkDiscountImportTemplate.xlsx"
                                        target="_blank"
                                        class="flex cursor-pointer items-center rounded-md p-2 text-sm font-medium text-gray-600 hover:bg-gray-50"
                                    >
                                        <base-excel-icon class="mr-2" />
                                        Download Excel file
                                    </a>
                                    <a
                                        target="_blank"
                                        href="https://ucarecdn.com/bc7d7745-2f8b-4aa5-a697-81c503cd9de1/BulkDiscountImportTemplateFixedDiscount.csv"
                                        class="ml-4 flex cursor-pointer items-center rounded-md p-2 text-sm font-medium text-gray-600 hover:bg-gray-50"
                                    >
                                        <base-csv-file-icon class="mr-2" />
                                        Download CSV file
                                    </a>
                                </div>

                                <hr class="mt-3" />

                                <flat-file-launcher
                                    class="mt-4"
                                    managed
                                    title="Discount code import"
                                    :fields="csvImportFields"
                                    :disableManualInput="false"
                                    type="Discount code"
                                    :status="csvImportStatus"
                                    @csvImport="submitCsvImport"
                                    :check-date="true"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div
                        class="mt-10 flex flex-col pl-2 sm:flex-row sm:flex-wrap"
                    >
                        <button
                            @click="$router.push('/discounts')"
                            type="button"
                            class="focus:outline-none group -ml-2 mt-2 flex cursor-pointer items-center rounded-md rounded-sm p-1.5 text-sm leading-5 text-gray-600 hover:bg-gray-200 hover:text-gray-700 sm:mr-6"
                        >
                            <base-back-icon />
                            Go back
                        </button>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, url } from 'vuelidate/lib/validators';
import FlatFileLauncher from '@/components/csv/FlatFileLauncher';
import { DiscountTypeEnum } from '@/enums';

export default {
    name: 'DiscountImportSpreadsheet',
    metaInfo: {
        title: 'Import Discounts'
    },
    validations: {
        urlImport: {
            url: {
                required,
                url
            }
        }
    },
    components: {
        FlatFileLauncher
    },
    data() {
        return {
            isLoading: false,
            menuId: null,
            csvImportStatus: 'pending',
            csvImportFields: [
                {
                    label: 'Code',
                    key: 'code',
                    validators: [
                        {
                            validate: 'required'
                        }
                    ]
                },
                {
                    label: 'Description',
                    key: 'description'
                },
                {
                    label: 'Type',
                    key: 'type',
                    type: 'select',
                    options: [
                        {
                            value: DiscountTypeEnum.FIXED,
                            label: 'Fixed'
                        },
                        {
                            value: DiscountTypeEnum.PERCENT,
                            label: 'Percent'
                        },
                        {
                            value: DiscountTypeEnum.FREE_DELIVERY,
                            label: 'Free delivery'
                        }
                    ],
                    validators: [
                        {
                            validate: 'required'
                        },
                        {
                            validate: 'regex_matches',
                            regex:
                                '^(fixed|percent|freedelivery|buyonegetonefree)$'
                        }
                    ]
                },
                {
                    label: 'Discount value',
                    key: 'value',
                    validators: [
                        {
                            validate: 'required'
                        },
                        {
                            validate: 'regex_matches',
                            regex: '^(?=.)([+-]?([0-9]*)(.([0-9]+))?)$',
                            error: 'Must be a number'
                        }
                    ]
                },
                {
                    label: 'Limit availability',
                    key: 'days',
                    validators: [
                        {
                            validate: 'regex_matches',
                            regex:
                                '\\b(monday|tuesday|wednesday|thursday|friday|saturday|sunday)\\b',
                            error: 'Must include at least one day of the week',
                            regexFlags: {
                                ignoreCase: true
                            }
                        }
                    ]
                },
                {
                    label: 'Time availability - start',
                    key: 'startTime',
                    validators: [
                        {
                            validate: 'required_with',
                            fields: ['endTime']
                        },
                        {
                            validate: 'regex_matches',
                            regex: '^([01][0-9]|2[0-3]):([0-5][0-9])$',
                            error: 'Time is invalid'
                        }
                    ]
                },
                {
                    label: 'Time availability - end',
                    key: 'endTime',
                    validators: [
                        {
                            validate: 'required_with',
                            fields: ['startTime']
                        },
                        {
                            validate: 'regex_matches',
                            regex: '^([01][0-9]|2[0-3]):([0-5][0-9])$',
                            error: 'Time is invalid'
                        }
                    ]
                },
                {
                    label: 'Minimum spent',
                    key: 'minimumAmount',
                    validators: [
                        {
                            validate: 'regex_matches',
                            regex: '^(?=.)([+-]?([0-9]*)(.([0-9]+))?)$',
                            error: 'Must be a number'
                        }
                    ]
                },
                {
                    label: 'Usage limit',
                    key: 'usageLimit',
                    validators: [
                        {
                            validate: 'regex_matches',
                            regex: '^\\d+$',
                            error: 'Must be a number'
                        }
                    ]
                },
                {
                    label: 'Exclude delivery charge',
                    key: 'excludeDelivery',
                    type: 'checkbox'
                },
                {
                    label: 'Fulfillment methods',
                    key: 'fulfillmentMethods',
                    validators: [
                        {
                            validate: 'regex_matches',
                            regex: '\\b(instore|pickup|delivery)\\b',
                            error:
                                'Must include at least one of "InStore", "Pickup", or "Delivery"',
                            regexFlags: {
                                ignoreCase: true
                            }
                        }
                    ]
                },
                {
                    label: 'Excluded payment types',
                    key: 'excludedPaymentTypes',
                    validators: [
                        {
                            validate: 'regex_matches',
                            regex: '\\b(Adyen|Cash)?\\b',
                            error:
                                'Must be one of "Adyen" or "Cash"',
                            regexFlags: {
                                ignoreCase: true
                            }
                        }
                    ]
                },
                {
                    label: 'Venue Ids',
                    key: 'venueIds',
                    description: 'Ids must be separated by a comma',
                    validators: [
                        {
                            validate: 'required'
                        }
                    ]
                },
                {
                    label: 'startDate',
                    key: 'startDate',
                    validators: [
                        {
                            validate: 'required_with',
                            fields: ['endDate']
                        }
                    ]
                },
                {
                    label: 'endDate',
                    key: 'endDate',
                    validators: [
                        {
                            validate: 'required_with',
                            fields: ['startDate']
                        }
                    ]
                }
            ]
        };
    },
    computed: {
        ...mapGetters({ user: 'user/currentUser' })
    },
    methods: {
        async submitCsvImport(data) {
            this.isLoading = true;
            this.csvImportStatus = 'pending';

            try {
                await this.$axios.post('/discount-codes/import-csv', data);

                this.csvImportStatus = 'success';

                await this.fetchDiscounts();

                this.$notify({
                    group: 'settings',
                    title: 'Discount codes uploaded successfully'
                });
            } catch (e) {
                this.csvImportStatus = 'failed';
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>

<style scoped></style>
