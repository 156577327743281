export const RESTRICTED_ACCOUNTS = {
    // Manjaros reporting access
    '89449ec1-8df1-4bbb-997e-bd9348bcc113': [10846],
    '413c45a4-ef9d-4365-902a-82576bf3a35c': [10847],
    '048d3672-331c-4241-beb0-b60e3cc72cda': [9474, 10903],
    '7d627c9e-6502-4068-84f4-868499ccda63': [11336],
    // Byron
    '4222230b-dc4d-44fc-9cb3-2dfd184955b6': {
        blockedUsers: [11168]
    },
    // Gracey's
    'c41e04e9-a424-44b9-93b4-3cc3166b1995': {
        blockedUsers: [11895]
    },
    // Savan
    '7569153a-c9eb-4974-8fcd-3584b76a6800': [5925],
    // Arancina
    '7a8ed699-6c9a-4404-a679-1588470ddd40': {
        blockedUsers: [9438]
    },
    // Sliceburg
    'da7b7de5-afb2-43e8-92fa-82adab05e767': {
        blockedUsers: [13032]
    }
};