<template>
    <div
        class="flex w-full max-w-xs flex-1 transform rounded-r-lg flex-col bg-sk-black duration-300 ease-in-out"
    >
        <div class="absolute right-0 top-0 -mr-14 p-1">
            <button
                x-show="sidebarOpen"
                @click="$store.commit('ui/UPDATE_SIDEBAR', false)"
                class="focus:outline-none flex h-12 w-12 items-center justify-center rounded-full text-white focus:bg-gray-600"
            >
                <base-close-icon />
            </button>
        </div>
        <div class="h-0 flex-1 overflow-y-auto pb-4 pt-5">
            <div class="flex flex-shrink-0 items-center px-4">
                <img
                    class="ml-6 h-7 w-auto"
                    :src="$store.state.ui.logoWhiteUrl"
                    alt="Logo"
                />
            </div>
            <SidebarContent />
        </div>
        <SidebarInformations />
        <SidebarAccount class="mt-3" />
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SidebarContent from './SidebarContent';
import SidebarInformations from './SidebarInformations';
import SidebarAccount from './SidebarAccount';

export default {
    components: {
        SidebarContent,
        SidebarInformations,
        SidebarAccount
    },
    computed: {
        ...mapGetters({ currentUser: 'user/currentUser' })
    }
};
</script>
