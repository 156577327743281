<template>
    <div
        class="flex min-h-screen w-full flex-row justify-start bg-sk-silver-grey sm:justify-center"
    >
        <div
            class="flex w-full flex-col justify-center px-4 py-12 sm:px-6 lg:px-8"
        >
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    class="ml-0 mr-auto h-10 w-auto"
                    :src="logo"
                    alt="Logo"
                    v-if="logo"
                />
            </div>

            <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
                <div
                    class="bg-white px-4 py-8 shadow-xl sm:rounded-md sm:px-10 sm:px-5 sm:py-10 md:px-12 md:py-12"
                >
                    <h2
                        class="mb-6 text-left font-heading text-xl font-bold leading-7 text-gray-700"
                    >
                        {{ clientName }} wants access to your storekit account.
                    </h2>
                    <div
                        class="mt-6"
                        v-if="!enableAllVenuesAccess && venues.length"
                    >
                        <div class="mt-1 rounded-md shadow-sm">
                            <multi-select
                                v-model="selectedVenues"
                                @select="onSelectVenue"
                                track-by="name"
                                label="name"
                                :multiple="!isSingleVenueClient"
                                :placeholder="placeholder"
                                :options="venues"
                                :searchable="true"
                                :allow-empty="!isSingleVenueClient"
                            />
                        </div>
                    </div>
                    <div
                        v-if="clientName === OAUTH_CLIENTS.STREAM"
                        class="flex items-center"
                    >
                        <toggle-switch
                            id="venueAccessToggle"
                            v-model="enableAllVenuesAccess"
                        />
                        <label class="ml-2 text-sm leading-5 text-gray-500">
                            Access all available venues
                        </label>
                    </div>
                    <div class="mt-6 flex justify-between">
                        <span
                            class="block w-full rounded-md shadow-sm"
                            :class="{ spinner: isLoading }"
                        >
                            <button
                                @click="consent(false)"
                                type="submit"
                                :disabled="isLoading"
                                class="focus:outline-none flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-3 text-sm font-semibold text-gray-700 text-white transition duration-150 ease-in-out hover:bg-gray-50 focus:border-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                Cancel
                            </button>
                        </span>
                        <span
                            class="ml-4 block w-full rounded-md shadow-sm"
                            :class="{ spinner: isLoading }"
                        >
                            <button
                                @click="consent(true)"
                                type="submit"
                                :disabled="isLoading"
                                class="focus:outline-none flex w-full justify-center rounded-md border border-transparent bg-indigo-800 px-4 py-3 text-sm font-semibold text-white transition duration-150 ease-in-out hover:bg-indigo-900 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                            >
                                Allow
                            </button>
                        </span>
                    </div>
                    <div class="mt-6"> </div>
                    <div class="mt-1" v-if="$store.state.user.errors">
                        <span class="py-2 text-xs text-red-600"
                            >Couldn't login. Please try again, reset your
                            password or create an account</span
                        >
                    </div>
                    <div class="mt-1" v-if="errors">
                        <span class="py-2 text-xs text-red-600">{{
                            selectionError
                        }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import isEmail from 'vuelidate/lib/validators/email';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';

const OAUTH_CLIENTS = {
    OPENTABLE: 'OpenTable',
    STREAM: 'Stream'
};

const SINGLE_VENUE_CLIENTS = [OAUTH_CLIENTS.OPENTABLE];

export default {
    name: 'OAuthConsent',
    metaInfo: {
        title: 'Login'
    },
    components: { ToggleSwitch },
    computed: {
        logo() {
            return (
                this.clientLogo || process.env.VUE_APP_WIHTELABEL_LOGO || null
            );
        },
        validEmail() {
            return isEmail(this.user.email) ? this.user.email : '';
        },
        isSingleVenueClient() {
            return SINGLE_VENUE_CLIENTS.includes(this.clientName);
        },
        isValidVenueSelection() {
            if (!this.selectedVenues.length && !this.enableAllVenuesAccess) {
                return false;
            }

            if (this.isSingleVenueClient) {
                return this.selectedVenues.length === 1;
            }

            return true;
        },
        selectionError() {
            return this.isSingleVenueClient
                ? 'Please select a store'
                : 'Please select at least one store';
        },
        placeholder() {
            return this.isSingleVenueClient
                ? 'Select one store'
                : 'Select stores';
        }
    },
    data() {
        return {
            isLoading: false,
            venues: [],
            selectedVenues: [],
            errors: false,
            clientName: '',
            clientLogo: '',
            enableAllVenuesAccess: false,
            OAUTH_CLIENTS
        };
    },
    async mounted() {
        await this.getInfo();
    },
    methods: {
        async getInfo() {
            try {
                const { data } = await this.$axios.get(`/oauth/get-info`, {
                    params: {
                        challenge: this.$route.query.consent_challenge
                    }
                });

                this.venues = data.venues || [];
                this.clientName = data.client_name;
                this.clientLogo = data.logo_uri;
                this.enableAllVenuesAccess =
                    this.clientName === OAUTH_CLIENTS.STREAM;

                if (this.isSingleVenueClient && this.venues.length === 1) {
                    this.selectedVenues = [this.venues[0]];
                }
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },

        async consent(accepted) {
            if (accepted && !this.isValidVenueSelection) {
                this.errors = true;
                return;
            }

            this.isLoading = true;

            if (this.enableAllVenuesAccess) {
                this.selectedVenues = [];
            }

            if (this.$route.query.consent_challenge) {
                try {
                    const { data } = await this.$axios.post(`/oauth/consent`, {
                        challenge: this.$route.query.consent_challenge,
                        accepted,
                        venueIds: this.selectedVenues.map(venue => venue.id),
                        clientName: this.clientName
                    });

                    window.location.href = data.redirect;
                } catch (error) {
                    throw new Error(`API ${error}`);
                } finally {
                    this.isLoading = false;
                }

                return;
            }
        },
        onSelectVenue() {
            if (!Array.isArray(this.selectedVenues)) {
                this.selectedVenues = [this.selectedVenues];
            }

            this.errors = false;
        }
    }
};
</script>

<style scoped>
.spinner:after {
    left: auto;
    right: 1em;
    border-right-color: white;
}
</style>
