<template>
    <div class="flex w-0 flex-1 flex-col overflow-hidden">
        <base-toggle-sidebar-button />
        <main
            class="focus:outline-none relative z-0 flex-1 overflow-y-auto pb-6 pt-2 md:py-6"
        >
            <div
                class="mx-auto flex max-w-7xl flex-row items-center justify-between px-4 sm:px-6 lg:px-8"
            >
                <BasePageTitle title="Menus" />
                <div
                    class="h-4 w-4"
                    :class="{ 'text-gray-900 spinner': isLoading }"
                >
                    <span></span>
                </div>
                <SortBySelector @toggleSort="sortMenu" />
            </div>

            <div
                class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8"
                v-if="isLoading"
            >
                <div class="py-4">
                    <div class="overflow-hidden bg-white shadow sm:rounded-md">
                        <div class="p-4">
                            <VclList :width="400" :height="200" />
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="mx-auto mt-10 max-w-7xl p-4 px-4 sm:mt-0 sm:px-6 md:px-8"
                v-else-if="menus && menus.length > 0"
            >
                <div>
                    <MenuListItem
                        v-for="(menu, index) in menus"
                        v-show="showArchived || !menu.archived"
                        :key="menu.id"
                        :menu="menu"
                        :index="index"
                    />
                </div>
            </div>

            <create-venue-card v-else-if="!availableVenues.length" />

            <div
                class="mx-auto mt-10 max-w-7xl p-4 px-4 sm:mt-0 sm:px-6 md:px-8"
                v-else
            >
                <div class="rounded-sm bg-white shadow">
                    <div class="px-4 py-5 sm:p-6">
                        <h3 class="text-lg font-medium leading-6 text-gray-900">
                            Create a menu
                        </h3>
                        <div
                            class="mt-2 sm:flex sm:items-start sm:justify-between"
                        >
                            <div
                                class="max-w-xl text-sm leading-5 text-gray-500"
                            >
                                <p>
                                    Before you can start taking orders online,
                                    you'll need to setup your first menu.
                                </p>
                            </div>
                            <div
                                class="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:shrink-0 sm:items-center"
                            >
                                <span
                                    @click="
                                        $router.push({
                                            name: 'createMenu',
                                            params: {
                                                venueId: availableVenues[0].id
                                            }
                                        })
                                    "
                                    class="inline-flex rounded-md shadow-sm"
                                >
                                    <button
                                        type="button"
                                        class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                                    >
                                        Create a menu
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

          <div
              class="mx-auto mt-10 max-w-7xl cursor-pointer sm:mt-0"
              v-if="!showArchived && isFeatureAvailable(FeaturesEnum.INTERNAL)"
          >
              <span
                  @click="toggleShowArchivedFlag"
                  class="block p-4 px-4 font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 sm:px-6 md:px-8"
              >
                  Show archived menus
              </span>
          </div>

        </main>
    </div>
</template>

<script>
import { VclList } from 'vue-content-loading';
import { FeaturesEnum } from '@/enums';
import { mapGetters } from 'vuex';
import MenuListItem from '@/components/MenuListItem.vue';
import SortBySelector from '@/components/selectors/SortBySelector.vue';
import CreateVenueCard from '@/components/onboarding/CreateVenueCard.vue';

export default {
    name: 'MenusList',
    metaInfo: {
        title: 'Menus'
    },
    components: {
        VclList,
        MenuListItem,
        CreateVenueCard,
        SortBySelector
    },
    data() {
        return {
            menus: '',
            isLoading: true,
            isOrderChanged: false,
            showArchived: false,
            FeaturesEnum
        };
    },
    created() {
        this.fetchMenus();
    },
    methods: {
        sortMenu(ascending = false) {
            this.menus.sort((a, b) => {
                const dateA = new Date(a.created_at);
                const dateB = new Date(b.created_at);

                return ascending ? dateA - dateB : dateB - dateA;
            });
        },
        async fetchMenus() {
            this.isLoading = true;

            try {
                const { data } = await this.$axios.get(`/menus${ this.isFeatureAvailable(FeaturesEnum.INTERNAL) ? '?showArchived=true' : '' }`);

                this.menus = data;
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
      toggleShowArchivedFlag() {
        this.showArchived = !this.showArchived;
      },
    },
    computed: {
        ...mapGetters({
            availableVenues: 'venues/getVenues',
            isFeatureAvailable: 'user/isFeatureAvailable'
        })
    }
};
</script>

<style scoped></style>
