var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"md:flex md:items-center md:justify-between"},[_c('div',{staticClass:"min-w-0 flex-1"},[_c('router-link',{staticClass:"mb-2 flex inline-flex items-center text-sm text-gray-500",attrs:{"to":{ name: 'editVenue' }}},[_c('base-back-icon'),_c('span',[_vm._v("Settings")])],1),_c('base-page-title',{attrs:{"title":"Pre-Orders"}})],1),_c('BaseSpinner',{attrs:{"isLoading":_vm.isLoading}})],1),_c('div',[_c('div',{staticClass:"mt-4 md:grid md:grid-cols-3 md:gap-6"},[_vm._m(0),_c('div',{staticClass:"mt-5 md:col-span-2 md:mt-0"},[_c('div',{staticClass:"grid grid-cols-6 gap-3"},[_c('div',{staticClass:"col-span-6 mb-2 rounded-lg bg-white px-4 py-5 shadow sm:rounded-md sm:p-5"},[_c('div',{staticClass:"mt-5 md:col-span-2 md:mt-0"},[_c('div',{staticClass:"grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6"},[_c('div',{staticClass:"sm:col-span-6"},[_c('p',{staticClass:"mt-1 text-sm leading-5 text-gray-500"}),_c('div',{staticClass:"mt-3 flex items-start"},[_c('toggle-switch',{attrs:{"value":_vm.slotSettings.acceptsPreOrders},on:{"input":function($event){return _vm.updateVenue(
                                                    'acceptsPreOrders'
                                                )}}}),_c('label',{staticClass:"pl-4 text-sm text-gray-500"},[_vm._v(" Enable to allow customers to order in advance. ")])],1),_c('div',{staticClass:"mt-3 flex items-start"},[_c('toggle-switch',{attrs:{"value":_vm.slotSettings.alwaysOpenForPreorders},on:{"input":function($event){return _vm.updateVenue(
                                                    'alwaysOpenForPreorders'
                                                )}}}),_c('label',{staticClass:"pl-4 text-sm text-gray-500"},[_vm._v(" Enable to allow customers to order outside of venue opening hours. ")])],1)])])])])])])])]),_c('BaseSpacerWithRuler'),_c('div',{staticClass:"sm:hidden"},[_c('label',{staticClass:"sr-only",attrs:{"for":"tabs"}},[_vm._v("Select a tab")]),_c('select',{staticClass:"block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500",attrs:{"id":"tabs","name":"tabs"},on:{"change":function($event){return _vm.selectTab($event.target.value)}}},[_c('option',{attrs:{"value":"pickup"}},[_vm._v("Pickup")]),_c('option',{attrs:{"value":"delivery","selected":""}},[_vm._v("Delivery")])])]),_c('div',{staticClass:"mt-4 hidden sm:block md:grid md:grid-cols-3 md:gap-6"},[_c('div',{staticClass:"border-b border-gray-200 md:col-span-2 md:col-start-2"},[_c('nav',{staticClass:"-mb-px flex space-x-8",attrs:{"aria-label":"Tabs"}},[_c('span',{staticClass:"group inline-flex cursor-pointer items-center border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700",class:[
                        _vm.isPickup
                            ? 'border-indigo-500 text-indigo-600'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                    ],on:{"click":function($event){return _vm.selectTab('pickup')}}},[_c('PickupIcon',{staticClass:"-ml-0.5 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500",class:[_vm.isPickup ? 'text-indigo-500' : '']}),_c('span',[_vm._v("Pickup")])],1),_c('span',{staticClass:"group relative inline-flex cursor-pointer items-center border-b-2 px-1 py-4 text-sm font-medium",class:[
                        !_vm.isPickup
                            ? 'border-indigo-500 text-indigo-600'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                    ],on:{"click":function($event){return _vm.selectTab('delivery')}}},[_c('DeliveryIcon',{staticClass:"-ml-0.5 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500",class:[_vm.isPickup ? '' : 'text-indigo-500']}),_c('span',[_vm._v("Delivery")])],1)])])]),(_vm.selectedTab === 'pickup')?_c('div',[_c('div',[_c('div',{staticClass:"mt-4 md:grid md:grid-cols-3 md:gap-6"},[_vm._m(1),_c('div',{staticClass:"mt-5 md:col-span-2 md:mt-0"},[_c('div',{staticClass:"grid grid-cols-6 gap-3"},[_c('div',{staticClass:"col-span-6 mb-2 rounded-lg bg-white px-4 py-5 shadow sm:rounded-md sm:p-5"},[_c('div',{staticClass:"mt-5 md:col-span-2 md:mt-0"},[_c('div',{staticClass:"grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6"},[_c('div',{staticClass:"sm:col-span-6 md:pr-2",class:{
                                            'pointer-events-none cursor-not-allowed opacity-50': !_vm.acceptsPreOrders
                                        }},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",attrs:{"for":"slotDuration"}},[_vm._v(" Time slot length ")]),_c('p',{staticClass:"mt-1 text-sm leading-5 text-gray-500"},[_vm._v(" Split up the day into multiple time slots customers can choose from when they order. ")]),_c('div',{staticClass:"mt-1 sm:col-span-2 sm:mt-2"},[_c('div',{staticClass:"max-w-xs rounded-md shadow-sm"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(
                                                        _vm.pickupSettings.slotInterval
                                                    ),expression:"\n                                                        pickupSettings.slotInterval\n                                                    "}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",attrs:{"id":"slotDuration"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.pickupSettings, "slotInterval", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.slotDurations),function(duration){return _c('option',{key:duration,domProps:{"value":duration}},[_vm._v(_vm._s(duration)+" Minutes")])}),0)])])]),_c('div',{staticClass:"sm:col-span-6 md:pl-2",class:{
                                            'pointer-events-none cursor-not-allowed opacity-50': !_vm.acceptsPreOrders
                                        }},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",attrs:{"for":"slotQuantity"}},[_vm._v(" Max orders per slot ")]),_c('p',{staticClass:"mt-1 text-sm leading-5 text-gray-500"},[_vm._v(" Limit the number of orders you allow per time slot. Leave empty for unlimited orders per time slot. ")]),_c('div',{staticClass:"mt-1 sm:col-span-2 sm:mt-2"},[_c('div',{staticClass:"max-w-xs rounded-md shadow-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                        _vm.pickupSettings.pickupSlotQuantity
                                                    ),expression:"\n                                                        pickupSettings.pickupSlotQuantity\n                                                    "}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",attrs:{"type":"number","placeholder":"Unlimited","id":"slotQuantity"},domProps:{"value":(
                                                        _vm.pickupSettings.pickupSlotQuantity
                                                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pickupSettings, "pickupSlotQuantity", $event.target.value)}}})])])]),_c('div',{staticClass:"sm:col-span-6 md:pl-2",class:{
                                            'pointer-events-none cursor-not-allowed opacity-50': !_vm.slotSettings.acceptsPreOrders
                                        }},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",attrs:{"for":"pickupSettingsAsapEnabled"}},[_vm._v(" ASAP Order ")]),_c('div',{staticClass:"mt-1 sm:col-span-2 sm:mt-2"},[_c('div',{staticClass:"mt-3 flex"},[_c('toggle-switch',{attrs:{"id":"pickupSettingsAsapEnabled"},model:{value:(
                                                        _vm.pickupSettings.asapEnabled
                                                    ),callback:function ($$v) {_vm.$set(_vm.pickupSettings, "asapEnabled", $$v)},expression:"\n                                                        pickupSettings.asapEnabled\n                                                    "}}),_c('label',{staticClass:"ml-2 text-sm leading-5 text-gray-500"},[_vm._v(" Allow customers to order without choosing a time slot ")])],1)])]),_c('div',{staticClass:"sm:col-span-6 md:pl-2",class:{
                                            'pointer-events-none cursor-not-allowed opacity-50': !_vm.acceptsPreOrders
                                        }},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",attrs:{"for":"slotQuantity"}},[_vm._v(" Max items per slot ")]),_c('p',{staticClass:"mt-1 text-sm leading-5 text-gray-500"},[_vm._v(" Limit the number of items you allow per time slot. Leave empty for unlimited items per time slot. ")]),_c('div',{staticClass:"mt-1 sm:col-span-2 sm:mt-2"},[_c('div',{staticClass:"max-w-xs rounded-md shadow-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                        _vm.pickupSettings.itemQuantity
                                                    ),expression:"\n                                                        pickupSettings.itemQuantity\n                                                    "}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",attrs:{"type":"number","placeholder":"Unlimited","id":"slotQuantity"},domProps:{"value":(
                                                        _vm.pickupSettings.itemQuantity
                                                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pickupSettings, "itemQuantity", $event.target.value)}}})])])]),_c('div',{staticClass:"sm:col-span-6 md:pl-2",class:{
                                            'pointer-events-none cursor-not-allowed opacity-50': !_vm.acceptsPreOrders
                                        }},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",attrs:{"for":"pickupSettingsMonetaryLimit"}},[_vm._v(" Monetary limit per slot ")]),_c('p',{staticClass:"mt-1 text-sm leading-5 text-gray-500"},[_vm._v(" Limit the total amount of orders allowed for the time slot by the sum of order totals. E.g. set a limit of £200 and customers will be prevented from ordering more within a timeslot. ")]),_c('div',{staticClass:"mt-1 sm:col-span-2 sm:mt-2"},[_c('div',{staticClass:"relative mt-1 max-w-xs rounded-md shadow-sm"},[_c('div',{staticClass:"pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"},[_c('span',{staticClass:"text-gray-500 sm:text-sm sm:leading-5"},[_vm._v(" "+_vm._s(_vm.currency.symbol)+" ")])]),_c('currency-input',{staticClass:"form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5",attrs:{"id":"pickupSettingsMonetaryLimit","placeholder":"Unlimited","currency":null,"locale":"en-GB","value-as-integer":true,"allow-negative":false},model:{value:(
                                                        _vm.pickupSettings.monetaryLimit
                                                    ),callback:function ($$v) {_vm.$set(_vm.pickupSettings, "monetaryLimit", $$v)},expression:"\n                                                        pickupSettings.monetaryLimit\n                                                    "}})],1)])]),_c('div',{staticClass:"sm:col-span-6 md:pl-2",class:{
                                            'pointer-events-none cursor-not-allowed opacity-50': !_vm.acceptsPreOrders
                                        }},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",attrs:{"for":"maxFutureDays"}},[_vm._v(" Limit future date selection ")]),_c('p',{staticClass:"mt-1 text-sm leading-5 text-gray-500"},[_vm._v(" How far in advance customers can place orders (in days). ")]),_c('div',{staticClass:"mt-1 sm:col-span-2 sm:mt-2"},[_c('div',{staticClass:"max-w-xs rounded-md shadow-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                        _vm.pickupSettings.maxFutureDays
                                                    ),expression:"\n                                                        pickupSettings.maxFutureDays\n                                                    "}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",class:{
                                                        'border-red-300':
                                                            _vm.$v
                                                                .pickupSettings
                                                                .maxFutureDays
                                                                .$error
                                                    },attrs:{"type":"number","min":"0","placeholder":"Unlimited","id":"maxFutureDays"},domProps:{"value":(
                                                        _vm.pickupSettings.maxFutureDays
                                                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pickupSettings, "maxFutureDays", $event.target.value)}}})]),(
                                                    _vm.$v.pickupSettings
                                                        .maxFutureDays
                                                        .$error
                                                )?_c('span',{staticClass:"mt-1 text-xs text-red-300"},[_vm._v(" Must be 1 or greater ")]):_vm._e()])]),_c('div',{staticClass:"sm:col-span-6 md:pl-2",class:{
                                            'pointer-events-none cursor-not-allowed opacity-50': !_vm.acceptsPreOrders
                                        }},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",attrs:{"for":"prepTimeInDays"}},[_vm._v(" Order cut-off ")]),_c('p',{staticClass:"mt-1 text-sm leading-5 text-gray-500"},[_vm._v(" Enter a number of days you'll need to prepare for pickup. Enter 0 for same day pickup. ")]),_c('div',{staticClass:"mt-1 sm:col-span-2 sm:mt-2"},[_c('div',{staticClass:"max-w-xs rounded-md shadow-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                        _vm.pickupSettings.prepTimeInDays
                                                    ),expression:"\n                                                        pickupSettings.prepTimeInDays\n                                                    "}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",class:{
                                                        'border-red-300':
                                                            _vm.$v
                                                                .pickupSettings
                                                                .prepTimeInDays
                                                                .$error
                                                    },attrs:{"type":"number","placeholder":"","id":"prepTimeInDays"},domProps:{"value":(
                                                        _vm.pickupSettings.prepTimeInDays
                                                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pickupSettings, "prepTimeInDays", $event.target.value)}}})])])])])])])])])])]),_c('BaseSpacerWithRuler'),_c('div',[_c('div',{staticClass:"mt-4 md:grid md:grid-cols-3 md:gap-6"},[_vm._m(2),(!_vm.isLoading)?_c('div',{staticClass:"mt-5 md:col-span-2 md:mt-0",class:{
                        'pointer-events-none': !_vm.acceptsPreOrders
                    }},[_c('div',{staticClass:"grid grid-cols-6 gap-3"},[_c('div',{staticClass:"col-span-6 mb-2 rounded-lg bg-white px-4 py-5 shadow sm:rounded-md sm:p-5"},[_c('pickup-times',{attrs:{"acceptsPreOrders":_vm.acceptsPreOrders,"pickupSlots":_vm.pickupSlots,"hoursDict":_vm.hoursDict,"END_OF_THE_DAY":_vm.END_OF_THE_DAY,"validationError":!!_vm.errors && _vm.$v.pickupSlots.$invalid},on:{"editedPickupTimes":_vm.editedPickupTimes}})],1)])]):_vm._e()])]),_c('BaseSpacerWithRuler'),_c('div',[_c('div',{staticClass:"mt-4 md:grid md:grid-cols-3 md:gap-6"},[_vm._m(3),_c('div',{staticClass:"mt-5 md:col-span-2 md:mt-0"},[_c('div',{staticClass:"grid grid-cols-6 gap-3"},[_c('div',{staticClass:"col-span-6 mb-2 rounded-lg bg-white px-4 py-5 shadow sm:rounded-md sm:p-5"},[_c('dates-selector',{attrs:{"dates":_vm.preOrderDates.pickupDates,"maxDates":365},on:{"selected":_vm.updatePreOrderDates}})],1)])])])]),_c('BaseSpacerWithRuler')],1):_vm._e(),(_vm.selectedTab === 'delivery')?_c('div',[_c('div',[_c('div',{staticClass:"mt-4 md:grid md:grid-cols-3 md:gap-6"},[_vm._m(4),_c('div',{staticClass:"mt-5 md:col-span-2 md:mt-0"},[_c('div',{staticClass:"grid grid-cols-6 gap-3"},[_c('div',{staticClass:"col-span-6 mb-2 rounded-lg bg-white px-4 py-5 shadow sm:rounded-md sm:p-5"},[_c('div',{staticClass:"mt-5 md:col-span-2 md:mt-0"},[_c('div',{staticClass:"grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6"},[_c('div',{staticClass:"sm:col-span-6 md:pr-2",class:{
                                            'pointer-events-none cursor-not-allowed opacity-50': !_vm.acceptsPreOrders
                                        }},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",attrs:{"for":"slotDuration"}},[_vm._v(" Default time slot length ")]),_c('p',{staticClass:"mt-1 text-sm leading-5 text-gray-500"},[_vm._v(" Split up the day into multiple time slots customers can choose from when they order. ")]),_c('div',{staticClass:"mt-1 sm:col-span-2 sm:mt-2"},[_c('div',{staticClass:"max-w-xs rounded-md shadow-sm"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(
                                                        _vm.defaultSlotSettings
                                                            .delivery
                                                            .slotInterval
                                                    ),expression:"\n                                                        defaultSlotSettings\n                                                            .delivery\n                                                            .slotInterval\n                                                    "}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",attrs:{"id":"slotDuration"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.defaultSlotSettings
                                                            .delivery
                                                            , "slotInterval", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.slotDurations),function(duration){return _c('option',{key:duration,domProps:{"value":duration}},[_vm._v(_vm._s(duration)+" Minutes")])}),0)])])]),_c('div',{staticClass:"sm:col-span-6 md:pl-2",class:{
                                            'pointer-events-none cursor-not-allowed opacity-50': !_vm.acceptsPreOrders
                                        }},[_c('div',{staticClass:"block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"},[_vm._v(" Default max orders per slot ")]),_c('p',{staticClass:"mt-1 text-sm leading-5 text-gray-500"},[_vm._v(" Limit the number of orders you allow per time slot. Leave empty for unlimited orders per time slot. ")]),_c('div',{staticClass:"mt-1 sm:col-span-2 sm:mt-2"},[_c('div',{staticClass:"max-w-xs rounded-md shadow-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                        _vm.defaultSlotSettings
                                                            .delivery
                                                            .slotQuantity
                                                    ),expression:"\n                                                        defaultSlotSettings\n                                                            .delivery\n                                                            .slotQuantity\n                                                    "}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",attrs:{"type":"number","placeholder":"Unlimited"},domProps:{"value":(
                                                        _vm.defaultSlotSettings
                                                            .delivery
                                                            .slotQuantity
                                                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.defaultSlotSettings
                                                            .delivery
                                                            , "slotQuantity", $event.target.value)}}})])])]),_c('div',{staticClass:"sm:col-span-6 md:pl-2",class:{
                                            'pointer-events-none cursor-not-allowed opacity-50': !_vm.slotSettings.acceptsPreOrders
                                        }},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",attrs:{"for":"pickupSettingsAsapEnabled"}},[_vm._v(" ASAP Order ")]),_c('div',{staticClass:"mt-1 sm:col-span-2 sm:mt-2"},[_c('div',{staticClass:"mt-3 flex"},[_c('toggle-switch',{attrs:{"id":"pickupSettingsAsapEnabled"},model:{value:(
                                                        _vm.defaultSlotSettings
                                                            .delivery
                                                            .asapEnabled
                                                    ),callback:function ($$v) {_vm.$set(_vm.defaultSlotSettings
                                                            .delivery
                                                            , "asapEnabled", $$v)},expression:"\n                                                        defaultSlotSettings\n                                                            .delivery\n                                                            .asapEnabled\n                                                    "}}),_c('label',{staticClass:"ml-2 text-sm leading-5 text-gray-500"},[_vm._v(" Allow customers to order without choosing a time slot ")])],1)])]),_c('div',{staticClass:"sm:col-span-6 md:pl-2",class:{
                                            'pointer-events-none cursor-not-allowed opacity-50': !_vm.acceptsPreOrders
                                        }},[_c('div',{staticClass:"block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"},[_vm._v(" Default max items per slot ")]),_c('p',{staticClass:"mt-1 text-sm leading-5 text-gray-500"},[_vm._v(" Limit the number of items you allow per time slot. Leave empty for unlimited items per time slot. ")]),_c('div',{staticClass:"mt-1 sm:col-span-2 sm:mt-2"},[_c('div',{staticClass:"max-w-xs rounded-md shadow-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                        _vm.defaultSlotSettings
                                                            .delivery
                                                            .itemQuantity
                                                    ),expression:"\n                                                        defaultSlotSettings\n                                                            .delivery\n                                                            .itemQuantity\n                                                    "}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",attrs:{"type":"number","placeholder":"Unlimited","min":"0"},domProps:{"value":(
                                                        _vm.defaultSlotSettings
                                                            .delivery
                                                            .itemQuantity
                                                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.defaultSlotSettings
                                                            .delivery
                                                            , "itemQuantity", $event.target.value)}}})])])]),_c('div',{staticClass:"sm:col-span-6 md:pl-2",class:{
                                            'pointer-events-none cursor-not-allowed opacity-50': !_vm.acceptsPreOrders
                                        }},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",attrs:{"for":"pickupSettingsMonetaryLimit"}},[_vm._v(" Default monetary limit per slot ")]),_c('p',{staticClass:"mt-1 text-sm leading-5 text-gray-500"},[_vm._v(" Limit the total amount of orders allowed for the time slot by the sum of order totals. E.g. set a limit of £200 and customers will be prevented from ordering more within a timeslot. ")]),_c('div',{staticClass:"mt-1 sm:col-span-2 sm:mt-2"},[_c('div',{staticClass:"relative mt-1 max-w-xs rounded-md shadow-sm"},[_c('div',{staticClass:"pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"},[_c('span',{staticClass:"text-gray-500 sm:text-sm sm:leading-5"},[_vm._v(" "+_vm._s(_vm.currency.symbol)+" ")])]),_c('currency-input',{staticClass:"form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5",attrs:{"id":"pickupSettingsMonetaryLimit","placeholder":"Unlimited","currency":null,"locale":"en-GB","value-as-integer":true,"allow-negative":false},model:{value:(
                                                        _vm.defaultSlotSettings
                                                            .delivery
                                                            .monetaryLimit
                                                    ),callback:function ($$v) {_vm.$set(_vm.defaultSlotSettings
                                                            .delivery
                                                            , "monetaryLimit", $$v)},expression:"\n                                                        defaultSlotSettings\n                                                            .delivery\n                                                            .monetaryLimit\n                                                    "}})],1)])]),_c('div',{staticClass:"sm:col-span-6 md:pl-2",class:{
                                            'pointer-events-none cursor-not-allowed opacity-50': !_vm.acceptsPreOrders
                                        }},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",attrs:{"for":"deliveryMaxFutureDays"}},[_vm._v(" Limit future date selection ")]),_c('p',{staticClass:"mt-1 text-sm leading-5 text-gray-500"},[_vm._v(" How far in advance customers can place orders (in days). ")]),_c('div',{staticClass:"mt-1 sm:col-span-2 sm:mt-2"},[_c('div',{staticClass:"max-w-xs rounded-md shadow-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                        _vm.defaultSlotSettings
                                                            .delivery
                                                            .maxFutureDays
                                                    ),expression:"\n                                                        defaultSlotSettings\n                                                            .delivery\n                                                            .maxFutureDays\n                                                    "}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",class:{
                                                        'border-red-300':
                                                            _vm.$v
                                                                .defaultSlotSettings
                                                                .delivery
                                                                .maxFutureDays
                                                                .$error
                                                    },attrs:{"type":"number","min":"0","id":"deliveryMaxFutureDays"},domProps:{"value":(
                                                        _vm.defaultSlotSettings
                                                            .delivery
                                                            .maxFutureDays
                                                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.defaultSlotSettings
                                                            .delivery
                                                            , "maxFutureDays", $event.target.value)}}})]),(
                                                    _vm.$v.defaultSlotSettings
                                                        .delivery
                                                        .maxFutureDays
                                                        .$error
                                                )?_c('span',{staticClass:"mt-1 text-xs text-red-300"},[_vm._v(" Must be 1 or greater ")]):_vm._e()])]),_c('div',{staticClass:"sm:col-span-6 md:pl-2",class:{
                                            'pointer-events-none cursor-not-allowed opacity-50': !_vm.acceptsPreOrders
                                        }},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",attrs:{"for":"deliveryPrepTimeInDays"}},[_vm._v(" Order cut-off ")]),_c('p',{staticClass:"mt-1 text-sm leading-5 text-gray-500"},[_vm._v(" Enter a number of days you'll need to prepare for pickup. Enter 0 for same day pickup. ")]),_c('div',{staticClass:"mt-1 sm:col-span-2 sm:mt-2"},[_c('div',{staticClass:"max-w-xs rounded-md shadow-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                        _vm.defaultSlotSettings
                                                            .delivery
                                                            .prepTimeInDays
                                                    ),expression:"\n                                                        defaultSlotSettings\n                                                            .delivery\n                                                            .prepTimeInDays\n                                                    "}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",class:{
                                                        'border-red-300':
                                                            _vm.$v
                                                                .defaultSlotSettings
                                                                .delivery
                                                                .prepTimeInDays
                                                                .$error
                                                    },attrs:{"type":"number","placeholder":"","id":"deliveryPrepTimeInDays"},domProps:{"value":(
                                                        _vm.defaultSlotSettings
                                                            .delivery
                                                            .prepTimeInDays
                                                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.defaultSlotSettings
                                                            .delivery
                                                            , "prepTimeInDays", $event.target.value)}}})])])])])])])])])])]),_c('BaseSpacerWithRuler'),_c('div',[_c('div',{staticClass:"mt-4 md:grid md:grid-cols-3 md:gap-6"},[_vm._m(5),(!_vm.isLoading)?_c('div',{staticClass:"mt-5 md:col-span-2 md:mt-0",class:{
                        'pointer-events-none': !_vm.acceptsPreOrders
                    }},[_c('div',{staticClass:"grid grid-cols-6 gap-3"},[_c('div',{staticClass:"col-span-6 mb-2 rounded-lg bg-white px-4 py-5 shadow sm:rounded-md sm:p-5"},[_c('preorder-times',{attrs:{"acceptsPreOrders":_vm.acceptsPreOrders,"pickupSlots":_vm.deliverySlots,"hoursDict":_vm.hoursDict,"END_OF_THE_DAY":_vm.END_OF_THE_DAY,"validationError":!!_vm.errors && _vm.$v.deliverySlots.$invalid,"defaultSettings":_vm.defaultSlotSettings.delivery},on:{"editedPickupTimes":_vm.editedDeliveryTimes}})],1)])]):_vm._e()])]),_c('BaseSpacerWithRuler'),_c('div',[_c('div',{staticClass:"mt-4 md:grid md:grid-cols-3 md:gap-6"},[_vm._m(6),_c('div',{staticClass:"mt-5 md:col-span-2 md:mt-0"},[_c('div',{staticClass:"grid grid-cols-6 gap-3"},[_c('div',{staticClass:"col-span-6 mb-2 rounded-lg bg-white px-4 py-5 shadow sm:rounded-md sm:p-5"},[_c('dates-selector',{attrs:{"dates":_vm.preOrderDates.deliveryDates,"maxDates":365},on:{"selected":_vm.updatePreOrderDates}})],1)])])])]),_c('BaseSpacerWithRuler')],1):_vm._e(),_c('div',{staticClass:"my-6 ml-4 flex flex-shrink-0 flex-col items-end justify-end"},[(!!_vm.errors)?_c('div',{staticClass:"block"},[_c('p',{staticClass:"mb-2 text-sm text-red-400"},[_vm._v(" Please add opening times for each day ")])]):_vm._e(),_c('span',{staticClass:"inline-flex rounded-md shadow-sm",on:{"click":_vm.saveSlots}},[_c('button',{staticClass:"focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700",attrs:{"type":"button","disabled":_vm.isLoading}},[_vm._v(" Save ")])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:col-span-1"},[_c('div',{staticClass:"sm:pl-0 sm:pr-5"},[_c('h3',{staticClass:"text-base font-semibold leading-6 text-gray-800"},[_vm._v("Pre-Orders")]),_c('p',{staticClass:"mt-2 text-sm text-gray-500"},[_vm._v(" Enable to allow customers to order in advance. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:col-span-1"},[_c('div',{staticClass:"sm:pl-0 sm:pr-5"},[_c('h3',{staticClass:"text-base font-semibold leading-6 text-gray-800"},[_vm._v("Pickup Details")]),_c('p',{staticClass:"mt-2 text-sm text-gray-500"},[_vm._v(" Configure your how you'd like to receive pre-orders ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:col-span-1"},[_c('div',{staticClass:"sm:pl-0 sm:pr-5"},[_c('h3',{staticClass:"text-base font-semibold leading-6 text-gray-800"},[_vm._v("Pickup times")]),_c('p',{staticClass:"mt-2 text-sm text-gray-500"},[_vm._v(" Choose which days and times customers can order pickups for. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:col-span-1"},[_c('div',{staticClass:"sm:pl-0 sm:pr-5"},[_c('h3',{staticClass:"text-base font-semibold leading-6 text-gray-800"},[_vm._v("Specific pre-order days")]),_c('p',{staticClass:"mt-2 text-sm text-gray-500"},[_vm._v(" Choose specific dates for pre-orders. These values will override normal weekdays, but inherit the same slot settings (time period, capacity etc) ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:col-span-1"},[_c('div',{staticClass:"sm:pl-0 sm:pr-5"},[_c('h3',{staticClass:"text-base font-semibold leading-6 text-gray-800"},[_vm._v("Delivery pre-order details")]),_c('p',{staticClass:"mt-2 text-sm text-gray-500"},[_vm._v(" Configure your how you'd like to receive pre-orders ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:col-span-1"},[_c('div',{staticClass:"sm:pl-0 sm:pr-5"},[_c('h3',{staticClass:"text-base font-semibold leading-6 text-gray-800"},[_vm._v("Delivery times")]),_c('p',{staticClass:"mt-2 text-sm text-gray-500"},[_vm._v(" Choose which days and times customers can order pickups for. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:col-span-1"},[_c('div',{staticClass:"sm:pl-0 sm:pr-5"},[_c('h3',{staticClass:"text-base font-semibold leading-6 text-gray-800"},[_vm._v("Specific pre-order days")]),_c('p',{staticClass:"mt-2 text-sm text-gray-500"},[_vm._v(" Choose specific dates for pre-orders instead of using standard weekly days. ")])])])
}]

export { render, staticRenderFns }