<template>
    <div class="flex w-full flex-col">
        <label
            class="pl-1 text-xs font-medium leading-5 tracking-wide text-gray-700"
        >
            {{ title }}
        </label>
        <div
            v-if="hasInitValue && !isEditMode"
            class="mt-1 flex w-full flex-col justify-center gap-4 rounded-md border border-gray-300 bg-gray-50 px-6 py-4"
        >
            <font-preview
                :font="font"
                :text="title"
                class="text-lg"
                :class="upperCase ? 'uppercase' : 'lowercase'"
            />

            <div class="flex w-full justify-center gap-2">
                <base-button
                    button-text="Remove"
                    size="xs"
                    @clicked="onFontRemoved"
                />

                <base-button
                    button-text="Change"
                    size="xs"
                    @clicked="editFont"
                />
            </div>
        </div>
        <template v-else>
            <button
                v-if="hasInitValue"
                class="px-2 py-1 text-right text-sm text-blue-700 hover:text-blue-500"
                @click="onCancelFontEdit"
            >
                Cancel
            </button>

            <uploadcare-inline
                class="w-full"
                style="min-height: 142px"
                :ctxName="ctxName"
                inputAcceptTypes=".woff,.woff2"
                @success="onFontSuccess"
                @files-removed="restoreFont"
            />
        </template>
        <div
            v-if="!isTransformDisabled"
            class="mt-2 flex w-full items-center justify-end gap-2"
        >
            <span class="text-xs text-gray-700">UPPERCASE</span>
            <base-small-toggle-switch v-model="upperCase" @input="onSwitch" />
        </div>
    </div>
</template>

<script>
import FontPreview from '@/components/storeDesigner/FontPreview';
import UploadcareInline from '@/components/widgets/UploadcareInline';

export default {
    props: {
        value: {
            type: [String, null],
            required: false,
            default: null
        },
        title: {
            type: String,
            required: true
        },
        isEditMode: {
            type: Boolean,
            required: true
        },
        font: {
            type: Object,
            required: true
        },
        ctxName: {
            type: String,
            required: true
        },
        keyName: {
            type: String,
            required: true
        },
        hasInitValue: {
            type: Boolean,
            required: true
        },
        isTransformDisabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    components: {
        FontPreview,
        UploadcareInline
    },

    data() {
        return {
            upperCase: !!this.value
        };
    },

    computed: {
        key() {
            return {
                font: `${this.keyName}Font`,
                text: `${this.keyName}TextTransform`
            };
        }
    },

    methods: {
        editFont() {
            this.$emit('edit');
        },

        onCancelFontEdit() {
            this.$emit('cancel-edit', this.key.font);
        },

        restoreFont() {
            this.$emit('restore-font', `${this.keyName}Font`);
        },

        onFontRemoved() {
            this.$emit('font-removed', this.key.font);
        },

        onFontSuccess(event) {
            this.$emit('success', this.key.font, event);
        },

        onSwitch() {
            const value = this.upperCase ? 'uppercase' : null;

            this.$emit('input', value);
            this.$emit('transform', this.key.text, value);
        }
    }
};
</script>
