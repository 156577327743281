import { render, staticRenderFns } from "./DeleteTableAreaModal.vue?vue&type=template&id=6319d961&scoped=true"
import script from "./DeleteTableAreaModal.vue?vue&type=script&lang=js"
export * from "./DeleteTableAreaModal.vue?vue&type=script&lang=js"
import style0 from "./DeleteTableAreaModal.vue?vue&type=style&index=0&id=6319d961&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6319d961",
  null
  
)

export default component.exports