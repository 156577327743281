<template>
    <div
        :class="{ 'h-40 flex-col': changeable, 'h-24': selectable }"
        class="relative flex items-center overflow-hidden"
    >
        <div
            class="group relative h-full overflow-hidden"
            :class="{
                'w-full border-b': changeable,
                'w-2/5 rounded-l': selectable
            }"
        >
            <img
                :src="staticMapUrl"
                loading="lazy"
                class="size-full object-cover"
            />
            <div
                v-if="editable"
                class="absolute inset-0 hidden size-full items-center justify-center bg-gray-100 bg-opacity-10 opacity-0 backdrop-blur-0 backdrop-filter transition-all duration-300 ease-in-out group-hover:flex group-hover:opacity-100 group-hover:backdrop-blur-sm"
            >
                <base-button
                    size="xs"
                    button-text="Edit"
                    @clicked="$emit('edit')"
                />
            </div>
        </div>

        <div
            class="flex flex-1 flex-col items-start gap-1 p-2 text-sm"
            :class="{ 'w-3/5': selectable }"
        >
            <h3
                class="font-bold"
                :class="[
                    { 'leading-8': changeable, 'pl-1': editable },
                    selected ? 'text-indigo-700' : 'text-gray-900'
                ]"
            >
                {{ name }}
            </h3>
            <p
                :class="[
                    selected ? 'text-indigo-600' : 'text-gray-700',
                    {
                        'max-w-full truncate rounded-md border border-gray-300 bg-gray-100/75 px-2 py-1 text-center text-xs': editable
                    }
                ]"
            >
                {{ description }}
            </p>
        </div>
        <div
            v-if="changeable"
            class="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-10 opacity-0 backdrop-blur-0 backdrop-filter transition duration-150 ease-in-out hover:opacity-100 hover:backdrop-blur-sm"
        >
            <base-button
                size="xs"
                button-text="Change"
                @clicked="$emit('show-modal')"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        changeable: {
            type: Boolean,
            required: false,
            default: false
        },
        selectable: {
            type: Boolean,
            required: false,
            default: false
        },
        editable: {
            type: Boolean,
            required: false,
            default: false
        },
        selected: {
            type: Boolean,
            required: false,
            default: false
        },
        mapStyle: {
            type: String,
            required: false,
            default: 'mapbox://styles/benlarceysk/ck8enq76w032w1itcbgkkkrwc'
        },
        coordinates: {
            type: Array,
            required: false,
            default: () => [-0.1381, 51.51]
        },
        description: {
            type: String,
            required: false,
            default: ''
        },
        name: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            accessToken:
                'pk.eyJ1IjoiYmVubGFyY2V5c2siLCJhIjoiY2pycXZnOHp5MDRuMDQ0cHJ4ZWttMGxsYSJ9.MQY0Fnhodssbf7FMG0EiHw',
            mapbox: null
        };
    },
    computed: {
        staticMapUrl() {
            const [mapStyleId, nickname] = this.mapStyle.split('/').reverse();
            const long = this.coordinates[0];
            const lat = this.coordinates[1];

            return `https://api.mapbox.com/styles/v1/${nickname}/${mapStyleId}/static/${long},${lat},${14}/${300}x${150}?access_token=${
                this.accessToken
            }&attribution=false&logo=false`;
        }
    }
};
</script>
