<template>
    <span :style="isFont && dynamicStyle" :class="{ spinner: isLoading }">
        {{ text }}
    </span>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true
        },
        font: {
            type: [Object, null],
            required: false,
            default: null
        }
    },

    data() {
        return {
            isLoading: false
        };
    },

    computed: {
        dynamicStyle() {
            return {
                fontFamily: this.font.name
            };
        },
        isFont() {
            return !!(this.font?.url && this.font?.name);
        }
    },

    watch: {
        isFont: {
            handler(val) {
                if (!val) {
                    return;
                }

                this.loadFont();
            },
            immediate: true
        },
        font: {
            deep: true,
            handler() {
                if (this.isFont) {
                    this.loadFont();
                }
            }
        }
    },

    created() {
        this.loadFont();
    },

    methods: {
        async loadFont() {
            if (!this.isFont) {
                return;
            }

            const font = new FontFace(this.font.name, `url(${this.font.url})`);

            try {
                this.isLoading = true;

                await font.load();

                document.fonts.add(font);
            } catch (err) {
                window.alert(err);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
