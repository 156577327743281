<template>
    <div class="relative">
        <div class="fixed w-full items-center  mt-4  z-50 left-0 top-0 px-4 ">
          <div class="px-4 glass bg-white">
            <div class="py-2">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex inline-flex items-center text-sm text-gray-500"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="Delivery Zones" />
            </div>
            <div class="absolute left-4 sm:left-auto sm:right-6 top-24 sm:top-4 flex" style="z-index: 99999">
                <base-button
                    button-text="Check Address Zone"
                    class="mr-2 mb-2 sm:mb-0"
                    size="sm"
                    @clicked="showCheckAddressZoneModal"
                />
                <venue-selector
                    v-if="availableVenues.length"
                    :venues="availableVenues"
                    :key="availableVenues ? availableVenues.length : 0"
                    :multiple-select="true"
                    :allow-empty="true"
                    :on-close="false"
                    size="sm"
                    placeholder="Compare with other stores"
                    @selected="onVenueChange"
                />
            </div>

          </div>
        </div>
        <div class="fixed w-full h-full top-0 left-0">
            <div ref="map" class="my-auto h-screen overflow-x-hidden"> </div>
            <div
                style="max-height: 95%"
                class="absolute right-0 sm:right-10 bottom-0 sm:bottom-auto sm:top-28 z-10 w-full sm:w-80 transform rounded-t-lg sm:rounded-lg bg-white sm:shadow-xl transition-all"
            >
                <div
                    v-if="!isAddMode && !isEditMode"
                    class="pt-2 text-center text-base font-heading"
                >
                    <span v-if="areZonesLoading">Loading delivery zones</span>
                    <span v-else-if="hasZones">All delivery zones</span>
                    <span v-else>No delivery zones yet</span>
                </div>

                <delivery-zone-list
                    v-if="!isAddMode && !isEditMode"
                    :zones="zones"
                    :venue="venue"
                    :are-zones-loading="areZonesLoading"
                    @toggle-add-mode="toggleAddMode"
                    @toggle-edit-mode="toggleEditMode"
                    @delete-zone="deleteZone"
                    @change-zone-opacity="changeZoneOpacity"
                />

                <create-edit-delivery-zone
                    v-else
                    :zones="zones"
                    :selected-tab="selectedTab"
                    :formData="formData"
                    :is-edit-mode="isEditMode"
                    :map="map"
                    @select-tab="selectTab"
                    @back="back"
                    @update="updateDeliveryZones"
                    @delete-zone="deleteZone"
                    @remove="removeZoneLayer"
                />
            </div>
        </div>
        <check-address-zone-modal @place-marker="placeCheckAddressMarker" />
    </div>
</template>
<script>
import mapboxgl from 'mapbox-gl';
import { FeaturesEnum } from '@/enums';
import { mapGetters, mapActions } from 'vuex';
import DeliveryZoneList from '@/components/DeliveryZoneList';
import VenueSelector from '@/components/selectors/VenueSelector';
import CreateEditDeliveryZone from '@/components/CreateEditDeliveryZone';
import CheckAddressZoneModal from '@/components/modals/CheckAddressZoneModal';

export default {
    name: 'DeliveryZonesSettings',
    props: ['venueId'],
    components: {
        DeliveryZoneList,
        CreateEditDeliveryZone,
        VenueSelector,
        CheckAddressZoneModal
    },
    metaInfo: {
        title: 'Delivery Zone Settings'
    },
    data() {
        const defaultFormData = {
            name: '',
            radius: '',
            deliveryFee: null,
            minDeliveryAmount: 0,
            postCodes: []
        };

        const colors = [
            'red',
            'blue',
            'green',
            'orange',
            'red',
            'yellow',
            'purple'
        ];

        return {
            zones: {},
            isLoading: true,
            areZonesLoading: true,
            selectedTab: 'drivingDistanceZones',
            FeaturesEnum,
            isAddMode: false,
            isEditMode: false,
            formData: { ...defaultFormData },
            zone: {},
            venueIds: [],
            availableVenues: [],
            venueColors: {},
            colors,
            markers: []
        };
    },
    async created() {
        if (!this.venues?.length > 0) {
            await this.fetchVenues();
        }

        this.availableVenues = this.venues.filter(
            venue =>
                !venue.acceptsInStore &&
                venue.acceptsDelivery &&
                venue.id !== this.venueId
        );

        this.assignVenueColors();
    },
    async mounted() {
        this.initMap();

        await this.fetchZones(true);
    },
    watch: {
        zone() {
            this.fillFormData();
        }
    },
    computed: {
        ...mapGetters({
            venue: 'venues/getVenue',
            isFeatureAvailable: 'user/isFeatureAvailable',
            venues: 'venues/getVenues'
        }),
        hasZones() {
            if (
                this.zones.drivingDistanceZones?.length > 0 ||
                this.zones.polygonZones?.length > 0 ||
                this.zones.circleZones?.length > 0
            ) {
                return true;
            }

            return false;
        }
    },
    beforeDestroy() {
        this.map ? this.map.remove(): null;
        this.map = null;
    },
    methods: {
        ...mapActions({
            fetchVenues: 'venues/fetch'
        }),
        back() {
            this.isAddMode = false;
            this.isEditMode = false;
            this.zone = {};
        },
        fillFormData() {
            this.formData = this.zone
                ? { ...this.zone }
                : { ...this.defaultFormData };
        },
        toggleAddMode() {
            this.formData = { ...this.defaultFormData };
            this.isAddMode = !this.isAddMode;
            this.removeCheckAddressMarker();
        },
        toggleEditMode(zone, tab) {
            this.zone = zone;
            this.selectTab(tab);
            this.isEditMode = !this.isEditMode;
            this.removeCheckAddressMarker();
        },
        initMap() {
            if (!this.$refs.map) {
                return;
            }

            mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;
            this.map = new mapboxgl.Map({
                container: this.$refs.map,
                style: 'mapbox://styles/mapbox/outdoors-v11',
                center: [this.venue.address?.lat, this.venue.address?.lng],
                zoom: 12
            });

            const marker = new mapboxgl.Marker({})
                .setLngLat([this.venue.address?.lat, this.venue.address?.lng])
                .addTo(this.map);

            this.markers.push(marker);
        },
        async fetchZones(isInitial = false) {
            this.isLoading = true;
            this.areZonesLoading = true;

            try {
                const { data } = await this.$axios.get(
                    `/venues/${this.venueId}/zones`
                );
                this.zones = data;

                if (isInitial) {
                    this.addZonesLayers(this.zones.drivingDistanceZones);
                    this.addZonesLayers(this.zones.polygonZones);
                    this.addZonesLayers(this.zones.circleZones);
                    this.addZonesLayers(this.zones.postcodeZones);
                }
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
                this.areZonesLoading = false;
            }
        },
        async updateDeliveryZones() {
            this.isLoading = true;

            this.zones.drivingDistanceZones.forEach(
                zone => (zone.polygon = null)
            );

            const allZones = [].concat(
                this.zones.drivingDistanceZones,
                this.zones.polygonZones,
                this.zones.circleZones,
                this.zones.postcodeZones
            );

            try {
                const zones = allZones.map(
                    // eslint-disable-next-line no-unused-vars
                    ({ created_at, updated_at, accountId, ...zone }) => zone
                );
                const { data } = await this.$axios.put(
                    `/venues/${this.venueId}/zones`,
                    {
                        deliveryZones: zones,
                        zoneId: this.zone?.id || null
                    }
                );

                this.zones = data;

                this.$notify({
                    group: 'settings',
                    title: 'Delivery zones updated'
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
                this.addZoneLayer();
                this.back();
            }
        },
        async deleteZone(deliveryZone, type) {
            // Optimistic render
            this.zones[type] = this.zones[type].filter(
                val => val !== deliveryZone
            );
            // If notice has not been saved to db, just return
            if (!deliveryZone.id) {
                this.$notify({
                    group: 'settings',
                    title: 'Delivery zones updated'
                });
                return;
            }
            this.isLoading = true;
            try {
                await this.$axios.delete(
                    `/venues/${this.venueId}/zones/${deliveryZone.id}`
                );
                this.$notify({
                    group: 'settings',
                    title: 'Delivery zones updated'
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
                this.isAddMode =false;
                this.isEditMode = false;
                this.removeZoneLayer(deliveryZone.id);
                await this.fetchZones();
            }
        },
        selectTab(value) {
            this.selectedTab = value;
        },
        removeZoneLayer(id) {
            if (this.map.getLayer(id)) {
              this.map.removeLayer(String(id));
              this.map.removeLayer(String(id)+'-outline');
              this.map.removeSource(String(id));
            }
        },
        addZoneLayer() {
            let zone;

            if (this.isEditMode) {
                if (
                    this.selectedTab === 'drivingDistanceZones' ||
                    this.selectedTab === 'postcodeZones'
                ) {
                    zone = this.zones[this.selectedTab].find(
                        zone => zone.id === this.zone.id
                    );
                } else {
                    zone = this.zone;
                }
            } else {
                const zones = this.zones[this.selectedTab];

                zone = zones.slice(-1)[0];
            }

            if (!zone.polygon?.coordinates) {
                this.deleteZone(zone, this.selectedTab);

                return;
            }

            if (zone?.postCodes?.length > 0) {
                this.addMultiPolygonLayer(zone);
                return;
            }

            this.map.addSource(`${zone.id}`, {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: zone.polygon.coordinates
                    }
                }
            })

            this.map.addLayer({
                id: `${zone.id}`,
                type: 'fill',
                source: `${zone.id}`,
                paint: {
                    'fill-color': 'black',
                    'fill-opacity': 0.25
                }
            });

            this.map.addLayer({
              'id': `${zone.id}-outline`,
              'type': 'line',
              'source': `${zone.id}`,
              'layout': {},
              'paint': {
                'line-color': '#000',
                'line-width': 2
              }
            });
        },
        addZonesLayers(zones, color) {
            zones.forEach(zone => {
                if (!zone.polygon?.coordinates) {
                    return;
                }

                if (zone.postCodes.length > 0) {
                    this.addMultiPolygonLayer(zone, color);
                    return;
                }

            this.map.addSource(`${zone.id}`, {
              type: 'geojson',
              data: {
                type: 'Feature',
                geometry: {
                  type: 'Polygon',
                  coordinates: zone.polygon.coordinates
                }
              }
            });

            this.map.addLayer({
              id: `${zone.id}`,
              type: 'fill',
              source: `${zone.id}`,
              paint: {
                'fill-color': color || 'black',
                'fill-opacity': 0.25
              }
            });

            this.map.addLayer({
              'id': `${zone.id}-outline`,
              'type': 'line',
              'source': `${zone.id}`,
              'layout': {},
              'paint': {
                'line-color': color || '#000',
                'line-width': 2
              }
            });
          });
        },
        changeZoneOpacity(id, opacity) {
            if (this.map.getLayer(id)) {
                this.map.setPaintProperty(String(id), 'fill-opacity', opacity);
            }
        },
        async onVenueChange(ids) {
            if (!ids) {
                if (this.venueIds?.length === 1) {
                    await this.removeVenueLayer(this.venueIds[0]);
                    this.venueIds = [];
                }
                return;
            }

            const idToAdd = ids.find(id => !this.venueIds.includes(id));
            const idToRemove = this.venueIds.find(id => !ids.includes(id));

            if (idToAdd) {
                await this.addVenueLayer(idToAdd);
            }

            if (idToRemove) {
                await this.removeVenueLayer(idToRemove);
            }

            this.venueIds = ids;
        },
        async addVenueLayer(id) {
            try {
                const { data } = await this.$axios.get(`/venues/${id}/zones`);

                this.addZonesLayers(
                    data.drivingDistanceZones,
                    this.venueColors[id]
                );
                this.addZonesLayers(data.circleZones, this.venueColors[id]);
                this.addZonesLayers(data.polygonZones, this.venueColors[id]);
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async removeVenueLayer(id) {
            try {
                const { data } = await this.$axios.get(`/venues/${id}/zones`);

                data.drivingDistanceZones.forEach(zone =>
                    this.removeZoneLayer(zone.id)
                );
                data.circleZones.forEach(zone => this.removeZoneLayer(zone.id));
                data.polygonZones.forEach(zone =>
                    this.removeZoneLayer(zone.id)
                );
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        assignVenueColors() {
            this.availableVenues.forEach((venue, index) => {
                const colorIndex = index % this.colors.length;
                const color = this.colors[colorIndex];

                this.$set(this.venueColors, venue.id, color);
            });
        },
        addMultiPolygonLayer(zone, color) {
            const features = zone.polygon.coordinates[0].map(
                (polygon, index) => {
                    return {
                        type: 'Feature',
                        geometry: {
                            type: 'Polygon',
                            coordinates: [polygon]
                        },
                        properties: {
                            postcode: zone.postCodes[index]
                        }
                    };
                }
            );

            this.map.addSource(`${zone.id}`, {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: features
                }
            });

            this.map.addLayer({
                id: `${zone.id}`,
                type: 'fill',
                source: `${zone.id}`,
                paint: {
                    'fill-color': color || 'black',
                    'fill-opacity': 0.25
                }
            });

            this.map.addLayer({
                id: `${zone.id}-outline`,
                type: 'line',
                source: `${zone.id}`,
                layout: {},
                paint: {
                    'line-color': color || '#000',
                    'line-width': 2
                }
            });
        },
        showCheckAddressZoneModal() {
            this.removeCheckAddressMarker();
            this.$modal.show('check-address-zone-modal');
        },
        removeCheckAddressMarker() {
            if (this.markers.length > 1) {
                const marker = this.markers.pop();
                marker.remove();
                if (this.map.getLayer('line')) {
                    this.map.removeLayer('line');
                    this.map.removeSource('line');
                }
            }
        },
        placeCheckAddressMarker(coords) {
            if (!coords?.lat || !coords?.lng) {
                return;
            }

            this.markers.push(
                new mapboxgl.Marker()
                    .setLngLat([coords.lng, coords.lat])
                    .addTo(this.map)
            );

            this.drawLinesBetweenMarkers(coords);
        },
        drawLinesBetweenMarkers(coords) {
            this.map.addSource('line', {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: [
                            [this.venue.address?.lat, this.venue.address?.lng],
                            [coords.lng, coords.lat]
                        ]
                    }
                }
            });

            this.map.addLayer({
                id: 'line',
                type: 'line',
                source: 'line',
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                paint: {
                    'line-color': '#FF0000',
                    'line-width': 3
                }
            });
        }
    }
};
</script>
