<template>
    <div ref="QrCodeWrapper" class="flex justify-center"></div>
</template>

<script>
import QRCodeStyling from 'qr-code-styling';

export default {
    name: 'QrCode',
    props: {
        url: {
            type: String,
            required: true
        },
        fileName: {
            type: String,
            default: 'qr-code'
        }
    },
    watch: {
        url(value) {
            this.options.data = value;
            this.qrCode.update(this.options);
        }
    },
    methods: {
        download(type) {
            this.qrCode.download({
                name: this.fileName,
                extension: ['svg', 'png'].includes(type) ? type : 'svg'
            });
        }
    },
    data() {
        const options = {
            width: 300,
            height: 300,
            type: 'canvas',
            data: this.url,
            image:
                'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjUgMTExIj48ZGVmcz48c3R5bGU+LmJjMzU1N2I5LTVmNjgtNDgyNC1hNTk3LTAzY2EzYzZiYzhjY3tmaWxsOiMyOTJhMzE7fS5hY2FkZWY4My03ZWZlLTQ2YzEtOTNkOS03NWJiYWI0ZWZiMmZ7ZmlsbDojZmZmZmZmO308L3N0eWxlPjwvZGVmcz48ZyBpZD0iZmM5YTAwYmUtNTdkMC00MDMyLTlmYTEtMTdiY2E0OGIwY2EzIiBkYXRhLW5hbWU9IkxheWVyIDIiPjxnIGlkPSJiODNhZDYwZC04MGZkLTQ4YjktYmMyZC1lMWM3MDIzMjY0NDUiIGRhdGEtbmFtZT0iTGF5ZXIgMSI+PHBhdGggY2xhc3M9ImJjMzU1N2I5LTVmNjgtNDgyNC1hNTk3LTAzY2EzYzZiYzhjYyIgZD0iTTExMy41MiwwSDMwLjc2QTExLjQ5LDExLjQ5LDAsMCwwLDE5LjUxLDkuMjhMLjIyLDk3LjIxQTExLjUyLDExLjUyLDAsMCwwLDExLjQ4LDExMUg5NC4yNGExMS40OSwxMS40OSwwLDAsMCwxMS4yNS05LjI4bDE5LjI5LTg3LjkzQTExLjUyLDExLjUyLDAsMCwwLDExMy41MiwwWiIvPjxwYXRoIGNsYXNzPSJhY2FkZWY4My03ZWZlLTQ2YzEtOTNkOS03NWJiYWI0ZWZiMmYiIGQ9Ik01Ny40MSw0My43M2MwLDIuNSwxLjQyLDQsNS41OCw1LjEyTDczLjUsNTEuNTdDODAuNzIsNTMuNDMsODUsNTcuNzksODUsNjQuNDQsODUsNzYuMjIsNzQsODMuMyw2MS4xMyw4My4zUzM4LjA1LDc3LjUyLDM3LjUsNjYuNjJMNTEuMDcsNjMuOWMtLjIyLDYuMzIsNC4xNSw4LjgzLDExLjQ4LDguODMsNC45MywwLDgtMS44Niw4LTYsMC0yLjQtMS42NC0zLjgyLTYuNjctNS4xMmwtOC41NC0yLjRjLTcuNjYtMi4wNy0xMi4yNS02LjU0LTEyLjI1LTEzLjMsMC0xMS4zNCw5LjMtMTguMjEsMjEuNTUtMTguMjEsMTIuNjksMCwyMi4yMSw0LjU4LDIyLjg3LDE2TDc0LjkyLDQ2Yy4xMS02LjEtMy44My04LjI4LTEwLjA3LTguMjhDNTkuODIsMzcuNzMsNTcuNDEsNDAuMTMsNTcuNDEsNDMuNzNaIi8+PC9nPjwvZz48L3N2Zz4=',
            dotsOptions: {
                color: '#292a31'
            },
            backgroundOptions: {
                color: '#ffffff00'
            },
            imageOptions: {
                margin: 10
            }
        };

        const qrCode = new QRCodeStyling(options);

        return {
            qrCode,
            options
        };
    },
    mounted() {
        this.qrCode.append(this.$refs.QrCodeWrapper);
    }
};
</script>
