var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex w-0 flex-1 flex-col overflow-hidden"},[_c('base-toggle-sidebar-button'),_c('main',{staticClass:"focus:outline-none relative z-0 flex-1 overflow-y-auto pb-6 pt-2 md:py-6"},[_c('div',{staticClass:"mx-auto flex flex-col px-4 sm:px-6 md:flex-row md:items-center md:justify-between lg:px-8"},[_c('BasePageTitle',{attrs:{"title":_vm.$t('views.allOrders.title')}}),_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"mr-2 mt-1 inline-flex sm:mt-0"},[_c('export-button',{attrs:{"count":this.selectedRows ? this.selectedRows.length : false},on:{"clicked":_vm.showOrderExportModal}})],1),_c('div',{staticClass:"mr-5 inline-flex flex-row items-center rounded-md border border-gray-300 bg-white px-3 py-1.5 shadow-sm md:mb-0"},[_c('span',{staticClass:"mr-3 text-xs font-semibold text-gray-700"},[_vm._v(_vm._s(_vm.$t('views.allOrders.groupByDate'))+" ")]),_c('base-small-toggle-switch',{attrs:{"value":_vm.userSettings.byDate},on:{"input":function($event){return _vm.toggleGroupByDate()}}})],1)]),_c('div',{staticClass:"flex flex-col md:flex-row"},[_c('div',{staticClass:"mt-3 h-4 w-4",class:{
                            'text-gray-900 spinner': _vm.isLoading
                        }},[_c('span')])])])],1),_c('div',{staticClass:"mx-auto sm:px-6 md:px-8"},[_c('div',{staticClass:"py-4"},[_c('div',{staticClass:"rounded-lg bg-white shadow"},[_c('div',[_c('div',{staticClass:"flex flex-col justify-between px-2 py-2.5 sm:flex-row sm:px-6"},[_c('ul',{staticClass:"mb-2 flex flex-row items-center sm:mb-0"},_vm._l((_vm.dateFilters),function(filter){return _c('li',{key:filter.key,staticClass:"mr-1 cursor-pointer rounded-md px-3 py-1 text-xs transition duration-150 ease-in-out hover:bg-gray-50",class:{
                                        'bg-gray-100':
                                            filter.key === _vm.selectedFilter
                                    },on:{"click":function($event){return _vm.applyDateFilter(filter.key)}}},[_vm._v(" "+_vm._s(filter.label)+" ")])}),0),(_vm.selectedRows.length)?_c('div',{staticClass:"mb-3 ml-5 mr-auto inline-flex flex-col md:mb-0"},[_c('span',{staticClass:"relative z-0 inline-flex rounded-md shadow-sm"},[_c('span',{staticClass:"relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700"},[_vm._v(" "+_vm._s(_vm.selectedRows.length)+" "+_vm._s(_vm.$t('views.allOrders.selected'))+" ")]),_c('button',{staticClass:"focus:outline-none relative -ml-px inline-flex items-center border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500",attrs:{"type":"button","disabled":_vm.isLoading},on:{"click":_vm.acceptOrders}},[_vm._v(" "+_vm._s(_vm.$t('views.allOrders.accept'))+" ("+_vm._s(_vm.selectedPendingRows.length)+") ")]),_c('button',{staticClass:"focus:outline-none relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500",attrs:{"type":"button","disabled":_vm.isLoading},on:{"click":_vm.bulkPrintOrders}},[_vm._v(" "+_vm._s(_vm.$t('views.allOrders.print'))+" ("+_vm._s(_vm.selectedRows.length)+") ")])])]):_vm._e(),_c('div',{staticClass:"mr-4 flex items-center"},[_c('search-input',{staticClass:"text-gray-700 sm:mr-3",attrs:{"size":"sm","value":_vm.serverParams.search,"min":3,"max":9,"placeholder":_vm.$t('views.allOrders.searchPlaceholder')},on:{"input":_vm.searchById}}),_c('base-button',{class:_vm.selectedFilters
                                            ? 'border-indigo-600 text-indigo-600'
                                            : 'border-gray-300 text-gray-700',attrs:{"size":"sm","type":"button","hightlighted":!!_vm.selectedFilters,"button-text":_vm.selectedFilters
                                            ? `${_vm.selectedFilters} ${_vm.$t('views.allOrders.filters')}`
                                            : _vm.$t('views.allOrders.filters')},on:{"clicked":_vm.openFilterSidebar}})],1)]),(_vm.hasNewOrders)?_c('div',{staticClass:"w-full bg-indigo-900 px-4 py-2 text-sm text-white",class:{
                                'opacity-75': _vm.isLoading
                            }},[_c('span',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(_vm.hasNewOrders)+" "+_vm._s(_vm.hasNewOrders > 1 ? _vm.$t('views.allOrders.newOrdersPlural') : _vm.$t('views.allOrders.newOrders'))+", ")]),_c('span',{staticClass:"cursor-pointer underline",on:{"click":function($event){return _vm.fetchOrders()}}},[_vm._v(_vm._s(_vm.$t('views.allOrders.clickToFetch')))])]):_vm._e(),_c('vue-good-table',{ref:"vueGoodTable",staticClass:"border-b border-gray-200",attrs:{"mode":"remote","styleClass":"tailwind-table","isLoading":_vm.isLoading,"totalRows":_vm.ordersLength,"pagination-options":{
                                enabled: true,
                                perPage:
                                    parseInt(_vm.$route.query.limit) ||
                                    _vm.userSettings.limit ||
                                    _vm.serverParams.limit,
                                dropdownAllowAll: false,
                                rowsPerPageLabel: '',
                                perPageDropdown: [
                                    10,
                                    20,
                                    50,
                                    100,
                                    200,
                                    300,
                                    500,
                                    1000
                                ],
                                setCurrentPage: this.$route.query.page
                            },"select-options":{
                                enabled: true,
                                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                                selectionInfoClass: 'custom-class',
                                selectionText: 'rows selected',
                                clearSelectionText: 'clear',
                                disableSelectInfo: true // disable the select info panel on top
                            },"group-options":{
                                enabled: _vm.userSettings.byDate,
                                collapsable: false
                            },"row-style-class":"mb-1 cursor-pointer","columns":_vm.columns,"rows":_vm.userSettings.byDate ? _vm.rowsByDate : _vm.orders},on:{"on-selected-rows-change":_vm.changeSelectedRows,"on-row-click":_vm.navigateToOrder,"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(
                                        props.column.field ===
                                        'estimatedTime'
                                    )?_c('span',[(props.row.estimatedTime)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(props.row.preOrderDateTime ? `Pre-order, placed at: ${_vm.$moment(props.row.created_at).format('HH:mm - Do MMM, YY')}`: ''),expression:"props.row.preOrderDateTime ? `Pre-order, placed at: ${$moment(props.row.created_at).format('HH:mm - Do MMM, YY')}`: ''"}],staticClass:"flex items-center"},[(
                                            props.row.preOrderDateTime
                                            )?_c('base-clock-icon',{staticClass:"h-4 w-4 text-gray-800 mr-2"}):_vm._e(),(props.row.estimatedTime)?_c('time',{staticClass:"block",attrs:{"datetime":props.row.estimatedTime}},[_vm._v(" "+_vm._s(_vm._f("moment")(props.row.estimatedTime,'calendar', null, { sameDay: '[Today] [at] HH:mm', nextDay: '[Tomorrow] [at] HH:mm', nextWeek: 'dddd [at] HH:mm', lastDay: '[Yesterday] [at] HH:mm', lastWeek: '[Last] dddd [at] HH:mm', sameElse: 'Do MMM [at] HH:mm' }))+" ")]):_vm._e()],1):_c('div',[_c('time',{staticClass:"block",attrs:{"datetime":props.row.created_at}},[_vm._v(" "+_vm._s(_vm._f("moment")(props.row.created_at,'from', 'now'))+" ")]),_c('span',{staticClass:"block"},[_vm._v(" "+_vm._s(props.formattedRow[ props.column.field ])+" ")])])]):(
                                        props.column.field ===
                                        'fulfillmentMethod'
                                    )?_c('span',[_c('span',{staticClass:"flex items-center"},[(
                                            _vm.FulfillmentMethodsEnum.isDeliveryMethod(props.row
                                                    .fulfillmentMethod)
                                            )?_c('DeliveryIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Delivery Order'),expression:"'Delivery Order'"}],attrs:{"color":"text-gray-500","size":"sm"}}):(
                                            _vm.FulfillmentMethodsEnum.isPickupMethod(props.row
                                                    .fulfillmentMethod)
                                            )?_c('PickupIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Pickup Order'),expression:"'Pickup Order'"}],attrs:{"color":"text-gray-500","size":"sm"}}):_c('InstoreIcon',{attrs:{"color":"text-gray-500","size":"sm"}}),(
                                                props.row.table &&
                                                props.row.table.name
                                            )?_c('span',{staticClass:"text-center mt-1 ml-2 text-gray-500"},[_vm._v(_vm._s(props.row.table.name))]):_vm._e(),(_vm.FulfillmentMethodsEnum.isCateringMethod(props.row.fulfillmentMethod))?_c('span',{staticClass:"font-semibold text-gray-600 pl-1 uppercase text-xs"},[_vm._v(" "+_vm._s(_vm.$t('common.catering'))+" ")]):_vm._e()],1)]):(
                                        props.column.field ===
                                        'customer.firstName'
                                    )?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[(props.row.customer && props.row.customer.email)?_c('CustomerDetailsTableItem',{attrs:{"email":props.row.customer.email,"phone":props.row.customer.phone,"name":props.row.customer.firstName || props.row.customer.lastName ? `${props.row.customer.firstName || ''} ${props.row.customer.lastName || ''}`.trim() : '',"show-email":false,"show-phone":false}}):(props.row.customer)?_c('span',{staticClass:"px-4"},[_vm._v(" "+_vm._s(`${props.row.customer.firstName || '-'} ${props.row.customer.lastName || ''}`)+" ")]):_vm._e()],1):(
                                        props.column.field === 'payment'
                                    )?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('payment-details-popover',{attrs:{"payment-type":props.row.paymentType,"refunds":props.row.refunds,"total-paid":props.row.total}})],1):(
                                        props.column.field === 'total'
                                    )?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(props.row.paymentType !== 'Cash' ? 'Order paid online' : 'Order not paid online, or no balance due.'),expression:"props.row.paymentType !== 'Cash' ? 'Order paid online' : 'Order not paid online, or no balance due.'"}],staticClass:"flex items-center",on:{"click":function($event){$event.stopPropagation();}}},[_c('payment-details-popover',{attrs:{"payment-type":props.row.paymentType,"refunds":props.row.refunds,"total-paid":props.row.total}})],1):(
                                        props.column.field === 'items'
                                    )?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-dropdown',{attrs:{"distance":"16","triggers":['click'],"popperClass":"order-item-popover"},scopedSlots:_vm._u([(props.row.customer)?{key:"popper",fn:function(){return [_c('OrderItemsTooltip',{attrs:{"order-notes":props.row?.customer
                                                        .notes,"order-items":props.row.items}})]},proxy:true}:null],null,true)},[_c('div',{staticClass:"group flex select-none items-center rounded-md px-4 py-1 hover:bg-gray-100"},[_c('span',{staticClass:"mr-2 block"},[_c('span',{staticClass:"p-2 w-2 h-2 bg-gray-100 text-gray-600 font-mono font-semibold inline-flex items-center justify-center border border-gray-300 mr-1 text-xs rounded-md"},[_vm._v(" "+_vm._s(_vm.itemCount( props.row.items ))+" ")]),_vm._v(" "+_vm._s(_vm.itemCount( props.row.items ) > 1 ? _vm.$t('views.allOrders.items') : _vm.$t('views.allOrders.item'))+" ")]),_c('svg',{staticClass:"m-auto block h-4 w-4 text-gray-600 opacity-0 group-hover:opacity-100",attrs:{"viewBox":"0 0 20 20","fill":"currentColor","focusable":"false","aria-hidden":"true"}},[_c('path',{attrs:{"d":"M5 8l5 5 5-5H5z"}})])])])],1):(
                                        props.column.field === 'orderStatus'
                                    )?_c('status-badge',{attrs:{"status":props.formattedRow[props.column.field]}}):(props.column.field === 'venue.name')?_c('span',[_vm._v(" "+_vm._s(props.row.venue?.adminName || props.row.venue?.name)+" ")]):(
                                        props.column.field === 'id' &&
                                        props.row.code
                                    )?_c('span',[_c('span',{staticClass:"block font-mono font-bold"},[_vm._v(" #"+_vm._s(props.row.code)+" ")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[ props.column.field ])+" ")])]}},{key:"table-header-row",fn:function(props){return (_vm.userSettings.byDate)?[_c('div',{staticClass:"flex justify-start py-2"},[_c('span',{staticClass:"mr-2"},[_c('b',[_vm._v(_vm._s(props.row.label))])]),_c('span',{staticClass:"mr-2"},[_c('b',[_vm._v(_vm._s(_vm.formatCurrency( props.row.sum ))+" ")])]),_c('span',{staticClass:"mr-2"},[_vm._v(" Orders: "),_c('b',[_vm._v(_vm._s(props.row.count))])])])]:undefined}}],null,true)},[_c('template',{slot:"emptystate"},[_c('div',{staticClass:"flex h-40 w-full items-center justify-center bg-white"},[(
                                            !_vm.isLoading && (_vm.ordersLength === 0 || _vm.currentPageOrdersCount === 0)
                                        )?_c('h3',{staticClass:"text-sm font-medium text-gray-700"},[_vm._v(_vm._s(_vm.$t('views.allOrders.noOrdersFound')))]):_c('span',{staticClass:"spinner"})])])],2)],1)])])]),_c('order-export-modal',{on:{"exportOrders":_vm.exportOrders}})],1),_c('all-orders-filter-sidebar',{attrs:{"userSettings":_vm.userSettings,"venues":_vm.venues},on:{"setVenue":_vm.onVenueChange,"orderFilterChange":_vm.onOrderFilterChange,"dateRangeChange":_vm.onDateRangeChange,"toggleArchived":_vm.toggleArchived,"toggleRejected":_vm.toggleRejected,"clearFilters":_vm.clearFilters},model:{value:(_vm.isFilterSidebarOpen),callback:function ($$v) {_vm.isFilterSidebarOpen=$$v},expression:"isFilterSidebarOpen"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }