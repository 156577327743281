<template>
    <div>
        <button
            v-for="option in options"
            :key="option.name"
            @click="toggleFulfillment(option.name)"
            type="button"
            class="mr-2 mt-2 inline-flex items-center rounded-md border border-gray-200 px-3 py-1.5 text-sm font-medium shadow-sm transition duration-200 ease-in-out"
            :class="[
                selectedValues.includes(option.name)
                    ? 'border-black bg-black text-white'
                    : 'bg-gray-100 text-gray-900',
                { 'border-red-300 bg-red-50 text-gray-900': error }
            ]"
        >
            <component
                v-if="iconComponents[option.icon]"
                :is="iconComponents[option.icon]"
                size="sm"
            />
            <span :class="{ 'ml-2': iconComponents[option.icon] }">
                {{ option.label }}
            </span>
        </button>
        <span v-if="error" class="mt-2 block text-sm text-red-600">
            Please select one or more options
        </span>
    </div>
</template>

<script>
export default {
    name: 'MultipleButtonSelector',
    props: {
        value: {
            type: Array,
            default: () => []
        },
        error: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            required: true
        },
        allowNoOption: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            iconComponents: {}
        };
    },
    computed: {
        selectedValues: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            }
        }
    },
    created() {
        this.loadIcons();
    },
    methods: {
        async loadIcons() {
            for (const option of this.options) {
                if (option.icon) {
                    try {
                        const icon = await import(
                            `@/components/common/${option.icon}.vue`
                        );
                        this.$set(
                            this.iconComponents,
                            option.icon,
                            icon.default || icon
                        );
                    } catch (error) {
                        // eslint-disable-next-line
                    }
                }
            }
        },
        toggleFulfillment(option) {
            if (
                !this.allowNoOption &&
                this.selectedValues.length === 1 &&
                this.selectedValues.includes(option)
            ) {
                return; // Prevent the last selected method from being removed
            }

            this.selectedValues = this.selectedValues.includes(option)
                ? this.selectedValues.filter(m => m !== option)
                : [...this.selectedValues, option];
        }
    }
};
</script>
