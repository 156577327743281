<template>
    <div id="app" class="font-sans">
        <div
            class="flex h-screen overflow-hidden bg-sk-silver-grey print:h-full print:overflow-visible print:bg-white"
        >
            <div v-if="isRouteLoading" class="fixed top-0 left-0 w-full h-1 bg-sk-black animate-pulse"></div>
            <super-admin-notification
                :accountId="accountId"
                :accountName="accountName"
                v-if="
                    isFeatureAvailable(FeaturesEnum.INTERNAL) && !isWebExtension
                "
            />
            <SidebarContainer v-if="!isWebExtension" />
            <router-view />
            <api-error-modal />
            <api-unauthorized-error-modal />
            <export-modal />
            <export-csv-modal />
            <notifications
                :duration="1500"
                :max="5"
                :ignoreDuplicates="false"
                group="settings"
                position="bottom center"
                classes="toast-style font-heading"
            />
            <notifications
                :duration="1500"
                :max="1"
                :ignoreDuplicates="true"
                group="update"
                :width="notificationWidth"
                position="bottom center"
            >
                <template slot="body" slot-scope="props">
                    <div class="dashboard-update flex flex-row items-center">
                        <div class="dashboard-update-content text-gray-200"
                            >{{ props.item.text }}
                        </div>
                        <div
                            @click="reload()"
                            class="h-full cursor-pointer border-l border-blue-200 px-4 text-gray-200"
                        >
                            <span>Refresh</span>
                        </div>
                    </div>
                </template>
            </notifications>
            <notifications
                :max="1"
                :ignoreDuplicates="true"
                group="deliverect-menu-update"
                :width="notificationWidth"
                position="bottom center"
            >
                <template slot="body" slot-scope="props">
                    <div
                        class="deliverect-menu-update flex flex-row items-center"
                        @click="props.close"
                    >
                        <div
                            class="deliverect-menu-update-content text-gray-200"
                        >
                            Your menus from deliverect are updating click
                            <span
                                @click="redirectToMenu(props.item.data)"
                                class="cursor-pointer"
                            >
                                <b>here</b>
                            </span>
                            to see details
                        </div>
                    </div>
                </template>
            </notifications>

            <custom-error-modal />
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import ably from '@/helpers/ably';
import SidebarContainer from '@/components/SidebarContainer';
import ApiErrorModal from '@/components/modals/ApiErrorModal';
import ApiUnauthorizedErrorModal from '@/components/modals/ApiUnauthorizedErrorModal';
import ExportModal from '@/components/modals/ExportModal';
import CustomErrorModal from '@/components/modals/CustomErrorModal';
import SuperAdminNotification from '@/components/widgets/SuperAdminNotification';
import { FeaturesEnum } from '@/enums';
import * as Sentry from '@sentry/vue';
import ExportCsvModal from './components/modals/ExportCsvModal.vue';
import { knockFeed } from '@/helpers/knock';

export default {
    name: 'app',
    metaInfo() {
        return {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: '-',
            // all titles will be injected into this template
            titleTemplate: '%s | ' + this.appName
        };
    },
    components: {
        SuperAdminNotification,
        SidebarContainer,
        ApiErrorModal,
        ApiUnauthorizedErrorModal,
        ExportModal,
        CustomErrorModal,
        ExportCsvModal
    },
    data() {
        return {
            FeaturesEnum,
            channels: []
        };
    },
    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
            accountId: 'user/accountId',
            accountName: 'user/accountName',
            isFeatureAvailable: 'user/isFeatureAvailable',
            isRouteLoading: 'ui/isRouteLoading',
            isWebExtension: 'ui/isWebExtension',
            userId: 'user/userId'
        }),
        appName() {
            return process.env.VUE_APP_WHITELABEL_NAME;
        },
        partnerId() {
            return process.env.VUE_APP_PARTNER_ID;
        },
        notificationWidth() {
            if (window.innerWidth > 768) {
                return '450px';
            } else {
                return '350px';
            }
        },
        isEditMenuRoute() {
            return this.$route.name === 'editMenu';
        }
    },
    watch: {
        currentUser() {
            if (this.currentUser && this.currentUser.email) {
                this.$tracker.setUserID(this.currentUser.email);
                const menuImportChannel = ably.channels.get(`menu-import`);
                menuImportChannel.subscribe(
                    `menu-import-${this.currentUser.email}`,
                    ({ data }) => {
                        this.$notify({
                            group: 'settings',
                            duration: -1,
                            text: data
                        });
                    }
                );
            }
        },
        accountId(value) {
            if (value) {
                this.pusherUpdateMenuListen();
            }
        },
        userId() {
            this.knock();
        }
    },
    created() {
      this.$axios.interceptors.response.use(
          response => response, // Simplified since no transformation needed

          error => {
            // Early return if no response object exists
            // Handle network errors specifically
            if (!error.response) {
              // Add useful context for network errors
              Sentry.withScope(scope => {
                scope.setTag('error_type', 'network_error');
                scope.setContext('request', {
                  url: error.config?.url,
                  method: error.config?.method,
                  timestamp: new Date().toISOString(),
                  userAgent: navigator.userAgent,
                  onLine: navigator.onLine,
                  connection: navigator.connection ? {
                    effectiveType: navigator.connection.effectiveType,
                    downlink: navigator.connection.downlink,
                    rtt: navigator.connection.rtt
                  } : null
                });
                Sentry.captureException(new Error('Network request failed'), {
                  level: 'warning' // Lower severity since often user-side
                });
              });
              return Promise.reject(error);
            }

            const { response, config } = error;
            const { data, status } = response;

            // Set response context for Sentry first
            if (data) {
              Sentry.setContext('response', data);
            }


            const AUTH_ERRORS = {
              TOKEN_EXPIRED: 'token_expired',
              INVALID_TOKEN: 'invalid_token',
              UNAUTHORIZED: 'unauthorized'
            };

            // Handle auth errors
            if (status === 401) {
              // Extract more specific auth error type if available
              const authErrorType = data?.error || AUTH_ERRORS.UNAUTHORIZED;

              // Add context before capturing
              Sentry.withScope(scope => {
                scope.setTag('error_type', 'auth_error');
                scope.setTag('auth_error_type', authErrorType);
                scope.setContext('auth_error', {
                  url: config.url,
                  errorType: authErrorType,
                  message: data?.message,
                  timestamp: new Date().toISOString()
                });

                // Capture with warning level since it's expected behavior
                Sentry.captureException(new Error(`Authentication failed: ${authErrorType}`), {
                  level: 'warning'
                });
              });

              // Handle the user session
              this.logout();

              // Show appropriate modal based on error type
              // if (authErrorType === AUTH_ERRORS.TOKEN_EXPIRED) {
              //   this.$modal.show('session-expired-modal');
              // } else {
                this.$modal.show('api-unauthorized-error-modal');
              // }

              return Promise.reject(error);
            }

            // Handle showing error modal
            const shouldShowErrorModal =
                !config?.skipErrorModal &&
                data?.message &&
                !data.message.includes('handled_error');

            if (shouldShowErrorModal) {
              this.$modal.show('api-error-modal', {
                errorMessage: data.message
              });
            }

            // Capture exception once at the end
            Sentry.captureException(error);
            return Promise.reject(error);
          });



        const channel = ably.channels.get('deploy');

        channel.subscribe('deploy', () => {
            this.$notify({
                group: 'update',
                duration: -1,
                closeOnClick: false,
                text:
                    'Your dashboard is out of date. Please refresh to get the latest version.'
            });
        });

        this.channels['deploy'] = channel;
    },

    mounted() {
        this.$moment.locale('en', {
            relativeTime: {
                future: 'in %s',
                past: '%s ago',
                s: 'just now',
                ss: '%ss',
                m: 'a minute',
                mm: '%d mins',
                h: 'an hour',
                hh: '%dh',
                d: 'a day',
                dd: '%d days',
                M: 'a month',
                MM: '%d months',
                y: 'a year',
                yy: '%dY'
            }
        });

    },

    beforeDestroy() {
        this.channels['deploy'].detach();
        this.channels[`account-${this.accountId}`].detach();
    },

    methods: {
        ...mapActions({ logout: 'user/LOGOUT' }),
        reload() {
            window.location.reload(true);
        },
        pusherUpdateMenuListen() {
            const channelName = `account-${this.accountId}`;
            const event = `${channelName}-deliverect-menu-import`;
            const channel = ably.channels.get(channelName);

            channel.subscribe(event, ({ data: { progressData, menuId } }) => {
                if (menuId && !this.isEditMenuRoute) {
                    this.$notify({
                        group: 'deliverect-menu-update',
                        duration: -1,
                        data: { progressData, menuId }
                    });
                }
            });

            this.channels[channelName] = channel;
        },
        redirectToMenu({ menuId }) {
            this.$router.push({
                name: 'editMenu',
                params: { id: menuId }
            });
        },
        knock() {
            if (!this.userId || !this.accountId) {
                return;
            }

            const knockFeedInit = knockFeed(
                this.userId,
                this.currentUser.knockToken
            );

            Vue.prototype.$knockFeed = knockFeedInit;
        }
    }
};
</script>

<style>
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.toast-style {
    padding: 0.75rem;
    margin: 0 5px 15px 5px;
    font-size: 16px;
    color: #ffffff;
    background: #1a1a1a !important;
    border-radius: 5px;
    text-align: center !important;
}

.dashboard-update-content,
.deliverect-menu-update-content {
    padding: 1rem;
}
.dashboard-update,
.deliverect-menu-update {
    background: #161e2e !important;
    border-radius: 5px;
    text-align: left;
    display: flex;
    margin: 0 5px 15px 5px;

    font-size: 14px;
}
</style>
