<template>
    <div class="flex w-0 flex-1 flex-col overflow-hidden">
        <ToggleSidebar />

        <main
            class="focus:outline-none relative z-0 flex-1 overflow-y-auto"
            tabindex="0"
        >
            <div class="pb-6 pt-2 md:py-6">
                <div class="mx-auto  px-4 sm:px-6 lg:px-8">
                    <div class="md:flex md:items-center md:justify-between">
                        <div
                            class="flex min-w-0 flex-1 items-center justify-between"
                        >
                            <BasePageTitle :title="$t('views.customers.title')" />

                            <div class="flex flex-col sm:flex-row sm:flex-wrap">
                                <ExportButton @clicked="requestExport" />
                            </div>
                        </div>
                        <div
                            class="h-4 w-4"
                            :class="{ 'text-gray-900 spinner': isLoading }"
                        >
                            <span></span>
                        </div>
                    </div>
                </div>

                <div class="mx-auto px-4 py-8 sm:px-6 md:px-8">
                    <div class="rounded bg-white shadow-md sm:rounded-lg">
                        <div class="px-4 py-1">
                            <div
                                class="relative flex flex-col py-2.5 sm:flex-row"
                            >
                                <div
                                    class="relative mb-3 inline-block text-left sm:w-1/4 md:mb-0"
                                >
                                    <div class="relative rounded-md shadow-sm">
                                        <div
                                            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                        >
                                            <svg
                                                class="h-5 w-5 text-gray-400"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                        </div>
                                        <input
                                            v-model="searchString"
                                            type="text"
                                            :placeholder="$t('views.customers.searchPlaceholder')"
                                            class="focus:outline-none form-input block w-full rounded-md border border-gray-300 py-2 pl-10 focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                        />
                                    </div>

                                    <span
                                        v-if="
                                            searchString.length > 0 &&
                                            searchString.length < 3
                                        "
                                        class="m-1 text-sm text-gray-500"
                                    >
                                        {{ $t('views.customers.minCharsMessage') }}
                                    </span>
                                    <span
                                        v-if="searchString.length >= 30"
                                        class="m-1 text-sm text-gray-500"
                                    >
                                        {{ $t('views.customers.maxCharsMessage') }}
                                    </span>
                                </div>
                                <div
                                    class="relative inline-block text-left sm:ml-2"
                                >
                                  <venue-selector
                                      :venues="venues"
                                      :multiple-select="false"
                                      :allow-empty="true"
                                      :key="venues ? venues.length : 0"
                                      @selected="onVenueChange"
                                      class="mr-2"
                                  />
                                </div>
                                <div
                                    class="relative flex items-center print:hidden"
                                >
                                    <DateRangeSelector
                                        v-on:date-updated="updateReport"
                                        :lastPeriodInMonths="6"
                                    />
                                </div>

                            </div>
                        </div>

                        <vue-good-table
                            mode="remote"
                            :totalRows="totalRecords"
                            @on-page-change="onPageChange"
                            @on-sort-change="onSortChange"
                            @on-per-page-change="onPerPageChange"
                            :isLoading.sync="isLoading"
                            class="border-b border-gray-200"
                            styleClass="tailwind-table"
                            :pagination-options="{
                                enabled: true,
                                perPage: 20,
                                dropdownAllowAll: false,
                                rowsPerPageLabel: '',
                                perPageDropdown: [10, 20, 50, 100]
                            }"
                            :columns="columns"
                            :rows="customers"
                            @on-row-click="goToCustomerDetails"
                            row-style-class="mb-1 cursor-pointer"
                        >
                            <th></th>
                            <template slot="emptystate">
                                <div
                                    class="flex h-40 w-full items-center justify-center bg-white"
                                >
                                    <h3
                                        v-if="!isLoading && totalRecords === 0"
                                        class="text-lg font-medium text-gray-700"
                                        >{{ $t('views.customers.noCustomersFound') }}</h3
                                    >
                                    <span v-else class="spinner"></span>
                                </div>
                            </template>
                            <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field === 'firstname'">
                                    <span class="hover:underline"
                                        >{{ props.row.firstname }}
                                        {{ props.row.lastname }}</span
                                    >
                                </span>
                                <span
                                    v-else-if="
                                        props.column.field === 'totalOrderValue'
                                    "
                                >
                                    <span>
                                        {{
                                            props.formattedRow[
                                                props.column.field
                                            ]
                                        }}
                                        {{ $t('views.customers.spent') }}
                                    </span>
                                </span>
                              <span
                                  v-else-if="
                                        props.column.field === 'marketing'
                                    "
                              >
                                    <span
                                        class="inline-flex items-center rounded-md  px-2 py-0.5 text-xs font-medium ring-1 ring-inset"
                                        :class="{ 'bg-green-50 text-green-700 ring-green-300': props.formattedRow[
                                            props.column.field
                                            ] === 'yes', 'bg-gray-50 text-gray-700 ring-gray-300': props.formattedRow[
                                            props.column.field
                                            ] === 'no' }"
                                        >
                                        {{ props.formattedRow[
                                        props.column.field
                                        ] === 'yes' ? 'Subscribed' : 'Unsubscribed' }}
                                    </span>
                                </span>
                                <span v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </main>
        <export-customers-modal />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ToggleSidebar from '@/components/common/ToggleSidebar';
import DateRangeSelector from '@/components/widgets/DateRangeSelector';
import VenueSelector from '@/components/selectors/VenueSelector';
import ExportCustomersModal from '@/components/modals/ExportCustomersModal';
import ExportButton from "@/components/helpers/ExportButton";

export default {
    name: 'CustomersList',
    metaInfo() {
        return {
            title: this.$t('views.customers.title')
        };
    },
    components: {
        ToggleSidebar,
        DateRangeSelector,
        VenueSelector,
        ExportCustomersModal,
        ExportButton
    },
    watch: {
        searchString(value) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }

            this.timer = setTimeout(() => {
                const searchValueConditions =
                    value.length >= 3 &&
                    value.length <= 30 &&
                    this.serverParams.q !== value;
                const clearSearchResults = !value && this.serverParams.q;

                if (searchValueConditions || clearSearchResults) {
                    this.updateParams({ q: value });
                    this.fetchCustomers();
                }
            }, 600);
        }
    },
    data() {
        return {
            customers: [],
            totalRecords: null,
            showVenueFilter: false,
            venueFilter: '',
            searchString: '',
            timer: null,
            serverParams: {
                page: 1,
                limit: 20,
                sortBy: '',
                sortOrder: '',
                venuesIds: null,
                q: ''
            },
            columns: [
                {
                    label: 'Name',
                    field: 'firstname',
                    sortable: false,
                    tdClass:
                        'px-6 py-1.5 whitespace-nowrap text-sm leading-5 font-medium text-gray-900',
                    thClass:
                        'px-6 bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                },
                {
                    label: 'Email',
                    field: 'email',
                    sortable: false,
                    tdClass:
                        'px-6 py-1.5 whitespace-nowrap text-sm leading-5 text-gray-500',
                    thClass:
                        'px-6 bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                },
                {
                    label: 'Phone',
                    field: 'phone',
                    sortable: false,
                    tdClass:
                        'px-6 py-1.5 whitespace-nowrap text-sm leading-5 text-gray-500',
                    thClass:
                        'px-6 bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                },
                {
                    label: 'Orders',
                    field: 'orderCount',
                    sortable: true,
                    firstSortType: 'desc',
                    type: 'number',
                    tdClass:
                        'px-6 py-1.5 whitespace-nowrap text-sm leading-5 text-gray-500',
                    thClass:
                        'px-6 text-right bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                },
                {
                    label: 'Total Spend',
                    field: 'totalOrderValue',
                    formatFn: this.formatCurrency,
                    sortable: true,
                    firstSortType: 'desc',
                    type: 'number',
                    tdClass:
                        'px-6 py-1.5 whitespace-nowrap text-sm leading-5 text-gray-500',
                    thClass:
                        'px-6 text-right bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                },
                {
                    label: 'Last Order Date',
                    field: 'lastOrderDate',
                    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
                    dateOutputFormat: 'dd MMM yyyy',
                    sortable: false,
                    type: 'date',
                    tdClass:
                        'px-6 py-1.5 whitespace-nowrap text-sm leading-5 text-gray-500',
                    thClass:
                        'px-6 text-right bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                },
                {
                    label: 'Marketing',
                    field: 'marketing',
                    sortable: false,
                    tdClass:
                        'px-6 py-1.5 whitespace-nowrap text-sm leading-5 text-gray-500',
                    thClass:
                        'px-6 text-right bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                }
            ],
            isLoading: true
        };
    },
    async created() {
        if (!this.venues?.length > 0) {
            await this.fetchVenues();
        }
        this.fetchCustomers();
    },
    methods: {
        ...mapActions({
            fetchVenues: 'venues/fetch'
        }),
        goToCustomerDetails({ event, row }) {
          if (event.metaKey || event.ctrlKey) {
            const routeData = this.$router.resolve({
              name: 'customerDetail',
              params: {
                email: row.email
              }
            });

            window.open(routeData.href, '_blank');
          } else {
            this.$router.push({
              name: 'customerDetail',
              params: { email: row.email }
            });
          }
        },
        updateReport(val) {
            this.updateParams({
                from: val.startDate,
                to: val.endDate
            });
            this.fetchCustomers();
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.fetchCustomers();
        },
        onPerPageChange(params) {
            this.updateParams({ limit: params.currentPerPage });
            this.fetchCustomers();
        },
        onVenueChange(venuesIds) {
            this.updateParams({ venuesIds: venuesIds });
            this.venueFilter = venuesIds;
            this.fetchCustomers();
        },
        onSortChange(params) {
            this.updateParams({
                page: 1,
                sortOrder: params[0].type,
                sortBy: params[0].field
            });
            this.fetchCustomers();
        },
        venueById(id) {
            return this.venues.find(venue => venue.id === id);
        },
        fetchCustomers() {
            this.isLoading = true;
            const params = this.serverParams;

            this.$axios
                .get('/customers?', {
                    params
                })
                .then(res => {
                    this.customers = res.data.results;
                    this.totalRecords = res.data.total;
                })
                .catch(e => {
                    this.totalRecords = 0;
                    throw new Error(`API ${e}`);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        requestExport() {
            this.$modal.show('export-customers-modal', {
                params: { ...this.serverParams, limit: this.totalRecords }
            });
        },
        formatCurrency: function (value) {
            return this.$options.filters.currency(parseInt(value) / 100);
        }
    },
    computed: {
        ...mapGetters({
            venues: 'venues/getVenues'
        })
    }
};
</script>

<style>
.vgt-table.condensed td {
    font-size: 14px;
}

.vgt-table thead th {
    background: #f9fafb;
    color: #6b7280;
    font-size: 1rem;
}

.vgt-wrap {
    overflow: hidden;
}
</style>
