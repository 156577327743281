<template>
    <div
        class="focus:outline-none  relative z-0 flex-1 overflow-y-auto pb-6 pt-2 md:py-6"
    >
        <base-toggle-sidebar-button />

        <div
            class="mx-auto flex max-w-7xl flex-row items-center justify-between px-4 sm:px-6 lg:px-8"
        >
            <div class="min-w-0 flex-1">
                <div>
                    <router-link
                        :to="{ name: 'settings-profile' }"
                        class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                    >
                        <base-back-icon />
                        <span>Settings</span>
                    </router-link>
                </div>

                <base-page-title title="Webhooks" />
            </div>
            <div>
                <BaseSpinner :isLoading="isLoading" />
            </div>
        </div>
        <div
            v-if="account && !account.webhooksEnabled"
            class="mx-auto max-w-7xl px-4 pt-8 sm:px-6 md:px-8 lg:px-8"
        >
            <FeaturePanelWithScreenshot
                title="Build custom integrations with Webhooks"
                subtitle="Unlock webhooks with a subscription"
                description="Send realtime storekit data to external services and custom integrations."
                :features="webhookFeatures"
                image="https://ucarecdn.com/33d41c4a-9f9a-41a0-bb98-9fda4acb437d/-/format/auto/-/resize/1500x/"
            >
                <template #cta>
                    <base-button
                        v-if="isFeatureAvailable(FeaturesEnum.SAAS) || isFeatureAvailable(FeaturesEnum.INTERNAL)"
                        class="mt-2 sm:ml-3 sm:mt-0"
                        @clicked="enableWebhooks"
                        buttonText="Enable Webhooks"
                    />
                    <base-upsell-button
                        v-else
                        upsell-item="webhooks"
                        buttonText="Upgrade to enable webhooks"
                        :disabled="isLoading"
                    ></base-upsell-button>

                    <base-button
                        class="mt-2 sm:ml-3 sm:mt-0"
                        href="https://storekit.com/docs/webhooks"
                        buttonText="Read the docs"
                    >
                        <template #left-icon>
                            <base-doc-icon class="mr-2 h-4 w-4" />
                        </template>
                    </base-button>
                </template>
            </FeaturePanelWithScreenshot>
        </div>
        <div
            class="mx-auto h-full max-w-7xl px-4 pt-8 sm:px-6 md:px-8 lg:px-8"
            v-if="!isLoading && url"
        >
            <iframe
                :src="url"
                style="width: 100%; height: 100%; border: none"
                allow="clipboard-write"
                loading="lazy"
            >
            </iframe>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FeaturePanelWithScreenshot from '@/components/widgets/FeaturePanelWithScreenshot';
import { FeaturesEnum } from '@/enums';

export default {
    name: 'WebhookSettings',
    metaInfo: {
        title: 'Webhook Settings'
    },
    components: { FeaturePanelWithScreenshot },
    data() {
        return {
            isLoading: false,
            account: null,
            url: null,
            webhookFeatures: [
                {
                    name: 'Multiple use cases.',
                    description:
                        'Send new orders into your POS, new customer information into a CRM, build custom notifications for your team, and more.'
                },
                {
                    name: 'One click setup.',
                    description:
                        'Start receiving webhooks in seconds. Just enter your endpoint URL, select the events you want to receive, and you’re done.'
                },
                {
                    name: 'Reliable & secure.',
                    description:
                        'Automatic retries, full visibility into webhook payloads and delivery, secure webhooks with message unique signatures.'
                }
            ],
            FeaturesEnum
        };
    },
    async created() {
        this.getAccount();
    },
    methods: {
        async getAccount() {
            try {
                this.isLoading = true;
                const { data } = await this.$axios.get('/account');

                this.account = data;
                if (data.webhooksEnabled) {
                    this.webhookIframeUrl();
                }
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        async webhookIframeUrl() {
            try {
                this.isLoading = true;
                const { data } = await this.$axios.get('/account/webhooks');

                this.url = data;
            } catch (error) {
                throw new Error(error);
            } finally {
                this.isLoading = false;
            }
        },
        async enableWebhooks() {
            try {
                this.isLoading = true;
                await this.$axios.post('/account/webhooks');

                this.$notify({
                    group: 'settings',
                    title: 'Webhooks enabled'
                });

                await this.getAccount();
            } catch (error) {
                throw new Error(error);
            } finally {
                this.isLoading = false;
            }
        }
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable'
        })
    }
};
</script>
