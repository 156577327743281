<template>
    <div
        class="flex w-full items-center justify-between text-xs gap-4 font-medium leading-5 tracking-wide text-gray-700"
    >
        <div class="flex h-10 flex-col justify-center overflow-hidden">
            <label
                :for="keyName"
                class="text-xs font-medium leading-5 tracking-wide text-gray-700"
                :class="value ? 'line-clamp-1' : 'line-clamp-2'"
            >
                {{ title }}
            </label>
            <div v-if="value" class="flex items-center gap-1 p-0.5">
                <div
                    class="cursor-pointer rounded hover:bg-gray-200"
                    v-tooltip="'Reset to default'"
                    @click="reset"
                >
                    <base-small-close-icon class="w-3 h-3" />
                </div>
            </div>
        </div>
        <colour-picker
            v-model="computedValue"
            :default-colour="defaultColour"
            :swatches="swatches"
            @change="onChange"
        />
    </div>
</template>

<script>
import ColourPicker from '@/components/storeDesigner/ColourPicker';

export default {
    props: {
        value: {
            type: [String, null],
            required: false,
            default: null
        },
        title: {
            type: String,
            required: true
        },
        keyName: {
            type: String,
            required: true
        },
        defaultColour: {
            type: String,
            required: false,
            default: '#ffffff'
        },
        swatches: {
            type: Array,
            required: false,
            default: () => [
                '#000000',
                '#222831',
                '#ffffff',
                '#393e46',
                '#583d72',
                '#9f5f80',
                '#99c1de',
                '#84a59d',
                '#ffd369',
                '#f5cac3',
                '#ff8e71'
            ]
        }
    },
    components: {
        ColourPicker
    },
    computed: {
        computedValue: {
            get() {
                return this.value || this.defaultColour;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        onChange(colour) {
            this.$emit('change', this.keyName, colour);
        },
        reset() {
            this.computedValue = null;

            this.onChange(null);
        }
    }
};
</script>
