<template>
    <div class="bg-white shadow sm:rounded-lg">
        <div class="px-4 py-5 sm:p-6">
            <div class="space-y-6">
                <toggle-switch
                    label="Enable como"
                    v-model="settings.enabled"
                    @toggle-enable="toggleSettings"
                />

                <div v-if="settings.enabled">
                    <base-password-input
                        v-model="settings.apiKey"
                        label="API Key"
                        @input="updateSettings"
                    />

                    <base-select-dropdown
                        class="mt-3"
                        v-model="selectedTransactionPolicy"
                        label="Transaction Policy"
                        :options="transactionPolicyOptions"
                        @input="updateSettings"
                    />

                    <base-select-dropdown
                        class="mt-3"
                        v-model="selectedUserIdentifier"
                        label="User Identifier"
                        :options="userIdentifierOptions"
                        @input="updateSettings"
                    />

                    <toggle-switch
                        label="Non-member Benefits"
                        class="mt-3"
                        v-model="settings.nonMemberBenefits"
                        @toggle-enable="toggleSettings"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';

export default {
    name: 'ComoSettings',
    components: {
        ToggleSwitch
    },
    data() {
        return {
            settings: {
                enabled: false,
                apiKey: '',
                transactionPolicy: null,
                userIdentifier: 'email',
                nonMemberBenefits: false
            },
            transactionPolicyOptions: [
                {
                    value: 'all',
                    label: 'All'
                },
                {
                    value: 'rewardOnly',
                    label: 'Reward Only'
                }
            ],
            userIdentifierOptions: [
                { value: 'email', label: 'Email' },
                { value: 'phone', label: 'Phone' },
                { value: 'email_and_phone', label: 'Email and Phone' }
            ]
        };
    },
    computed: {
        ...mapGetters({
            venue: 'venues/getVenue'
        }),
        selectedTransactionPolicy: {
            get() {
                return (
                    this.transactionPolicyOptions.find(
                        option =>
                            option.value === this.settings.transactionPolicy
                    ) || this.transactionPolicyOptions[0]
                );
            },
            set(newValue) {
                this.settings.transactionPolicy = newValue.value;
            }
        },
        selectedUserIdentifier: {
            get() {
                return (
                    this.userIdentifierOptions.find(
                        option => option.value === this.settings.userIdentifier
                    ) || this.userIdentifierOptions[0]
                );
            },
            set(newValue) {
                this.settings.userIdentifier = newValue.value;
            }
        }
    },
    created() {
        if (this.venue.integrations?.como) {
            this.settings = { ...this.venue.integrations.como };
        }
    },
    methods: {
        async toggleSettings() {
            try {
                await this.$nextTick();
                await this.updateSettings();
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async updateSettings() {
            const venue = {
                id: this.venue.id,
                integrations: {
                    ...this.venue.integrations,
                    como: this.settings
                }
            };

            await this.$store.dispatch('venues/update', venue);
        }
    }
};
</script>
